import React, { useState, useEffect } from "react";
import AddCardIcon from "@mui/icons-material/AddCard";
import "./Billing.css";
import "../Billing/BillingInvoice.css";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import axios from "axios";
// import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import Barcode from "react-barcode";
import { useDispatch } from "react-redux";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { MdOutlineCancel } from "react-icons/md";
import { toPng } from "html-to-image";
import QRCode from "qrcode.react";
import "../Pharmacy/PharmacyBilling.css";
// import { Logger } from "html2canvas/dist/types/core/logger";
// import { ca } from "date-fns/locale";
// import { ContactsOutlined } from "@mui/icons-material";

function Billing() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const [refdoc, setrefdoc] = useState([]);
  // const [pdfBlob, setPdfBlob] = useState(null);
  // const [preview, setpreview] = useState(false);
  // const dispatchvalue = useDispatch();
  // const [patientbarcode, setpatientbarcode] = useState('');
  const [type, setType] = useState("PatientDetailCon");
  const [testname, setTestName] = useState([]);
  const [visitid, setvisitid] = useState("");
  const [patientid, setpatientid] = useState("");
  const [testdetails, settestdetails] = useState([]);
  const handleToggleChange = (event) => {
    const newValue = event.target.value;
    setType(newValue);
  };
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const outsourcelab = useSelector((state) => state.userRecord?.Outsourcelab);
  

  const [invoiceno, setInvoiceno] = useState();
  const [barcode, setbarcode] = useState();
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);
  // const [rural, setrural] = useState([]);
  const [commissionper, setcommissionper] = useState();
  const [TRFFile, setTRFFile] = useState(null);
  const [patientdetails, setPatientDetails] = useState({
    visitid: "",
    visittype: "Walkin",
    title: "",
    patientname: "",
    fathername: "",
    dob: "",
    age: "",
    gender: "",
    maritalstatus: "",
    phlebotomisttype: "",
    phlebotomistname: "",
    Referingdoctor: "",
    Patientgroup: "",
    Doorno: "",
    Rural: "",
    Pincode: "",
    Street: "",
    // District: "",
    City: "",
    State: "",
    PhoneNo: "",
    Alternatephn: "",
    Email: "",
    Dispatchmethod: "",
    testmethod: "Individual",
    OutsourceType: "",
    OutsourceLab: "",
    concernname: "",
    timeperiod: "year",
    LabCode: "",
  });
  console.log(patientdetails);
  const dispatchvalue = useDispatch();

  // const [trfpicture, settrfpicture] = useState(null)
  const [isEdit, setIsEdit] = useState(null);
  // const [doctorname, setdoctorname] = useState([])
  const [Billing_date, setBilling_date] = useState("");
  // const [totalPaidAmount, settotalPaidAmount] = useState(0);
  const [patinetbillingbarcode, setpatinetbillingbarcode] = useState("");
  const [formAmount, setFormAmount] = useState([]);
  const [franchaise, setfranchaise] = useState([]);
  const [paymentdetail, setpaymentdetail] = useState({
    paidamount: "",
    Billpay_method: "",
  });
  const [patientname, setpatientname] = useState([]);
  const [isDoctor, setIsDoctor] = useState(false);
  const [labname, setlabname] = useState([]);
  const [patientdata, setpatientdata] = useState([]);
  const [initialState, setinitialState] = useState({
    totalItems: 0,
    totalAmount: 0,
    totalDiscount: 0,
    totalGstamount: 0,
    totalUnits: 0,
    totalNetAmount: 0,
    totalrecived: 0,
    // totalAdditionalAmount: 0,
  });
  console.log(initialState)
  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: "",
    ClinicGST: "",
    ClinicAddress: "",
    ClinicCity: "",
    ClinicState: "",
    ClinicCode: "",
    ClinicMobileNo: "",
    ClinicLandLineNo: "",
    ClinicMailID: "",
    InvoiceNo: "",
  });
  const [orderdetails, setorderdetails] = useState({
    testname: "",
    grossAmount: 0,
    discountType: "", // "Cash" or "Percentage"
    discountValue: "",
    paymentMethod: "",
    receivedAmount: 0,
    netAmount: 0,
    dueAmount: 0,
    CardName: "",
    CardNumber: "",
    TransactionId: "",
    UPI_Mob: "",
    CardType: "",
    Test_Code: "",
    AdditionalAmount: 0,
    AdditionalAmountReason: "",
    DiscountReason: "",
    HistopathologyNumber: "",
    MicrobiologyNumber: "",
    Collectioncharges: 0
  });

  console.log(orderdetails);
  const handleinputchangepay = (event) => {
    const { name, value } = event.target;
    console.log('initialState :', initialState)
    const total_netamount = initialState.totalNetAmount;
    const totalPaidAmount = formAmount.reduce(
      (acc, val) => acc + Number(val.paidamount),
      0
    );

    const remainingAmount = total_netamount - totalPaidAmount;

    if (isEdit !== null) {
      // If in edit mode, allow the value to be modified freely
      setpaymentdetail((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (+totalPaidAmount !== +total_netamount) {
      if (name === "Billpay_method") {
        setpaymentdetail((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        if (formAmount.length > 0) {
          if (+remainingAmount >= +value) {
            setpaymentdetail((prevState) => ({
              ...prevState,
              [name]: value,
            }));
          } else {
            alert(`Enter a value less than or equal to ${remainingAmount}`);
            setpaymentdetail((prevState) => ({
              ...prevState,
              [name]: "",
            }));
          }
        } else {
          if (+total_netamount >= +value) {
            setpaymentdetail((prevState) => ({
              ...prevState,
              [name]: value,
            }));
          } else {
            alert(`Enter a value less than or equal to ${total_netamount}`);
            setpaymentdetail((prevState) => ({
              ...prevState,
              [name]: "",
            }));
          }
        }
      }
    } else {
      alert("No Balance Amount");
    }
  };


  const handleInputChange1 = (event) => {
    const { name, value } = event.target;

    if (name === "discountValue") {
      // If updating discountValue
      const isDiscountValueEntered = !!value.trim(); // Check if discountValue is not empty
      setorderdetails((prevState) => ({
        ...prevState,
        [name]: value,
        isDiscountValueEntered: isDiscountValueEntered,
      }));

      if (isDiscountValueEntered) {
        // If discountValue is entered, enforce mandatory requirement for discountType
        if (!orderdetails.discountType.trim()) {
          // If discountType is not selected
          alert("Please select a discount type.");
          setorderdetails((prevState) => ({
            ...prevState,
            discountValue: "",
          }));
        } else {
          // Subtract discount from original netAmount
          setorderdetails((prevState) => {
            const originalNetAmount = prevState.originalNetAmount || prevState.netAmount;
            const discount = parseFloat(value) || 0;
            const discountedAmount =
              prevState.discountType === "Percentage"
                ? originalNetAmount * (discount / 100)
                : discount;
            const newNetAmount = originalNetAmount - discountedAmount;

            return {
              ...prevState,
              netAmount: newNetAmount.toFixed(2),
              dueAmount: (newNetAmount - parseFloat(prevState.receivedAmount || 0)).toFixed(2),
            };
          });
        }
      } else {
        // Reset netAmount to original if discount is removed
        setorderdetails((prevState) => ({
          ...prevState,
          netAmount: prevState.originalNetAmount.toFixed(2),
          dueAmount: (prevState.originalNetAmount - parseFloat(prevState.receivedAmount || 0)).toFixed(2),
        }));
      }
    } else if (name === "AdditionalAmount") {
      // If updating AdditionalAmount
      const additionalAmount = parseFloat(value) || 0;

      // Update netAmount and dueAmount based on grossAmount + additionalAmount
      setorderdetails((prevState) => {
        const grossAmount = parseFloat(prevState.grossAmount) || 0;
        const newNetAmount = grossAmount + additionalAmount; // Calculate netAmount as grossAmount + additionalAmount
        const receivedAmount = parseFloat(prevState.receivedAmount) || 0;

        return {
          ...prevState,
          [name]: value, // Store AdditionalAmount
          netAmount: newNetAmount.toFixed(2), // Update netAmount
          dueAmount: (newNetAmount - receivedAmount).toFixed(2), // Update dueAmount based on netAmount and receivedAmount
        };
      });

      if (!value.trim()) {
        // If AdditionalAmount is cleared, reset to only grossAmount
        setorderdetails((prevState) => {
          const grossAmount = parseFloat(prevState.grossAmount) || 0;
          const receivedAmount = parseFloat(prevState.receivedAmount) || 0;

          return {
            ...prevState,
            netAmount: grossAmount.toFixed(2), // Set netAmount to grossAmount
            dueAmount: (grossAmount - receivedAmount).toFixed(2), // Update dueAmount based on grossAmount
          };
        });
      }
    }else if(name === "Collectioncharges"){
      const additionalAmount = parseFloat(value) || 0;

      // Update netAmount and dueAmount based on grossAmount + additionalAmount
      setorderdetails((prevState) => {
        const grossAmount = parseFloat(prevState.grossAmount) || 0;
        const newNetAmount = grossAmount + additionalAmount; // Calculate netAmount as grossAmount + additionalAmount
        const receivedAmount = parseFloat(prevState.receivedAmount) || 0;

        return {
          ...prevState,
          [name]: value, // Store AdditionalAmount
          netAmount: newNetAmount.toFixed(2), // Update netAmount
          dueAmount: (newNetAmount - receivedAmount).toFixed(2), // Update dueAmount based on netAmount and receivedAmount
        };
      });

      if (!value.trim()) {
        // If AdditionalAmount is cleared, reset to only grossAmount
        setorderdetails((prevState) => {
          const grossAmount = parseFloat(prevState.grossAmount) || 0;
          const receivedAmount = parseFloat(prevState.receivedAmount) || 0;

          return {
            ...prevState,
            netAmount: grossAmount.toFixed(2), // Set netAmount to grossAmount
            dueAmount: (grossAmount - receivedAmount).toFixed(2), // Update dueAmount based on grossAmount
          };
        });
      }
    }
    
    else {
      // If updating other fields
      if (patientdetails.testmethod === "Individual") {
        const selectedTest = testname?.find((item) => item.Test_Name === value);

        setorderdetails((prevState) => ({
          ...prevState,
          [name]: value,
          Test_Code: selectedTest ? selectedTest.Test_Code : "", // Set Test_Code if selectedTest is found
        }));
      } else if (patientdetails.testmethod === "Group") {
        const selectedTest = testname.find((item) => item.groupname === value);

        setorderdetails((prevState) => ({
          ...prevState,
          [name]: value,
          Test_Code: selectedTest ? selectedTest.groupcode : "", // Set Test_Code if selectedTest is found
        }));
      } else {
        const selectedTest = testname.find((item) => item.package_name === value);
        console.log("selectedTest", selectedTest);
        setorderdetails((prevState) => ({
          ...prevState,
          [name]: value,
          Test_Code: selectedTest ? selectedTest.package_code : "", // Set Test_Code if selectedTest is found
        }));
      }
    }
  };


  useEffect(() => {
    axios
      .get(
        `${urllink}Billing/get_refering_doc_names?Location=${userRecord?.location}`
      )
      .then((response) => {
        console.log(response);
        setrefdoc(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [urllink, userRecord?.location]);

  const numberToWords = (number) => {
    let num = parseInt(number.toString().split(".")[0]);
    if (num === 0) {
      return "Zero Rupees Only";
    }

    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
    ];
    const teens = [
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    const convert = (num) => {
      if (num <= 10 && num !== 0) return units[num];
      if (num < 20) return teens[num - 11];
      if (num < 100)
        return (
          tens[Math.floor(num / 10)] +
          (num % 10 !== 0 ? " " + units[num % 10] : "")
        );
      if (num < 1000)
        return (
          units[Math.floor(num / 100)] +
          " Hundred" +
          (num % 100 !== 0 ? " and " + convert(num % 100) : "")
        );
      if (num < 100000)
        return (
          convert(Math.floor(num / 1000)) +
          " Thousand" +
          (num % 1000 !== 0 ? " and " + convert(num % 1000) : "")
        );
      if (num < 10000000)
        return (
          convert(Math.floor(num / 100000)) +
          " Lakh" +
          (num % 100000 !== 0 ? " and " + convert(num % 100000) : "")
        );
    };

    return convert(num) + " Rupees  Only";
  };

  // console.log("invoiceno", initialState);

  const handleAdd = () => {
    const requiredFields = ["Billpay_method", "paidamount"];
    const missingFields = requiredFields.filter(
      (field) => !paymentdetail[field]
    );

    if (missingFields.length === 0) {
      const existingMethod = formAmount.find(
        (payment) => payment.Billpay_method === paymentdetail.Billpay_method
      );

      if (!existingMethod) {
        console.log(paymentdetail);
        setFormAmount((prev) => [...prev, paymentdetail]);
        resetPaymentDetail();
      } else {
        alert("The Payment Method already exists");
      }
    } else {
      alert(`Enter the required fields: ${missingFields.join(", ")}`);
    }
  };

  const handleEdit = (index) => {
    setIsEdit(index);
    const item = formAmount[index];
    const editamount = item.paidamount;
    console.log("item", editamount);

    setpaymentdetail({
      ...item,
    });

    setorderdetails((prev) => ({
      ...prev,
      dueAmount: prev.dueAmount + Number(editamount),
      receivedAmount: prev.receivedAmount - Number(editamount),
      // Corrected line
    }));
  };

  useEffect(() => {
    if (outsourcelab) {
      setPatientDetails((prevState) => ({
        ...prevState,
        patientname: outsourcelab.Patient_Name || "",
        dob: outsourcelab?.Date_of_Birth || "",
        age: calculateAge(outsourcelab?.Date_of_Birth).toString(),
        gender: outsourcelab?.Gender || "",
        concernname: outsourcelab?.Refering_Hospital || "",
        PhoneNo: outsourcelab?.Phone_Number || "",
      }));
    }
  },[]);

  useEffect(() => {
    const handleFetchData = async () => {
      if (outsourcelab?.testdetails?.length > 0) {
        try {
          let totalNewData = [];
          let totalCost = 0;
          let alreadyExists = false;

          for (const labRequest of outsourcelab?.testdetails) {

            console.log(labRequest, 'labRequest');
            
            const url = `${urllink}usercontrol/getcostpertest?Test_Code=${labRequest?.Test_Code}&ratecard=${outsourcelab?.Refering_Hospital}`;
            const response = await axios.get(url);
              console.log(response, 'response');
              
            let newData = [];
            if (
              response.data &&
              !Array.isArray(response.data) &&
              response.data.test_code
            ) {
              newData = [response.data];
            } else if (Array.isArray(response.data)) {
              newData = response.data;
            }

            for (const newItem of newData) {
              if (newItem.testmethod === "Group") {
                const groupResponse = await axios.get(
                  `${urllink}Billing/get_test_name_for_group_compare?Test_Code=${newItem.test_code}`
                );
                const groupTestNames = groupResponse.data.map(
                  (item) => item.test_name
                );

                if (
                  groupTestNames.some((groupTestName) =>
                    testdetails.some(
                      (existingItem) => existingItem.test_name === groupTestName
                    )
                  )
                ) {
                  alreadyExists = true;
                  break;
                }
              } else {
                for (const existingItem of testdetails) {
                  if (existingItem.testmethod === "Group") {
                    const existingGroupResponse = await axios.get(
                      `${urllink}Billing/get_test_name_for_group_compare?Test_Code=${existingItem.test_code}`
                    );
                    const existingGroupTestNames =
                      existingGroupResponse.data.map((item) => item.test_name);

                    if (existingGroupTestNames.includes(newItem.test_name)) {
                      alreadyExists = true;
                      break;
                    }
                  } else if (existingItem.test_name === newItem.test_name) {
                    alreadyExists = true;
                    break;
                  }
                }
                if (alreadyExists) break;
              }
            }

            if (alreadyExists) {
              alert(
                `Test ${newData[0]?.test_name} already exists in testdetails`
              );
            } else {
              totalNewData = [...totalNewData, ...newData];
              totalCost += newData.reduce(
                (acc, curr) => acc + Number(curr.cost_per_reportable),
                0
              );
            }
          }

          if (!alreadyExists) {
            settestdetails((prevData) => {
              const updatedData = [...prevData, ...totalNewData];
              return updatedData.filter((item) => {
                if (item.testmethod === "Group") return true;
                return !totalNewData.some(
                  (newItem) =>
                    newItem.testmethod === "Group" &&
                    newItem.test_name === item.test_name
                );
              });
            });

            setorderdetails((prevState) => ({
              ...prevState,
              grossAmount: Number(prevState.grossAmount || 0) + totalCost,
              testname: "",
            }));
          } else {
            setorderdetails((prev) => ({
              ...prev,
              testname: "",
            }));
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
      }
    };

    handleFetchData();
  }, []); // Empty dependency array

  const handleUpdate = () => {
    const req = ["Billpay_method", "paidamount"];
    const missing = req.filter((row) => ![row]);
    if (missing.length === 0) {
      const data = [...formAmount];
      data[isEdit] = paymentdetail;

      setFormAmount(data);
      setpaymentdetail({
        paidamount: "",
        Billpay_method: "",
      });
      setIsEdit(null);
    } else {
      alert(`enter the required fields : ${missing.join(",")}`);
    }
  };

  const resetPaymentDetail = () => {
    setpaymentdetail({
      paidamount: "",
      Billpay_method: "",
    });
  };

  const handleSearch = async () => {
    if (
      orderdetails.Test_Code !== "" &&
      patientdetails.Patientgroup !== "" &&
      orderdetails.testname !== ""
    ) {
      if (
        patientdetails.testmethod === "Individual" ||
        patientdetails.testmethod === "Group" ||
        patientdetails.testmethod === "Offers"
      ) {
        try {
          const url =
            patientdetails.testmethod === "Individual"
              ? `${urllink}usercontrol/getcostpertest?Test_Code=${orderdetails.Test_Code}&ratecard=${patientdetails.Patientgroup}`
              : patientdetails.testmethod === "Group"
                ? `${urllink}usercontrol/getgroupcost?Test_Code=${orderdetails.Test_Code}&ratecard=${patientdetails.Patientgroup}`
                : `${urllink}usercontrol/getcostperoffer?Test_Code=${orderdetails.Test_Code}&ratecard=${patientdetails.Patientgroup}`;

          const response = await axios.get(url);

          let newData = [];
          if (
            response.data &&
            !Array.isArray(response.data) &&
            response.data.test_code
          ) {
            newData = [response.data];
          } else if (Array.isArray(response.data)) {
            newData = response.data;
          }

          if (testdetails.length === 0) {
            settestdetails(newData);

            const totalCost = newData.reduce(
              (acc, curr) => acc + Number(curr.cost_per_reportable),
              0
            );
            setorderdetails((prevState) => ({
              ...prevState,
              grossAmount: Number(prevState.grossAmount || 0) + totalCost,
            }));

            setorderdetails((prev) => ({
              ...prev,
              testname: "",
            }));
            return;
          }

          let alreadyExists = false;

          for (const newItem of newData) {
            if (newItem.testmethod === "Group") {
              const groupResponse = await axios.get(
                `${urllink}Billing/get_test_name_for_group_compare?Test_Code=${newItem.test_code}`
              );
              const groupTestNames = groupResponse.data.map(
                (item) => item.test_name
              );

              if (
                groupTestNames.some((groupTestName) =>
                  testdetails.some(
                    (existingItem) => existingItem.test_name === groupTestName
                  )
                )
              ) {
                alreadyExists = true;
                break;
              }
            } else {
              for (const existingItem of testdetails) {
                if (existingItem.testmethod === "Group") {
                  const existingGroupResponse = await axios.get(
                    `${urllink}Billing/get_test_name_for_group_compare?Test_Code=${existingItem.test_code}`
                  );
                  const existingGroupTestNames = existingGroupResponse.data.map(
                    (item) => item.test_name
                  );

                  if (existingGroupTestNames.includes(newItem.test_name)) {
                    alreadyExists = true;
                    break;
                  }
                } else if (existingItem.test_name === newItem.test_name) {
                  alreadyExists = true;
                  break;
                }
              }
              if (alreadyExists) break;
            }
          }

          if (alreadyExists) {
            alert("Data already exists in testdetails");
            setorderdetails((prev) => ({
              ...prev,
              testname: "",
            }));
            return;
          } else {
            settestdetails((prevData) => {
              const updatedData = [...prevData, ...newData];
              return updatedData.filter((item) => {
                if (item.testmethod === "Group") return true;
                return !newData.some(
                  (newItem) =>
                    newItem.testmethod === "Group" &&
                    newItem.test_name === item.test_name
                );
              });
            });

            const totalCost = newData.reduce(
              (acc, curr) => acc + Number(curr.cost_per_reportable),
              0
            );
            setorderdetails((prevState) => ({
              ...prevState,
              grossAmount: Number(prevState.grossAmount || 0) + totalCost,
            }));

            setorderdetails((prev) => ({
              ...prev,
              testname: "",
            }));
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
      }
    } else {
      alert("Please enter patient details");
    }
  };

  // console.log('orderdetails', orderdetails);

  console.log("testdetails", testdetails);

  useEffect(() => {
    let concern = patientdetails.concernname;
    let searchconsern = concern?.split(" ").join("");
    console.log("searchconsern", searchconsern);
    const data = franchaise.filter((p) => p === searchconsern);
    console.log(data);
    const datas = data[0];
    if (data.length > 0) {
      setPatientDetails((prev) => ({
        ...prev,
        Patientgroup: datas,
      }));
    } else {
      setPatientDetails((prev) => ({
        ...prev,
        Patientgroup: "Basic",
      }));
    }
  }, [patientdetails.concernname, patientdetails.Patientgroup, franchaise]);

  useEffect(() => {
    const currentDate = new Date();
    setBilling_date(format(currentDate, "dd-MM-yyyy"));
    // if (patientdetails.gender !== '') {
    //   // console.log('123444444444')
    //   axios
    //     .get(`${urllink}usercontrol/getalltestname?gender=${patientdetails.gender}`)
    //     .then((response) => {
    //       console.log(response.data);
    //       const data = response.data;
    //       setTestName(data);
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // }

    // axios.get(`${urllidnk}usercontrol/get_ref_doctorname`)
    //   .then((response) => {
    //     // console.log('response.data', response.data)
    //     const data = response.data
    //     setdoctorname(data)
    //   })
    //   .catch((error) => {
    //     console.error(error)
    //   })

    axios
      .get(`${urllink}Billing/getinvoice`)
      .then((response) => {
        // console.log(response.data);
        const data = response.data.nextInvoiceCode;

        setInvoiceno(data);
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(`${urllink}Billing/getbarcode`)
      .then((response) => {
        // console.log(response.data);
        const data = response.data.nextInvoiceBarcode;
        setbarcode(data);
        // setInvoiceno(data);
      })
      .catch((error) => {
        console.error(error);
      });

    if (patientdetails.patientname) {
      axios
        .get(
          `${urllink}Billing/getpatientid?patientname=${patientdetails.patientname}&patientphn=${patientdetails.PhoneNo}`
        )
        .then((response) => {
          console.log(response.data);
          const data = response.data.nextPatientId;
          setpatientid(data);
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          `${urllink}Billing/getforvisitid?patientphn=${patientdetails.PhoneNo}&patientname=${patientdetails.patientname}`
        )
        .then((response) => {
          // console.log(response.data);
          const data = response.data.nextVisitId;
          setvisitid(data);
        })
        .catch((error) => {
          console.error(error);
        });

      // axios.get(`${urllink}Billing/get_patient_barcode`)
      //   .then((response) => {
      //     // console.log(response)
      //     const data = response.data.patientbarcode
      //     setpatientbarcode(data)
      //   })
      //   .catch((error) => {
      //     console.error(error)
      //   })
    }
  }, [
    patientdetails.patientname,
    patientdetails.PhoneNo,
    urllink,
    patientdetails.gender,
  ]);

  useEffect(() => {
    axios
      .get(`${urllink}mainddepartment/get_ratecard_names_for_billing`)
      .then((response) => {
        console.log(response);
        setfranchaise(response.data.ratecard);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${urllink}mainddepartment/get_for_outsource_lab_name`)
      .then((response) => {
        console.log(response);
        setlabname(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [urllink]);

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getClinic?location=${userRecord?.location}`)
      .then((response) => {
        console.log(response.data);
        const data = response.data[0];
        // console.log(data);
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicAddress: data.doorNo + "," + data.area + "," + data.street,
            ClinicGST: data.gstno,
            ClinicCity: data.city,
            ClinicState: data.state,
            ClinicCode: data.pincode,
            ClinicMobileNo: data.phoneNo,
            ClinicLandLineNo: data.landline,
            ClinicMailID: data.email,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        // console.log(response.data.Data)
        const data = response.data;
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicName: data.clinicName,
            ClinicLogo: `data:image/png;base64,${data.clinicLogo}`,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      });

    if (patientdetails.patientname) {
      axios
        .get(
          `${urllink}Billing/get_billing_patient_barcode?Patientid=${patientid}&Patientname=${patientdetails.patientname}`
        )
        .then((response) => {
          console.log(response);
          setpatinetbillingbarcode(response.data.Patient_Barcode);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [patientid, patientdetails.patientname, urllink, userRecord]);

  const handleDeleted = (testname) => {
    // Find the item to be deleted
    const deletedItem = testdetails.find((p) => p.test_name === testname);

    if (!deletedItem) {
      console.error(`Item with test name ${testname} not found.`);
      return;
    } else {
      const reductionAmount = Number(deletedItem.cost_per_reportable || 0);
      const updatedTestDetails = testdetails.filter(
        (p) => p.test_name !== testname
      );
      // console.log('updated testdetails', updatedTestDetails);
      settestdetails(updatedTestDetails);

      setorderdetails((prevState) => ({
        ...prevState,
        grossAmount: Number(prevState.grossAmount || 0) - reductionAmount,
      }));
    }
  };

  // console.log('testdetails', testdetails);

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
    console.log(name, value, files);

    let updatedValue = value;

    // Phone number handling
    if ((name === "PhoneNo" || name === "Alternatephn") && value.length > 10) {
      updatedValue = value.slice(0, 10);
    }

    let updatedDetails = {
      ...patientdetails,
      [name]: updatedValue,
    };

    // Title handling for gender and timeperiod
    if (name === "title") {
      setIsDoctor(value === "Dr" || "Baby" || "Child"); // Update isDoctor based on title
      let genderValue = "";

      // Set gender based on title selection
      if (value === "Mr") genderValue = "Male";
      else if (value === "Mrs" || value === "Ms") genderValue = "Female";
      else if (value.includes("Baby")) genderValue = "Baby";
      else if (value.includes("Child")) genderValue = "Child";
      else if (value.includes("Sister")) genderValue = "Sister";
      else if (value.includes("Master")) genderValue = "Master";
      else if (value.includes("Transgender")) genderValue = "Transgender";

      updatedDetails = { ...updatedDetails, gender: genderValue };

      if (value.includes("Baby")) {
        updatedDetails = { ...updatedDetails, timeperiod: "" };
      } else {
        updatedDetails = { ...updatedDetails, timeperiod: "year" }; // Clear timeperiod if title includes "Baby"
      }
    }
    if (name === "dob") {
      const age = calculateAge(value);
      updatedDetails = { ...updatedDetails, age: age.toString() }; // Assuming age is stored as a string
    }

    if (name === "age") {
      const dob = calculateYear(value);
      console.log("dob", dob);
      updatedDetails = {
        ...updatedDetails,
        dob: dob.toISOString().split("T")[0],
      }; // Format DOB as YYYY-MM-DD
    }

    if (name === "CapturedFile4") {
      const fileObject = event.target.files[0];
      setTRFFile(fileObject);
    }

    if (name === "concernname") {
      console.log(labname);
      console.log(value);
      const selectedLab = labname.find((item) => item.Lab_Name === value);
      if (selectedLab) {
        updatedDetails = {
          ...updatedDetails,
          LabCode: selectedLab.Labcode,
          concernname: selectedLab.Lab_Name,
        };
      }
    }

    if (name === "patientname"){
      const capvalue = value.toUpperCase();
      // console.log(capvalue, 'capvalue');
      
      setPatientDetails(prev =>({
        ...prev,
        patientname: capvalue
      }));
    }

    setPatientDetails(updatedDetails);
  };

  const calculateYear = (age) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const yearOfBirth = currentYear - age;
    const dob = new Date(yearOfBirth, 1, -29);
    return dob;
  };

  useEffect(() => {
    console.log(orderdetails);
    const parsedGrossAmount = parseFloat(orderdetails.grossAmount || 0); // Ensure parsing and handle undefined
    const parsedDiscountValue = parseFloat(orderdetails.discountValue || 0); // Ensure parsing and handle undefined
    const parsedAdditionalAmount = parseFloat(parseInt(
      orderdetails.AdditionalAmount || 0
    )); // Ensure parsing and handle undefined
    const parsecollectioncharges = parseFloat(parseInt(orderdetails.Collectioncharges) || 0)

    console.log(parsedAdditionalAmount)
    let discount = 0;
    if (orderdetails.discountType === "Cash") {
      discount = parsedDiscountValue;
    } else if (orderdetails.discountType === "Percentage") {
      discount = (parsedDiscountValue / 100) * parsedGrossAmount;
    }

    const netAmount = parsedGrossAmount - discount + parsedAdditionalAmount + parsecollectioncharges;

    // Check if discount is greater than netAmount
    if (discount > parsedGrossAmount) {
      alert("Discount cannot exceed the gross amount.");
      setorderdetails((prev) => ({
        ...prev,
        discountValue: "",
      }));
      return; // Do not update state if discount is invalid
    }

    setorderdetails((prevState) => ({
      ...prevState,
      netAmount: Number(netAmount.toFixed(2)), // Ensure parsing
    }));
    console.log(parsedAdditionalAmount)
    console.log('Number(netAmount.toFixed(2) + parsedAdditionalAmount)', Number(netAmount.toFixed(2)) + parsedAdditionalAmount)
    setinitialState((prev) => ({
      ...prev,
      totalDiscount: Number(discount.toFixed(2)), // Ensure parsing
      totalNetAmount: Number(netAmount.toFixed(2)), // Ensure parsing
      // totalAdditionalAmount: Number(parsedAdditionalAmount.toFixed(2)),
    }));
  }, [
    orderdetails.discountType,
    orderdetails.discountValue,
    orderdetails.grossAmount,
    orderdetails.testname,
    orderdetails.AdditionalAmount,
    orderdetails.Collectioncharges
  ]);


  useEffect(() => {
    const phonenum = patientdetails.PhoneNo;

    if (phonenum !== "") {
      const time = setTimeout(() => {
        axios
          .get(`${urllink}Billing/getforpatientinfo?phonenum=${phonenum}`)
          .then((response) => {
            console.log(response.data);
            const data = response.data.patient_infos;
            const patient = response.data.patients;
            setpatientname(patient);
            setpatientdata(data);
            // const error = response.data.error;
          })
          .catch((error) => {
            console.log(error);
          });
      }, 400); // Adjust delay as needed

      // Cleanup timeout on component unmount or dependency change
      return () => clearTimeout(time);
    }
  }, [patientdetails.PhoneNo, urllink]);

  useEffect(() => {
    if (patientdata) {
      patientdata
        .filter((p) => p.Patient_Name === patientdetails.patientname)
        .forEach((item) => {
          setPatientDetails((prevState) => ({
            ...prevState,
            patientname: item.Patient_Name || "",
            fathername: item.Father_Name || "",
            dob: item.DOB || "",
            age: calculateAge(item.DOB).toString(),
            gender: item.Gender || "",
            maritalstatus: item.Marital_Status || "",
            Doorno: item.Door_No || "",
            Street: item.Street || "",
            State: item.State || "",
            PhoneNo: patientdetails.PhoneNo, // Assuming you want to keep the searched phone number
            Alternatephn: item.Alternate_No || "",
            Email: item.Email || "",
            title: item.Title || "",
            City: item.City || "",
            Rural: item.Rural_Urban || "",
            Pincode: item.Pincode || "", // Add other fields if necessary
          }));

          if (item.Age !== "") {
            calculateYear(item.Age);
          } else {
            calculateAge(item.DOB);
          }
        });
      // console.log('patientdaa............................', patientdaa)
    }
  }, [patientdata, patientdetails.patientname, patientdetails.PhoneNo]);

  // console.log('patientdetails................', patientdata);

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontent"),
    onAfterPrint: async () => {
      // setPdfBlob(null);
      const printdata = document.getElementById("reactprintcontent");

      try {
        if (printdata) {
          const contentWidth = printdata.offsetWidth;
          const padding = 20;
          const pdfWidth = contentWidth + 2 * padding;
          const pdfHeight = contentWidth * 1.5;
          const pdf = new jsPDF({
            unit: "px",
            format: [pdfWidth, pdfHeight],
          });

          const barcodeImagecon1 = await toPng(
            printdata.querySelector("#get_imagecontent_1")
          );
          const barcodeImagecon2 = await toPng(
            printdata.querySelector("#get_imagecontent_2")
          );

          const barcodeImage1 = await new Promise((resolve, reject) => {
            const barcodeImg1 = new Image();
            barcodeImg1.onload = () => resolve(barcodeImg1);
            barcodeImg1.onerror = reject;
            barcodeImg1.src = barcodeImagecon1;
          });

          const barcodeImage2 = await new Promise((resolve, reject) => {
            const barcodeImg2 = new Image();
            barcodeImg2.onload = () => resolve(barcodeImg2);
            barcodeImg2.onerror = reject;
            barcodeImg2.src = barcodeImagecon2;
          });

          const barcodeContainer1 = printdata.querySelector(
            "#get_imagecontent_1"
          );
          const barcodeContainer2 = printdata.querySelector(
            "#get_imagecontent_2"
          );
          barcodeContainer1.innerHTML = "";
          barcodeContainer2.innerHTML = "";
          barcodeContainer1.appendChild(barcodeImage1);
          barcodeContainer2.appendChild(barcodeImage2);

          pdf.html(printdata, {
            x: padding,
            y: padding,
            callback: async () => {
              const generatedPdfBlob = pdf.output("blob");
              // setPdfBlob(generatedPdfBlob);

              const formData = new FormData();
              formData.append("pdf", generatedPdfBlob, "billing_receipt.pdf");
              formData.append("invoice", invoiceno);
              formData.append("Patientbarcode", patinetbillingbarcode);
              formData.append("Visitid", visitid);
              formData.append("location", userRecord?.location);
              formData.append("createdby", userRecord?.username);
              formData.append("patientid", patientid);
              const barcodeBlob = await fetch(barcodeImagecon2)
                .then((res) => res.blob())
                .catch((err) =>
                  console.error("Error converting barcode image to Blob:", err)
                );
              formData.append(
                "invoicebarcodeimage",
                barcodeBlob,
                "invoicebarcodeimage.png"
              );
              formData.append("TRFFile", TRFFile);
              formData.append("Request_Id", outsourcelab?.Request_Id);

              // Append other data
              formData.append(
                "postpersonaldata",
                JSON.stringify({
                  visitid: visitid,
                  visittype: patientdetails.visittype,
                  title: patientdetails.title,
                  patientname: patientdetails.patientname,
                  fathername: patientdetails.fathername,
                  dob: patientdetails.dob,
                  age: patientdetails.age,
                  gender: patientdetails.gender,
                  maritalstatus: patientdetails.maritalstatus,
                  Doorno: patientdetails.Doorno,
                  Street: patientdetails.Street,
                  State: patientdetails.State,
                  City: patientdetails.City,
                  // rural: patientdetails.Rural,
                  pincode: patientdetails.Pincode,
                  PhoneNo: patientdetails.PhoneNo,
                  Alternatephn: patientdetails.Alternatephn,
                  Email: patientdetails.Email,
                  phlebotomisttype: patientdetails.phlebotomisttype,
                  phlebotomistname: patientdetails.phlebotomistname,
                  Referingdoctor: patientdetails.Referingdoctor,
                  Patientgroup: patientdetails.Patientgroup,
                  Dispatchmethod: patientdetails.Dispatchmethod,
                  Location: userRecord?.location,
                  CreatedBy: userRecord?.username,
                  patientbarcode: patinetbillingbarcode,
                  patientid: patientid,
                  Billing_Type: patientdetails.OutsourceType,
                  timeperiod: patientdetails.timeperiod,
                  concernname: patientdetails.concernname,
                })
              );

              formData.append(
                "postbillingdetails",
                JSON.stringify({
                  ...orderdetails,
                  testdetails: orderdetails.testdetails,
                  invoiceno: invoiceno,
                  barcode: barcode,
                  itemcount: testdetails.length,
                  patientid: patientid,
                  visitid: visitid,
                  patientname: patientdetails.patientname,
                  Dispatchmethod: patientdetails.Dispatchmethod,
                  location: userRecord?.location,
                  CreatedBy: userRecord?.username,
                  outsource_type: patientdetails.Patientgroup,
                  refering_doctor: patientdetails.Referingdoctor,
                  Billing_Type: patientdetails.OutsourceType,
                  Ratecardtype: patientdetails.Patientgroup,
                  AdditionalAmount: orderdetails?.AdditionalAmount,
                  collectionchrgs : orderdetails.Collectioncharges,
                  AdditionalAmountReason: orderdetails?.AdditionalAmountReason,
                  DiscountReason: orderdetails?.DiscountReason,
                  HistopathologyNumber: orderdetails?.HistopathologyNumber,
                  MicrobiologyNumber: orderdetails?.MicrobiologyNumber,
                })
              );

              formData.append(
                "postorderdetailsforpayment",
                JSON.stringify({
                  payment_details: formAmount,
                  invoiceno: invoiceno,
                  barcode: barcode,
                  patientid: patientid,
                  visitid: visitid,
                  patientname: patientdetails.patientname,
                  Dispatchmethod: patientdetails.Dispatchmethod,
                  receivedAmount: orderdetails.receivedAmount,
                  netAmount: orderdetails.netAmount,
                  cardnum: orderdetails.CardNumber,
                  cardname: orderdetails.CardName,
                  cardtype: orderdetails.CardType,
                  transactionid: orderdetails.TransactionId,
                  onlinenum: orderdetails.UPI_Mob,
                  location: userRecord?.location,
                  CreatedBy: userRecord?.username,
                })
              );

              formData.append(
                "postorderdetailsinfo",
                JSON.stringify({
                  testdetails,
                  invoiceno: invoiceno,
                  patientid: patientid,
                  barcode: barcode,
                  visitid: visitid,
                  testmethod: patientdetails.testmethod,
                  patientname: patientdetails.patientname,
                  Dispatchmethod: patientdetails.Dispatchmethod,
                  location: userRecord?.location,
                  CreatedBy: userRecord?.username,
                })
              );

              formData.append(
                "postcommissionfordoctor",
                JSON.stringify({
                  doctorname: patientdetails.Referingdoctor,
                  billinginvoice: invoiceno,
                  visitid: visitid,
                  commissionper: commissionper,
                  netAmount: orderdetails.netAmount,
                  billingdate: new Date().toLocaleDateString(),
                  patientname: patientdetails.patientname,
                  location: userRecord?.location,
                })
              );

              formData.append(
                "postreferinghospitaldata",
                JSON.stringify({
                  payment_details: formAmount,
                  invoiceno: invoiceno,
                  patientid: patientid,
                  visitid: visitid,
                  patientname: patientdetails.patientname,
                  receivedAmount: orderdetails.receivedAmount,
                  netAmount: orderdetails.netAmount,
                  location: userRecord?.location,
                  CreatedBy: userRecord?.username,
                  concernname: patientdetails.concernname,
                  LabCode: patientdetails?.LabCode,
                })
              );
              console.log("formData", formData);
              console.log("formData", outsourcelab?.Request_Id);

              axios
                .post(`${urllink}/Billing/insert_billing_all_data`, formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then((response) => {
                  console.log(response);
                  axios
                    .post(
                      `${urllink}Billing/update_refering_hospital_request_status`,
                      { request_id: outsourcelab?.Request_Id }
                    )
                    .then((response) => {
                      console.log(response);
                      setType("PatientDetailCon");
                      dispatchvalue({ type: "Outsourcelab", value: null });
                      setTimeout(cleardataafterpost, 500);
                    })
                    .catch((error) => {
                      console.error(
                        "Error updating referring hospital request status:",
                        error
                      );
                    });
                })
                .catch((error) => {
                  console.error("Error inserting data:", error);
                });

              // if (doctorname.includes(patientdetails.Referingdoctor)) {
              //     axios.post(`${urllink}Billing/insert_doctor_payment_commission`, postcommissionfordoctor)

              // }

              setIsPrintButtonVisible(true);
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });

  const handleSubmit = () => {
    const requiredFields = [
      "visittype",
      "title",
      "patientname",
      "age",
      "gender",
    ];

    const missingFields = requiredFields.filter(
      (field) => !patientdetails[field] || patientdetails[field].trim() === ""
    );

    if (missingFields.length > 0) {
      alert(`Please fill all required fields: ${missingFields.join(", ")}.`);
      return;
    } else if (testdetails.length === 0) {
      alert('Select Test');
      return;
    }


    const isGenderOthers = patientdetails["gender"] === "Others";

    // Include age in required fields only if gender is not "Others"
    if (!isGenderOthers) {
      requiredFields.push("age");
    }

    const isFormValid = requiredFields.every(
      (field) => patientdetails[field].trim() !== ""
    );

    if (!isFormValid) {
      alert("Please fill all required fields.");
      return;
    }

    setIsPrintButtonVisible(false);

    setTimeout(() => {
      handlePrint();
    }, 900);
  };

  const cleardataafterpost = () => {
    setPatientDetails({
      visitid: "",
      visittype: "Walkin",
      title: "",
      patientname: "",
      fathername: "",
      dob: "",
      age: "",
      gender: "",
      maritalstatus: "",
      phlebotomisttype: "",
      phlebotomistname: "",
      Referingdoctor: "",
      Patientgroup: "",
      Doorno: "",
      Rural: "",
      Pincode: "",
      Street: "",
      // District: "",
      City: "",
      State: "",
      PhoneNo: "",
      Alternatephn: "",
      Email: "",
      Dispatchmethod: "",
      testmethod: "Individual",
      OutsourceType: "",
      OutsourceLab: "",
      concernname: "",
      timeperiod: "",
    });

    setorderdetails({
      testname: "",
      grossAmount: 0,
      discountType: "",
      discountValue: "",
      paymentMethod: "",
      receivedAmount: "",
      netAmount: 0,
      dueAmount: 0,
      AdditionalAmount: 0,
      Collectioncharges: 0
    });
    settestdetails([]);
    setvisitid("");
    setFormAmount([]);
  };

  // useEffect(() => {
  //   const pincode = patientdetails.Pincode;

  //   if (patientdetails.Pincode !== "") {
  //     const time = setTimeout(() => {
  //       axios.get(`https://api.postalpincode.in/pincode/${pincode}`)
  //         .then((response) => {
  //           // console.log(response);
  //           const ruraldata = response.data[0].PostOffice;
  //           const State = response.data[0].PostOffice[0].State;
  //           // const City = response.data[0].PostOffice[0].Block;
  //           const District = response.data[0].PostOffice[0].District
  //           // console.log('ruraldata', ruraldata);
  //           setrural(ruraldata);

  //           setPatientDetails(prev => ({
  //             ...prev,
  //             City: District,
  //             State: State,
  //           }))
  //         })
  //         .catch((error) => {
  //           console.error(error);
  //         });
  //     }, 200);

  //     return () => {
  //       clearTimeout(time);
  //     };
  //   }
  // }, [patientdetails.Pincode]);

  // console.log('userRecord', userRecord)

  const handleBillingModeChange = (event) => {
    const { value } = event.target;
    setPatientDetails({ ...patientdetails, testmethod: value });
  };

  useEffect(() => {
    let value = patientdetails.testmethod;
    if (value === "Individual") {
      axios
        .get(
          `${urllink}usercontrol/getalltestname?gender=${patientdetails.gender}`
        )
        .then((response) => {
          console.log();
          
          setTestName(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (value === "Group") {
      axios
        .get(`${urllink}usercontrol/getallgroupname`)
        .then((response) => {
          console.log(response.data);
          setTestName(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      axios
        .get(`${urllink}usercontrol/get_all_package_name`)
        .then((response) => {
          console.log(response.data);
          setTestName(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [patientdetails.testmethod, patientdetails.gender, urllink]);

  useEffect(() => {
    const totalPaidAmount = formAmount.reduce(
      (total, ele) => +total + +ele.paidamount,
      0
    );

    console.log(totalPaidAmount)
    console.log(initialState);
    const pendingamount = initialState.totalNetAmount - totalPaidAmount;
    // settotalPaidAmount(totalPaidAmount);
    // const totalnet = initialState.totalNetAmount
    setorderdetails((prev) => ({
      ...prev,
      receivedAmount: totalPaidAmount,
      dueAmount: pendingamount,
    }));
    // Remainingamount(formAmount)
  }, [formAmount, paymentdetail.paidamount, initialState]);

  useEffect(() => {
    if (patientdetails.Referingdoctor !== "") {
      const time = setTimeout(() => {
        axios
          .get(
            `${urllink}usercontrol/get_for_indivitual_doctor_commission?Referingdoctor=${patientdetails.Referingdoctor}`
          )
          .then((response) => {
            // console.log(response)
            const data = response.data.amount;
            setcommissionper(data);
          })
          .catch((error) => {
            console.error(error);
          });
      }, 500);
      return () => {
        clearTimeout(time);
      };
    }
  }, [patientdetails.Referingdoctor, urllink]);

  console.log("ClinicDetials", ClinicDetials);

  const hasHistopathology = testdetails.some(
    (test) => test.Department === "HISTOPATHOLOGY"
  );
  const hasMicrobiology = testdetails.some(
    (test) => test.Department === "MICROBIOLOGY"
  );

  const forPrintData = () => {
    return (
      <div className="billing-invoice santhu_billing" id="reactprintcontent">
        <div className="New_billlling_invoice_head">
          <div className="new_billing_logo_con">
            <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
          </div>
          <div className="new_billing_address_1 ">
            <span>{ClinicDetials.ClinicName}</span>
            <div>
              <span>{ClinicDetials.ClinicAddress},</span>
              <span>
                {ClinicDetials.ClinicCity +
                  "," +
                  ClinicDetials.ClinicState +
                  "," +
                  ClinicDetials.ClinicCode}
              </span>
            </div>
            <div>
              <span>{ClinicDetials.ClinicMobileNo + " , "}</span>
              <span>{ClinicDetials.ClinicLandLineNo + " , "}</span>
              <span>{ClinicDetials.ClinicMailID}</span>
            </div>
          </div>
        </div>
        <h2
          style={{
            display: "flex",
            justifyContent: "center",
            color: "var(--labelcolor)",
            fontWeight: 600,
            padding: "10px",
          }}
        >
          Billing Invoice
        </h2>

        <div className="new_billing_address uwed_u7mm">
          <div className="new_billing_address_2 mjusd_0i9k">
            <div className="Register_btn_con_barcode" id="get_imagecontent_1">
              <Barcode
                value={patinetbillingbarcode || ""}
                lineColor="black"
                height={40}
                width={1.2}
                fontSize={12}
              />
            </div>
            <div className="new_billing_div">
              <label>
                Patient Name <span>:</span>
              </label>
              <span>{patientdetails.patientname.toUpperCase()}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Visit ID <span>:</span>
              </label>
              <span>{visitid}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Age <span>:</span>
              </label>
              <span>{patientdetails.age}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Gender <span>:</span>
              </label>
              <span>{patientdetails.gender}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Address <span>:</span>
              </label>
              <span>{patientdetails.City}</span>
            </div>
          </div>


          <div className="new_billing_address_2">
            <div className="Register_btn_con_barcode" id="get_imagecontent_2">
              <Barcode
                value={barcode || ""}
                lineColor="black"
                height={40}
                width={1.2}
                fontSize={12}
              />
            </div>
            <div className="new_billing_div">
              <label>
                Invoice No <span>:</span>
              </label>
              <span>{invoiceno}</span>
            </div>
            <div className="new_billing_div">
              <label>
                GSTIN No <span>:</span>
              </label>
              <span>{ClinicDetials.ClinicGST}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Physician Name <span>:</span>
              </label>
              <span>{patientdetails.Referingdoctor}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Date <span>:</span>
              </label>
              <span>{Billing_date}</span>
            </div>
            <div className="new_billing_div">
              <label>
                {" "}
                Patient Mobile No <span>:</span>
              </label>
              <span>{patientdetails.PhoneNo}</span>
            </div>
          </div>
        </div>

        <br />

        <div className="Selected-table-container">
        <table className="selected-medicine-table2">
        <thead>
              <tr>
                <th>S.No</th>
                <th>Test Method</th>
                <th>Service Name</th>

                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {testdetails.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{row.test_code}</td>
                  <td>{row.test_name}</td>
                  <td>{row.cost_per_reportable}</td>
                </tr>
              
              ))}
              <tr>
                 <td>-</td>
                  <td>-</td>
                  <td>Blood Collection Charges</td>
                  <td>{orderdetails.Collectioncharges}</td>
              </tr>
              
            </tbody>
          </table>
        </div>
        <br />
        <div
          className="new_billing_invoice_detials "
          style={{ paddingBottom: "10px", height: "auto" }}
        >
          <div className="invoice_detials_total_1 neww_invoicedetials">
            <div className="total_con_bill">
              <div className="bill_body jkued7">
                <label>
                  {" "}
                  Items Count <span>:</span>
                </label>
                <span>{testdetails.length}</span>
              </div>
              {/* <div className="bill_body jkued7">
                          <label> Unit <span>:</span></label>
                          <span>{orderdetails.totalUnits}</span>
                      </div> */}
              <div className="bill_body jkued7">
                <label>
                  {" "}
                  Amount <span>:</span>
                </label>
                <span>{orderdetails.netAmount}</span>
              </div>
              <div className="bill_body jkued7">
                <label>
                  {" "}
                  Discount <span>:</span>
                </label>
                <span>{initialState.totalDiscount}</span>
              </div>
              <div className="bill_body jkued7">
                <label>
                  {" "}
                  Billed By <span>:</span>
                </label>
                <span>{userRecord?.username}</span>
              </div>
            </div>

            <div className="total_con_bill">
              <div className="bill_body jkued7">
                <label>
                  Net Amount <span>:</span>
                </label>
                <span>{orderdetails.netAmount}</span>
              </div>
              {formAmount.map((row, index) => (
                <div key={index} className="bill_body jkued7">
                  {/* <div className="item-index">{index + 1}</div> */}
                  <label>
                    {row.Billpay_method}
                    <span>:</span>
                  </label>
                  <span>{row.paidamount}</span>
                </div>
              ))}
              <div className="bill_body jkued7">
                <label>
                  {" "}
                  Recived <span>:</span>
                </label>
                <span>{orderdetails.receivedAmount} </span>
              </div>
            </div>
          </div>
          <br />
          <div className="invoice_detials_total_1 neww_invoicedetials">
            <div className="total_con_bill">
              <div
                className="bill_body"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <label>
                  {" "}
                  Amount In Words<span>:</span>
                </label>
                <span style={{ color: "grey" }}>
                  {numberToWords(+orderdetails.netAmount)}{" "}
                </span>
              </div>
            </div>
          </div>
          <br />
          <div className="Register_btn_con_QRcode_fix" style={{marginLeft:'10px'}}>
            <QRCode
              value={`${urllink}Billing/get_report?inv=${invoiceno}`}
              size={106}
              level="H"
              bgColor="#ffffff"
              fgColor="#000000"
            />
          </div>

          <div className="signature-section909">
            <p className="disclaimer23">
              This page is created automatically without a signature.
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isPrintButtonVisible ? (
        <div className="Supplier_Master_form_Container">
          <div className="Supplier_Master_form_Container_Header">
            <h3>Billing </h3>
          </div>
          {/* <BarcodeDisplay patientDetails={patientDetails} barcode={barcode} /> */}

          <div className="Supplier_Master_form_sub_con">
            <div className="Supplier_Master_form_sub_con_div">
              <div className="Supplier_Master_form_sub_con_div_1">
              <div style={{width:'100%' ,display:"flex" ,justifyContent:'center'}}>
                <ToggleButtonGroup
                  value={type}
                  exclusive
                  onChange={handleToggleChange}
                  aria-label="Platform"
                >
                  <ToggleButton
                    value="PatientDetailCon" // Set the value prop here
                    style={{
                      backgroundColor:
                        type === "PatientDetailCon"
                          ? "var(--ProjectColor)"
                          : "inherit",
                    }}
                    className="Supplier_Master_form_sub_con_div_1_toggle"
                  >
                    Patient Details
                  </ToggleButton>
                  {/* <ToggleButton
                    value="CommunicationAddressCon" // Set the value prop here
                    style={{
                      backgroundColor:
                        type === "CommunicationAddressCon"
                          ? "var(--ProjectColor)"
                          : "inherit",
                    }}
                    className="Supplier_Master_form_sub_con_div_1_toggle"
                  >
                    Test Informations
                  </ToggleButton> */}
                </ToggleButtonGroup>
</div>

                {type === "PatientDetailCon" ? (
                  <div className="Supplier_Master_form_sub_con_div_1">
                    <div className="Supplier_Master_form_Con_Row">
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="state"
                        >
                          Patient Name{" "}
                          <span>
                            {" "}
                            <select
                              id="title"
                              name="title"
                              value={patientdetails.title}
                              onChange={handleInputChange}
                              className="krfekj_09"
                            >
                              <option value="">Title</option>
                              <option value="Mr">Mr.</option>
                              <option value="Ms">Ms.</option>
                              <option value="Mrs">Mrs.</option>
                              <option value="Baby">Baby.</option>
                              <option value="Dr">Dr.</option>
                              <option value="BabyOf">Baby.of.</option>
                              <option value="Child">Child.</option>
                              <option value="Sister">Sister</option>
                              <option value="Master">Master</option>
                              <option value="Child">Child.</option>
                              <option value="Others">Others.</option>
                            </select>{" "}
                            :
                          </span>
                        </label>
                        <div className="Supplier_Master_form_Con_div_Row_title_name_fr5">
                          <input
                            type="text"
                            name="patientname"
                            list="patientname"
                            // placeholder="Enter Patient Name"
                            value={patientdetails.patientname.toUpperCase()}
                            onChange={handleInputChange}
                            required
                            className="Supplier_Master_form_Con_div_Row_input11 Supplier_Master_form_Con_div_Row_input11_o"
                          // style={{ width: "180px" }}
                          />
                          <datalist id="patientname">
                            {patientname?.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </datalist>
                        </div>
                      </div>
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="street"
                        >
                          PhoneNo(WhatsApp) <span>:</span>
                        </label>

                        <input
                          type="number"
                          name="PhoneNo"
                          // placeholder="Enter Phone No"
                          value={patientdetails.PhoneNo}
                          onChange={handleInputChange}
                          maxLength={10}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                    </div>

                    <div className="Supplier_Master_form_Con_Row">
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="state"
                        >
                          Visit Id<span>:</span>
                        </label>
                        <input
                          type="text"
                          name="visitid"
                          // placeholder="Enter Visit Id"
                          value={visitid}
                          required
                          readOnly
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="email"
                        >
                          Gender<span>:</span>
                        </label>
                        {isDoctor ? (
                          <select
                            name="gender"
                            value={patientdetails.gender}
                            onChange={(e) => handleInputChange(e)}
                            className="Supplier_Master_form_Con_div_Row_input"
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        ) : (
                          <input
                            type="text"
                            name="gender"
                            value={patientdetails.gender}
                            onChange={handleInputChange}
                            readOnly
                            className="Supplier_Master_form_Con_div_Row_input"
                          />
                        )}
                      </div>
                    </div>

                    <div className="Supplier_Master_form_Con_Row">
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="place"
                        >
                          Date Of Birth<span>:</span>
                        </label>
                        <input
                          type="Date"
                          name="dob"
                          value={patientdetails.dob}
                          onChange={handleInputChange}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                          max={new Date().toISOString().split("T")[0]} // Sets the max attribute to today's date
                        />
                      </div>
                      {patientdetails.title.toLowerCase().includes("baby") && (
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="street"
                          >
                            Age<span>:</span>
                          </label>
                          <div className="Supplier_Master_form_Con_div_Row_title_name_ooooop">
                            <input
                              type="number"
                              name="age"
                              // placeholder="Enter Age"
                              value={patientdetails.age}
                              onChange={handleInputChange}
                              required
                              className="Supplier_Master_form_Con_div_Row_input"
                            />
                            <select
                              id="timeperiod"
                              name="timeperiod"
                              value={patientdetails.timeperiod}
                              onChange={handleInputChange}
                              className="krfekj_09"
                            >
                              <option value="hour">Hours</option>
                              <option value="day">Days</option>
                              <option value="month">Months</option>
                              <option value="week">Weeks</option>
                              <option value="year">Years</option>
                            </select>
                          </div>
                        </div>
                      )}
                      {!patientdetails.title.toLowerCase().includes("baby") && (
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="street"
                          >
                            Age<span>:</span>
                          </label>
                          <input
                            type="number"
                            name="age"
                            // placeholder="Enter Age"
                            value={patientdetails.age}
                            onChange={handleInputChange}
                            required
                            className="Supplier_Master_form_Con_div_Row_input"
                          />
                        </div>
                      )}
                    </div>
                    <div className="Supplier_Master_form_Con_Row">
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="street"
                        >
                          Email <span>:</span>
                        </label>
                        <input
                          type="email"
                          name="Email"
                          // placeholder="Enter Email"
                          value={patientdetails.Email}
                          onChange={handleInputChange}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>

                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="state"
                        >
                          Visit Type<span>:</span>
                        </label>
                        <select
                          id="visittype"
                          name="visittype"
                          className="Supplier_Master_form_Con_div_Row_input"
                          value={patientdetails.visittype}
                          onChange={handleInputChange}
                        >
                          {/* <option value="">Select</option> */}
                          <option value="Walkin">Walk-in</option>
                          <option value="InHouse">In-House</option>
                          <option value="Outsource">Out-Source</option>
                          {/* <option value="others">Others</option> */}
                        </select>
                      </div>
                    </div>

                    <div className="Supplier_Master_form_Con_Row">
                      {patientdetails.visittype === "Outsource" && (
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="phone2"
                          >
                            Outsource Type<span>:</span>
                          </label>
                          <select
                            id="OutsourceType"
                            name="OutsourceType"
                            value={patientdetails.OutsourceType}
                            onChange={handleInputChange}
                            className="Supplier_Master_form_Con_div_Row_input"
                          >
                            <option value="">Select</option>
                            <option value="Internal">Internal</option>
                            <option value="External">External</option>
                          </select>
                        </div>
                      )}

                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="phone2"
                        >
                          Refering Doctor<span>:</span>
                        </label>
                        <input
                          type="text"
                          name="Referingdoctor"
                          list="referingdoc"
                          // placeholder="Enter Reference Doctor"
                          value={patientdetails.Referingdoctor}
                          onChange={handleInputChange}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                        <datalist id="referingdoc">
                          {refdoc?.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </datalist>
                      </div>

                      {patientdetails.visittype !== "Outsource" && (
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="phone2"
                          >
                            Refering-Hospital<span>:</span>
                          </label>
                          <input
                            id="concernname"
                            name="concernname"
                            list="concernnamelist"
                            value={patientdetails.concernname}
                            onChange={handleInputChange}
                            // placeholder="Enter your Address"
                            required
                            className="Supplier_Master_form_Con_div_Row_input"
                          />
                          <datalist id="concernnamelist">
                            {labname?.map((item, index) => (
                              <option key={index} value={item.Lab_Name}>
                                {item.Lab_Name}
                              </option>
                            ))}
                          </datalist>
                        </div>
                      )}
                    </div>
                    <div className="Supplier_Master_form_Con_Row">
                      {patientdetails.visittype === "Outsource" && (
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="phone2"
                          >
                            Outsource Lab<span>:</span>
                          </label>
                          <input
                            id="Patientgroup"
                            name="Patientgroup"
                            list="OutsourceLab"
                            value={patientdetails.Patientgroup}
                            onChange={handleInputChange}
                            // placeholder="Enter your Address"
                            required
                            className="Supplier_Master_form_Con_div_Row_input"
                          />
                          <datalist id="OutsourceLab">
                            {franchaise?.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </datalist>
                        </div>
                      )}

                      {patientdetails.visittype !== "Outsource" && (
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="phone2"
                          >
                            Ratecard Type<span>:</span>
                          </label>
                          <input
                            id="Patientgroup"
                            name="Patientgroup"
                            list="Patientgroup1"
                            value={patientdetails.Patientgroup}
                            onChange={handleInputChange}
                            // placeholder="Enter your Address"
                            required
                            readOnly
                            className="Supplier_Master_form_Con_div_Row_input"
                          />
                          <datalist id="Patientgroup1">
                            {franchaise?.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </datalist>
                        </div>
                      )}
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="phone2"
                        >
                          Dispatch Method<span>:</span>
                        </label>
                        <select
                          id="dispatchMode"
                          name="Dispatchmethod"
                          value={patientdetails.Dispatchmethod}
                          onChange={handleInputChange}
                          className="Supplier_Master_form_Con_div_Row_input"
                        >
                          <option value="">Select</option>
                          <option value="sms">SMS</option>
                          <option value="wp">whatsapp</option>
                          <option value="email">EMAIL</option>
                          <option value="courier">Courier</option>
                        </select>
                      </div>
                    </div>
                    <div className="Supplier_Master_form_Con_Row">
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="CapturedFile4"
                        >
                          TRF File<span>:</span>
                        </label>
                        {/* <div className="RegisterForm_2"> */}
                        <input
                          type="file"
                          id="CapturedFile4"
                          name="CapturedFile4"
                          className="hiden-nochse-file"
                          accept="image/*,.pdf"
                          onChange={handleInputChange}
                          required
                          style={{ display: "none" }}
                        />
                        <label
                          htmlFor="CapturedFile4"
                          className="RegisterForm_1_btns choose_file_update"
                        >
                          Choose File
                        </label>
                        {/* </div> */}
                      </div>
                    </div>
                    <br />

                    <div style={{width:'100%' ,display:"flex" ,justifyContent:'center'}}>
                    <ToggleButton
                      value="orderdetails"
                      style={{
                        color: "black",
                        background: "var(--ProjectColor)",
                        font: "14px",
                      }}
                      className="Supplier_Master_form_sub_con_div_1_toggle"
                    >
                      Billing Informations
                    </ToggleButton>
                    </div>

                    <div className="Supplier_Master_form_sub_con_div_1">
                      <div className="Supplier_Master_form_Con_Row">
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor=""
                          >
                            Billing Mode:
                          </label>
                          <select
                            id="testmethod"
                            name="testmethod"
                            className="Supplier_Master_form_Con_div_Row_input"
                            value={patientdetails.testmethod}
                            onChange={handleBillingModeChange}
                          >
                            {/* <option value="">Select</option> */}
                            <option value="Individual">Individual</option>
                            <option value="Group">Group</option>
                            <option value="Offers">Packages</option>
                          </select>
                        </div>
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="shortName"
                          >
                            Test Name<span>:</span>
                          </label>
                          <div className="Supplier_Master_form_Con_div_Row_title_name_ooooop">
                            <input
                              id="testname"
                              name="testname"
                              list="browsers1"
                              value={orderdetails.testname}
                              onChange={handleInputChange1}
                              // placeholder="Enter your Address"
                              required
                              className="Supplier_Master_form_Con_div_Row_input"
                            />
                            {patientdetails.testmethod === "Individual" && (
                              <datalist id="browsers1">
                                {Array.isArray(testname) &&
                                  testname.map((item, index) => (
                                    <option key={index} value={item.Test_Name}>
                                      {item.Test_Name}
                                    </option>
                                  ))}
                              </datalist>
                            )}
                            {patientdetails.testmethod === "Group" && (
                              <datalist id="browsers1">
                                {Array.isArray(testname) &&
                                  testname?.map((item, index) => (
                                    <option key={index} value={item.groupname}>
                                      {item.groupname}
                                    </option>
                                  ))}
                              </datalist>
                            )}
                            {patientdetails.testmethod === "Offers" && (
                              <datalist id="browsers1">
                                {Array.isArray(testname) &&
                                  testname?.map((item, index) => (
                                    <option
                                      key={index}
                                      value={item.package_name}
                                    >
                                      {item.package_name}
                                    </option>
                                  ))}
                              </datalist>
                            )}
                            <button
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                              }}
                              onClick={handleSearch}
                            >
                              <AddCardIcon />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="Supplier_Master_form_sub_con_div_1"></div>
                )}
              </div>
            </div>


            <div className="Supplier_Master_form_sub_con_div">
       
              <div className="Supplier_Master_form_sub_con_div_1">

                       <div style={{width:'100%' ,display:"flex" ,justifyContent:'center'}}>
                <ToggleButton
                  value="orderdetails"
                  style={{
                    color: "black",
                    background: "var(--ProjectColor)",
                    font: "14px",
                    width: '100%',
                    alignItems: 'center'
                  }}
                  className="Supplier_Master_form_sub_con_div_1_toggle"
                >
                  Order Details
                </ToggleButton>
                           </div>
                </div>

                <div className="Supplier_Master_form_sub_con_div_1">
                  <div
                    className="Selected-table-container"
                    style={{ marginBottom: "50px" }}
                  >
                    <table className="selected-medicine-table2 ">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Code</th>
                          <th>Name</th>
                          <th>Amount</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {testdetails?.map((row, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td> <td>{row.test_code}</td>
                            <td>{row.test_name}</td>
                            <td>
                              {Number(row.cost_per_reportable).toFixed(2)}
                            </td>
                            <td>
                              <button
                                onClick={() => handleDeleted(row.test_name)}
                              >
                                <MdOutlineCancel />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="Supplier_Master_form_Con_Row">
                    {hasHistopathology && (
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="HistopathologyNumber"
                        >
                          Histopathology Number <span>:</span>
                        </label>
                        <input
                          type="text"
                          name="HistopathologyNumber"
                          id="HistopathologyNumber"
                          value={orderdetails?.HistopathologyNumber}
                          onChange={handleInputChange1}
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                    )}

                    
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="Collectioncharges"
                        >
                          Collection Chrgs <span>:</span>
                        </label>
                        <input
                          type="number"
                          name="Collectioncharges"
                          id="Collectioncharges"
                          value={orderdetails?.Collectioncharges}
                          onChange={handleInputChange1}
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                    

                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="AdditionalAmount"
                      >
                        Additional Amount <span>:</span>
                      </label>
                      <input
                        type="number"
                        name="AdditionalAmount"
                        id="AdditionalAmount"
                        placeholder=""
                        value={orderdetails?.AdditionalAmount}
                        onChange={handleInputChange1}
                        className="Supplier_Master_form_Con_div_Row_input"
                      />
                    </div>
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="AdditionalAmountReason"
                      >
                        Reason <span>:</span>
                      </label>
                      <input
                        type="text"
                        name="AdditionalAmountReason"
                        placeholder=""
                        value={orderdetails.AdditionalAmountReason}
                        onChange={handleInputChange1}
                        className="Supplier_Master_form_Con_div_Row_input"
                      />
                    </div>
                  </div>
                  <br />

                  <div className="Supplier_Master_form_Con_Row">
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="shortName"
                      >
                        Discount Type<span>:</span>
                      </label>
                      <select
                        id="discountType"
                        name="discountType"
                        value={orderdetails.discountType}
                        onChange={handleInputChange1}
                        className="Supplier_Master_form_Con_div_Row_input"
                      >
                        <option value="">Select</option>
                        <option value="Cash">Cash</option>
                        <option value="Percentage">Percentage</option>
                      </select>
                    </div>
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="webSite"
                      >
                        Discount<span>:</span>
                      </label>
                      <input
                        type="text"
                        name="discountValue"
                        // placeholder="Enter Discount"
                        value={orderdetails.discountValue}
                        onChange={handleInputChange1}
                        required
                        className="Supplier_Master_form_Con_div_Row_input"
                      />
                    </div>
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="webSite"
                      >
                        Discount Reason<span>:</span>
                      </label>
                      <input
                        type="text"
                        name="DiscountReason"
                        // placeholder="Enter Discount"
                        value={orderdetails.DiscountReason}
                        onChange={handleInputChange1}
                        className="Supplier_Master_form_Con_div_Row_input"
                      />
                    </div>
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="Billpay_method"
                      >
                        Payment Mode<span>:</span>
                      </label>
                      <select
                        id="dispatchMode"
                        name="Billpay_method"
                        value={paymentdetail.Billpay_method}
                        onChange={handleinputchangepay}
                        className="Supplier_Master_form_Con_div_Row_input"
                      >
                        <option value="">Select</option>
                        <option value="Cash">Cash</option>
                        <option value="Card">Card</option>
                        <option value="OnlinePayment">Online Payment</option>
                      </select>
                    </div>
                  </div>

                  <div className="Supplier_Master_form_Con_Row">
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="webSite"
                      >
                        Received Amount<span>:</span>
                      </label>

                      <input
                        type="number"
                        name="paidamount"
                        // placeholder="Enter Received Amount"
                        value={paymentdetail.paidamount}
                        onChange={handleinputchangepay}
                        required
                        className="Supplier_Master_form_Con_div_Row_input"
                      />
                    </div>
                  </div>
                  {paymentdetail.Billpay_method === "Card" ? (
                    <>
                      <div className="Supplier_Master_form_Con_Row">
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="CardName"
                          >
                            Card Type <span>:</span>
                          </label>
                          <select
                            name="CardType"
                            value={orderdetails.CardType}
                            onChange={handleInputChange1}
                            className="Supplier_Master_form_Con_div_Row_input"
                          >
                            <option value="">Select</option>
                            <option value="Credit">Credit</option>
                            <option value="Debit">Debit</option>
                          </select>
                        </div>
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="CardNumber"
                          >
                            Card Number<span>:</span>
                          </label>
                          <input
                            type="text"
                            name="CardNumber"
                            // placeholder="Enter Discount"
                            value={orderdetails.CardNumber}
                            onChange={handleInputChange1}
                            required
                            className="Supplier_Master_form_Con_div_Row_input"
                          />
                        </div>
                      </div>
                      <div className="Supplier_Master_form_Con_Row">
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="CardName"
                          >
                            Card Name <span>:</span>
                          </label>
                          <input
                            type="text"
                            name="CardName"
                            // placeholder="Enter Discount"
                            value={orderdetails.CardName}
                            onChange={handleInputChange1}
                            required
                            className="Supplier_Master_form_Con_div_Row_input"
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  {paymentdetail.Billpay_method === "OnlinePayment" && (
                    <>
                      <div className="Supplier_Master_form_Con_Row">
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="TransactionId"
                          >
                            OnlinePayment Number/ UPI Id <span>:</span>
                          </label>
                          <input
                            type="text"
                            name="UPI_Mob"
                            // placeholder="Enter Discount"
                            value={orderdetails.UPI_Mob}
                            onChange={handleInputChange1}
                            required
                            className="Supplier_Master_form_Con_div_Row_input"
                          />
                        </div>
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="TransactionId"
                          >
                            Transaction Id <span>:</span>
                          </label>
                          <input
                            type="text"
                            name="TransactionId"
                            // placeholder="Enter Discount"
                            value={orderdetails.TransactionId}
                            onChange={handleInputChange1}
                            required
                            className="Supplier_Master_form_Con_div_Row_input"
                          />
                        </div>
                      </div>
                    </>
                  )}


<div className="Register_btn_con">
                  <button
            className="RegisterForm_1_btns"
                    onClick={isEdit !== null ? handleUpdate : handleAdd}
                  >
                    {isEdit !== null ? "Update" : "Add"}
                  </button>
                  </div>

                  <div
                    className="Selected-table-container"
                    style={{ marginBottom: "50px" }}
                  >
                    <table className="selected-medicine-table2 ">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th> Payment Method</th>
                          <th>Paid Amount</th>
                          {/* <th>Ordered Time</th>
                      <th>TAT Time</th> */}
                          <th>Edit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formAmount.map((row, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td> <td>{row.Billpay_method}</td>
                            <td>{row.paidamount}</td>
                            {/* <td>Ordered Time </td>{" "}
                        <td>TAT Time </td>{" "} */}
                            <td>
                              <button onClick={() => handleEdit(index)}>
                                <EditIcon />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="Supplier_Master_form_Con_Row">
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="shortName"
                      >
                        Net Amount<span>:</span>
                      </label>
                      <input
                        type="text"
                        name="netAmount"
                        placeholder="Enter Net Amount"
                        value={orderdetails.netAmount}
                        onChange={handleInputChange1}
                        required
                        className="Supplier_Master_form_Con_div_Row_input"
                      />
                    </div>
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="webSite"
                      >
                        Due Amount<span>:</span>
                      </label>
                      <input
                        type="text"
                        name="dueAmount"
                        placeholder="Enter Balance Amount"
                        value={orderdetails.dueAmount}
                        onChange={handleInputChange1}
                        required
                        className="Supplier_Master_form_Con_div_Row_input"
                      />
                    </div>
                  </div>
                </div>
           


   

            </div>
          </div>
          <div className="Supplier_Master_form_Save">
            <button
              className="Supplier_Master_form_Save_button"
              onClick={handleSubmit}
            >
              Print
            </button>
          </div>
        </div >
      ) : (
        forPrintData()
      )
      }
    </>
  );
}

export default Billing;
