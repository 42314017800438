import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function LabpatientsEdit() {
  const patientEditData = useSelector(
    (state) => state.userRecord?.forpatienteditdatalab
  );
  console.log(patientEditData);
  const navigate = useNavigate();
  const dispatchvalue = useDispatch();
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const [PatientRegisterformData, setPatientRegisterformData] = useState({
    Patient_Id: "",
    Patient_Name: "",
    // PatientPhoto: null,
    DOB: "",
    Age: "",
    // AdhaarNumber: "",
    // Alternate_No: "",
    Email: "",
    // Father_Name: "",
    Gender: "",
    // Marital_Status: "",
    Phone: "",
    // Relation: "",
    // SurName: "",
    Time_Period: "",
    Title: "",
    communicationAddress: "",
    Location: userRecord?.location,
    CreatedBy: userRecord?.username,
  });

  const handleChange12 = (e) => {
    const { name, value, files } = e.target;

    // Limit Phone and Alternate_No to 10 characters
    let updatedValue = value;
    if ((name === "Phone" || name === "Alternate_No") && value.length > 10) {
      updatedValue = value.slice(0, 10);
    }

    setPatientRegisterformData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : updatedValue,
    }));
  };

  console.log(PatientRegisterformData);

  useEffect(() => {
    const fetchImageAsBlob = async () => {
      if (patientEditData?.PatientPhoto) {
        try {
          const response = await fetch(patientEditData.PatientPhoto);
          const blob = await response.blob();
          const file = new File([blob], "patientPhoto.jpg", {
            type: blob.type,
          });

          // Update state with blob file as PatientPhoto
          setPatientRegisterformData((prevData) => ({
            ...prevData,
            PatientPhoto: file,
          }));
        } catch (error) {
          console.error("Error fetching and converting image to blob:", error);
        }
      }
    };

    if (patientEditData && Object.keys(patientEditData).length > 0) {
      // Set all other data
      setPatientRegisterformData((prevData) => ({
        ...prevData,
        Patient_Id: patientEditData.Patient_Id,
        Patient_Name: patientEditData.Patient_Name,
        DOB: patientEditData.DOB,
        Age: patientEditData.Age,
        // AdhaarNumber: patientEditData.AdhaarNumber,
        // Alternate_No: patientEditData.Alternate_No,
        Email: patientEditData.Email,
        // Father_Name: patientEditData.Father_Name,
        Gender: patientEditData.Gender,
        // Marital_Status: patientEditData.Marital_Status,
        Phone: patientEditData.Phone,
        // Relation: patientEditData.Relation,
        // SurName: patientEditData?.SurName,
        Time_Period: patientEditData?.Time_Period,
        Title: patientEditData?.Title,
        communicationAddress: patientEditData?.communicationAddress,
      }));

      // Fetch the PatientPhoto as blob
      fetchImageAsBlob();
    }
  }, [patientEditData, urllink]);

  const handleSubmit = () => {
    const datatosend = new FormData();

    Object.entries(PatientRegisterformData).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        datatosend.append(key, value);
      }
    });

    axios
      .post(`${urllink}Billing/Update_Patient_Details`, datatosend)
      .then((res) => {
        console.log(res);
        successMsg(res.data.Message);
        dispatchvalue({ type: "forpatienteditdatalab", value: {} });
        navigate("/Home/Patientlistforlab");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const successMsg = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Patient Edit</h4>
      </div>
      <br />
      <div className="Add_items_Purchase_Master">
        <span>Personal Details</span>
      </div>
      <br />
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="Patient_Id">
            Patient ID <span>:</span>
          </label>
          <input
            type="text"
            id="Patient_Id"
            name="Patient_Id"
            value={PatientRegisterformData?.Patient_Id}
            onChange={handleChange12}
            required
            disabled
          />
        </div>

        <div className="RegisForm_1 smalefhiu">
          <label htmlFor="Patient_Name">
            Patient Name<span>:</span>
          </label>
          <select
            id="Title"
            name="Title"
            value={PatientRegisterformData.Title}
            onChange={handleChange12}
            className="krfekj_09"
            autocomplete="off"
          >
            <option value="">Title</option>
            <option value="Dr">Dr.</option>
            <option value="Mr">Mr.</option>
            <option value="Ms">Ms.</option>
            <option value="Mrs">Mrs.</option>
            <option value="Others">Others.</option>
          </select>
          <input
            type="text"
            id="Patient_Name"
            name="Patient_Name"
            required
            value={PatientRegisterformData.Patient_Name}
            onChange={handleChange12}
          />
        </div>

        {/* <div className="RegisForm_1 smalefhiu">
          <label htmlFor="SurName">
            Sur Name<span>:</span>
          </label>
          <select
            id="Relation"
            name="Relation"
            value={PatientRegisterformData.Relation}
            onChange={handleChange12}
            className="krfekj_09"
            autocomplete="off"
          >
            <option value="S/O">Son of</option>
            <option value="D/O">Daughter of</option>
            <option value="W/O">Wife of</option>
            <option value="H/O">Husband of</option>
            <option value="F/O">Father of</option>
            <option value="M/O">Mother of</option>
          </select>
          <input
            type="text"
            id="SurName"
            name="SurName"
            required
            value={PatientRegisterformData.SurName}
            onChange={handleChange12}
          />
        </div> */}

        <div className="RegisForm_1">
          <label htmlFor="Gender">
            Gender <span>:</span>
          </label>
          <input
            type="text"
            id="Gender"
            name="Gender"
            value={PatientRegisterformData?.Gender}
            onChange={handleChange12}
            required
          />
        </div>

        <div className="RegisForm_1 smalefhiu">
          <label htmlFor="Age">
            Age<span>:</span>
          </label>
          <select
            id="Time_Period"
            name="Time_Period"
            value={PatientRegisterformData.Time_Period}
            onChange={handleChange12}
            className="krfekj_09"
            autocomplete="off"
          >
            <option value="year">Years</option>
            <option value="day">Days</option>
            <option value="month">Months</option>
          </select>
          <input
            type="text"
            id="Age"
            name="Age"
            required
            value={PatientRegisterformData.Age}
            onChange={handleChange12}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="DOB">
            DOB <span>:</span>
          </label>
          <input
            type="date"
            id="DOB"
            name="DOB"
            value={PatientRegisterformData?.DOB}
            onChange={handleChange12}
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="Phone">
            Phone Number <span>:</span>
          </label>
          <input
            type="number"
            id="Phone"
            name="Phone"
            value={PatientRegisterformData?.Phone}
            onChange={handleChange12}
            required
          />
        </div>
        {/* <div className="RegisForm_1">
          <label htmlFor="Alternate_No">
            Alternate Phone Number <span>:</span>
          </label>
          <input
            type="number"
            id="Alternate_No"
            name="Alternate_No"
            value={PatientRegisterformData?.Alternate_No}
            onChange={handleChange12}
            required
          />
        </div> */}

        {/* <div className="RegisForm_1">
          <label htmlFor="AdhaarNumber">
            Adhaar Number <span>:</span>
          </label>
          <input
            type="number"
            id="AdhaarNumber"
            name="AdhaarNumber"
            value={PatientRegisterformData?.AdhaarNumber}
            onChange={handleChange12}
            required
          />
        </div> */}
        <div className="RegisForm_1">
          <label htmlFor="Email">
            Email <span>:</span>
          </label>
          <input
            type="text"
            id="Email"
            name="Email"
            value={PatientRegisterformData?.Email}
            onChange={handleChange12}
            required
          />
        </div>
        {/* <div className="RegisForm_1">
          <label htmlFor="Marital_Status">
            Marital Status <span>:</span>
          </label>
          <input
            type="text"
            id="Marital_Status"
            name="Marital_Status"
            value={PatientRegisterformData?.Marital_Status}
            onChange={handleChange12}
            required
          /> */}
        {/* </div> */}

        <div className="RegisForm_1">
          <label htmlFor="communicationAddress">
            Communication Address <span>:</span>
          </label>
          <input
            type="text"
            id="communicationAddress"
            name="communicationAddress"
            value={PatientRegisterformData?.communicationAddress}
            onChange={handleChange12}
            required
          />
        </div>
      </div>
      <br />
      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handleSubmit}>
          Update
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default LabpatientsEdit;
