import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./SideBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import GridViewIcon from '@mui/icons-material/GridView';
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import RecyclingIcon from "@mui/icons-material/Recycling";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

import {
  faStethoscope,
  faMoneyBillAlt,
  faRightFromBracket,
  faPenNib,
  faPrint,
  faSackDollar,
  faUserTag,
  faChartBar,
  faAngleDown,
  faBuildingColumns,
  faSuitcaseMedical,
  faStore,
  faLayerGroup,
  faRegistered,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";

function Sidebar() {
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [mainAccess, setMainAccess] = useState([]);
  const [subAccess, setSubAccess] = useState([]);
  const sidebarRef = useRef(null);
  const navigate = useNavigate();

  // const handleSidebarToggle = () => {
  //   setSidebarOpen(!isSidebarOpen);
  // };
  const location = useLocation();

  const dispatchvalue = useDispatch();

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  useEffect(() => {
    dispatchvalue({ type: "isSidebarOpen", value: false });
    // Close the sidebar when the route changes
    console.log(location);
    const userRecord = localStorage.getItem("token");
    if (userRecord) {
      const decodedToken = (token) => {
        const payloadBase64 = token.split(".")[1];
        const decodedPayload = atob(payloadBase64);
        return JSON.parse(decodedPayload);
      };
      const decodedTokenData = decodedToken(userRecord);
      const setAccess1 =
        (decodedTokenData &&
          decodedTokenData.Access_one.split(",").map((item) => item.trim())) ||
        [];
      const setAccess2 =
        (decodedTokenData &&
          decodedTokenData.Access_two.split(",").map((item) => item.trim())) ||
        [];

      setMainAccess(setAccess1);
      setSubAccess(setAccess2);
    }
    // Split the string and then trim each element
  }, [location.pathname, dispatchvalue, location]);

  // console.log(subAccess);
  // console.log(mainAccess)
  useEffect(() => {
    // Disable text selection on the entire document
    const disableTextSelection = () => {
      document.body.style.userSelect = "none";
      document.body.style.MozUserSelect = "none";
      document.body.style.msUserSelect = "none";
    };
    disableTextSelection();
    // Enable text selection when the component is unmounted
    return () => {
      document.body.style.userSelect = "auto";
      document.body.style.MozUserSelect = "auto";
      document.body.style.msUserSelect = "auto";
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }); // This effect runs once when the component mounts

  const handleSubMenuClick = (subMenu) => {
    setOpenSubMenu(openSubMenu === subMenu ? null : subMenu);
  };

  const handleLogoutClick = () => {
    navigate("/");
    localStorage.clear();
  };

  return (
    <nav
      id="inventory_sidebar"
      className={isSidebarOpen ? "inventory_sidebar_open" : ""}
      ref={sidebarRef}
    >
      <div
        className="inv_sidebar_header"
        onMouseEnter={() =>
          dispatchvalue({ type: "isSidebarOpen", value: true })
        }
        onMouseLeave={() =>
          dispatchvalue({ type: "isSidebarOpen", value: false })
        }
      >
        <div className="inv_components_sidebar">
          {mainAccess.includes("A") && (
            <div
              className={
                isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
              }
              id="font_111"
            >
              <FontAwesomeIcon
                icon={faStethoscope}
                className="inventory_sidebar_icon"
              />
              {isSidebarOpen && (
                <span
                  className="icon-name"
                  onClick={() => {
                    navigate("/Home/ClinicMetrics");
                  }}
                >
                  Clinic Metrics
                </span>
              )}
            </div>
          )}

          {mainAccess.includes("B") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu1")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <HeadsetMicIcon
                  icon={faUserTag}
                  className="inventory_sidebar_icon"
                />
                {isSidebarOpen && (
                  <span className="icon-name">Receptionist</span>
                )}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu1" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>
              {isSidebarOpen && openSubMenu === "subsidebarmenu1" && (
                <div className="subSidebarmenuhover">
                  {subAccess.includes("B2-1") && (
                    <div
                      onClick={() => {
                        navigate("/Home/ReceptionistBilling");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Billing</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("B2-6") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Deu-History-List");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Due History </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("B2-2") && (
                    <div
                      onClick={() => {
                        navigate("/Home/PatientRequestlistforlab");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Request List</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("B2-3") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Bill-Cancellation");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Bill Cancellation</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("B2-4") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Patientlistforlab");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Patient List</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("B2-5") && (
                    <div
                      onClick={() => {
                        navigate("/Home/PaymentHistroy");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Payment History</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("B2-5") && (
                    <div
                      onClick={() => {
                        navigate("/Home/RequestListLab");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">External Request</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {mainAccess.includes("C") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu2")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <DeviceThermostatIcon
                  icon={faUserTag}
                  className="inventory_sidebar_icon"
                />
                {isSidebarOpen && (
                  <span className="icon-name">Phelobotomist</span>
                )}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu2" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>
              {isSidebarOpen && openSubMenu === "subsidebarmenu2" && (
                <div className="subSidebarmenuhover">
                  {subAccess.includes("C3-1") && (
                    <div
                      onClick={() => {
                        navigate("/Home/CaptureSamplelist");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Sample Waiting List</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("C3-2") && (
                    <div
                      onClick={() => {
                        navigate("/Home/PatientRequestlistforlab");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Request List</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("C3-3") && (
                    <div
                      onClick={() => {
                        navigate("/Home/SamplelistData");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Completed List</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {mainAccess.includes("D") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu3")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <FontAwesomeIcon
                  icon={faMoneyBillAlt}
                  className="inventory_sidebar_icon"
                />

                {isSidebarOpen && (
                  <span className="icon-name">Lab Technicians</span>
                )}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu3" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>
              {isSidebarOpen && openSubMenu === "subsidebarmenu3" && (
                <div className="subSidebarmenuhover">
                  {subAccess.includes("D4-1") && (
                    <div
                      onClick={() => {
                        navigate("/Home/AnalayseReportEntry");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Report Entry Que</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("D4-2") && (
                    <div
                      onClick={() => {
                        navigate("/Home/ValidatedQue");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Validated Que</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {mainAccess.includes("E") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu4")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <RecyclingIcon
                  icon={faMoneyBillAlt}
                  className="inventory_sidebar_icon"
                />

                {isSidebarOpen && (
                  <span className="icon-name">Senior Technicians</span>
                )}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu4" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>
              {isSidebarOpen && openSubMenu === "subsidebarmenu4" && (
                <div className="subSidebarmenuhover">
                  {subAccess.includes("E5-1") && (
                    <div
                      onClick={() => {
                        navigate("/Home/VerifyReportEntry");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Report Verify Que</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("E5-2") && (
                    <div
                      onClick={() => {
                        navigate("/Home/SrLabValidatedQue");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Validated Que</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {mainAccess.includes("F") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu5")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <LocalHospitalIcon
                  icon={faMoneyBillAlt}
                  className="inventory_sidebar_icon"
                />

                {isSidebarOpen && (
                  <span className="icon-name">Pathologist</span>
                )}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu5" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>
              {isSidebarOpen && openSubMenu === "subsidebarmenu5" && (
                <div className="subSidebarmenuhover">
                  {subAccess.includes("F6-1") && (
                    <div
                      onClick={() => {
                        navigate("/Home/AuthorizedReportEntry");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Report Approval Que</span>
                      )}
                    </div>
                  )}

                  {subAccess.includes("F6-2") && (
                    <div
                      onClick={() => {
                        navigate("/Home/PathValidatedQue");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Validated Que</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {mainAccess.includes("G") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu6")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <FontAwesomeIcon
                  icon={faPrint}
                  className="inventory_sidebar_icon"
                />
                {isSidebarOpen && <span className="icon-name">Dispatch</span>}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu6" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>
              {isSidebarOpen && openSubMenu === "subsidebarmenu6" && (
                <div className="subSidebarmenuhover">
                  {subAccess.includes("G7-1") && (
                    <div
                      onClick={() => {
                        navigate("/Home/DispatchEntryList");
                        dispatchvalue({ type: "SignPerson", value: "" });
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Waiting For Dispatch</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {mainAccess.includes("H") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu8")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <FontAwesomeIcon
                  icon={faSackDollar}
                  className="inventory_sidebar_icon"
                />
                {isSidebarOpen && <span className="icon-name">Petty Cash</span>}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu8" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>
              {isSidebarOpen && openSubMenu === "subsidebarmenu8" && (
                <div className="subSidebarmenuhover">
                  {subAccess.includes("H8-1") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Expenses-Master");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Expense Master</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("H8-2") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Cash-Expenses");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Cash Expenses </span>
                      )}
                    </div>
                  )}
                  {/* {subAccess.includes("DigitalExpenses") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Digital-Expenses");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Digital Expenses </span>
                      )}
                    </div>
                  )} */}
                  {subAccess.includes("H8-3") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Cash-Expenses-Report");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Expenses Report</span>
                      )}
                    </div>
                  )}

                  {subAccess.includes("H8-4") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Day-Closing");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Day Closing</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("H8-5") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Day-Book-Report");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Day Closing Report</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("H8-6") && (
                    <div
                      onClick={() => {
                        navigate("/Home/DoctorsPayout");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Doctors Payout</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("H8-7") && (
                    <div
                      onClick={() => {
                        navigate("/Home/DoctorsPayoutReport");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Doctors Payout Report</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("H8-8") && (
                    <div
                      onClick={() => {
                        navigate("/Home/DueBillPayout");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Due Payout </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("H8-9") && (
                    <div
                      onClick={() => {
                        navigate("/Home/DuereferBillPayout");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Client Payout</span>
                      )}
                    </div>
                  )}

                    
                </div>
              )}
            </div>
          )}


          {mainAccess.includes("I") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu18")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <FontAwesomeIcon
                  icon={faSuitcaseMedical}
                  className="inventory_sidebar_icon"
                />
                {isSidebarOpen && (
                  <span className="icon-name">Inventory Master</span>
                )}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu18" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>
              {isSidebarOpen && openSubMenu === "subsidebarmenu18" && (
                <div className="subSidebarmenuhover">
                  {subAccess.includes("I9-1") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Supplier_List");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Supplier Master </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("I9-2") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Productitemmaster");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Product Type Master</span>
                      )}
                    </div>
                  )}

                  {subAccess.includes("I9-3") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Pharmacy_MasterList");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Product Master</span>
                      )}
                    </div>
                  )}

                  {subAccess.includes("I9-4") && (
                    <div
                      onClick={() => {
                        navigate("/Home/General_MasterList");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">General Master</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {mainAccess.includes("J") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu16')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faStore} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Inventory</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu16' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu16' && <div className="subSidebarmenuhover">
              {subAccess.includes("J10-1") && <div onClick={(() => { navigate("/Home/Quick_Stock_Recieve") })}> {isSidebarOpen && <span className="icon-name" >Quick Stock Recieve</span>}</div>}
              {subAccess.includes("J10-2") && <div onClick={(() => { navigate("/Home/PurchaseRecieceListLocal") })}> {isSidebarOpen && <span className="icon-name" >GRN List</span>}</div>}
              {subAccess.includes("J10-3") && <div onClick={(() => { navigate("/Home/Supplier-Stock-Manager") })}> {isSidebarOpen && <span className="icon-name" >Supplier pay </span>}</div>}
              {subAccess.includes("J10-4") && <div onClick={(() => { navigate("/Home/CentralStock_List") })}> {isSidebarOpen && <span className="icon-name" >All Dept Stock List </span>}</div>}
            </div>}
          </div>}

          {mainAccess.includes("X") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu15')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faLayerGroup} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Indent Inventory</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu15' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu15' && <div className="subSidebarmenuhover">
              {subAccess.includes("X11-1") && <div onClick={(() => { navigate("/Home/Indent_Raise_list") })}> {isSidebarOpen && <span className="icon-name" >Indent Raise </span>}</div>}
              {subAccess.includes("X11-2") && <div onClick={(() => { navigate("/Home/Indent_Issue_list") })}> {isSidebarOpen && <span className="icon-name" >Indent Issue </span>}</div>}
              {subAccess.includes("X11-3") && <div onClick={(() => { navigate("/Home/Indent_Recieve_list") })}> {isSidebarOpen && <span className="icon-name" >Indent Recieve </span>}</div>}
              {subAccess.includes("X11-4") && <div onClick={(() => { navigate("/Home/LocationStock_List") })}> {isSidebarOpen && <span className="icon-name" >Department Stock List </span>}</div>}

            </div>}
          </div>}

          {mainAccess.includes("L") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu20")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <FontAwesomeIcon
                  icon={faRegistered}
                  className="inventory_sidebar_icon"
                />
                {isSidebarOpen && <span className="icon-name">Reports</span>}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu20" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>
              {isSidebarOpen && openSubMenu === "subsidebarmenu20" && (
                <div className="subSidebarmenuhover">
                  {subAccess.includes("L12-1") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Supplier_Pay_List");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Supplier Pay List </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("L12-2") && (
                    <div
                      onClick={() => {
                        navigate("/Home/PurchaseRegister");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Purchase Register </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("L12-3") && (
                    <div
                      onClick={() => {
                        navigate("/Home/SalesRegister");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Sales Register </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("L12-4") && (
                    <div
                      onClick={() => {
                        navigate("/Home/RevenueByDepartment");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Revenue By Department</span>
                      )}
                    </div>
                  )}

                  {subAccess.includes("L12-5") && (
                    <div
                      onClick={() => {
                        navigate("/Home/tatreport");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">TAT Report</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("L12-6") && (
                    <div
                      onClick={() => {
                        navigate("/Home/ReferingDoctorReport");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Refering Doctor List</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("L12-7") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Userwisebillreport");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">User Wise Collection</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("L12-8") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Audittrailreport");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Audit Trail</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("L12-9") && (
                    <div
                      onClick={() => {
                        navigate("/Home/ReferDocreviewreport");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Review Report</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("L12-10") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Refering-Hospital-Report");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">
                          Refering Hospital Report
                        </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("L12-11") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Finance-report");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">
                          Finance report
                        </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("L12-12") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Salesreport");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">
                          Sales report
                        </span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}





          {mainAccess.includes("M") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu10")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <FontAwesomeIcon
                  icon={faPenNib}
                  className="inventory_sidebar_icon"
                />
                {isSidebarOpen && (
                  <span className="icon-name">HR Management</span>
                )}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu10" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>
              {isSidebarOpen && openSubMenu === "subsidebarmenu10" && (
                <div className="subSidebarmenuhover">
                  {/* {subAccess.includes("HRD") && <div onClick={(() => { navigate("/Home/Hr-DashBoard") })}>{isSidebarOpen && <span className="icon-name" >HR Dashboard</span>}</div>} */}
                  {subAccess.includes("M13-1") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Employee-Register");
                        dispatchvalue({ type: "foremployeeedit", value: [] });
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Employee Register</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("M13-2") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Employee-List");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Employee List</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("M13-3") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Employee-Attendance");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Attendance</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("M13-4") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Employee-PayRoll");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Pay Roll </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("M13-5") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Duty-Management");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Duty Management</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("M13-6") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Duty-Roster-Master");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Duty Roster Master</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("M13-7") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Leave-Management-Navigation");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Leave Management </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("M13-8") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Advance-Management-Navigation");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Advance Management </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("M13-9") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Performance-Appraisal-Navigation");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">
                          Performance Management{" "}
                        </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("M13-10") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Employee-Reports");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Employee Report </span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {mainAccess.includes("N") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu12")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <FontAwesomeIcon
                  icon={faUserTag}
                  className="inventory_sidebar_icon"
                />
                {isSidebarOpen && (
                  <span className="icon-name">Employee Request</span>
                )}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu12" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>
              {isSidebarOpen && openSubMenu === "subsidebarmenu12" && (
                <div className="subSidebarmenuhover">
                  {subAccess.includes("N14-1") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Navigation-leave");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Leave Management</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("N14-2") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Navigation-Advance");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Advance Management</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("N14-3") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Shift-Details");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Shift Management</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("N14-4") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Employee-PaySlip-View");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Pay Slip Download</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {mainAccess.includes("O") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu13")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <FontAwesomeIcon
                  icon={faChartBar}
                  className="inventory_sidebar_icon"
                />
                {isSidebarOpen && <span className="icon-name">Masters</span>}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu13" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>
              {isSidebarOpen && openSubMenu === "subsidebarmenu13" && (
                <div className="subSidebarmenuhover">
                  {subAccess.includes("O15-1") && (
                    <div
                      onClick={() => {
                        navigate("/Home/DepartmentsMasters");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Department Management</span>
                      )}
                    </div>
                  )}

                  {subAccess.includes("O15-2") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Units");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Units Management</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("O15-3") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Container");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Container Management</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("O15-4") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Specimen");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Specimen Management</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("O15-5") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Methods");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Methods Management</span>
                      )}
                    </div>
                  )}

                  {subAccess.includes("O15-6") && (
                    <div
                      onClick={() => {
                        navigate("/Home/OrganismMaster");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Organism Masters</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("O15-7") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Antibioticmaster");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Antibiotic Masters</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("O15-8") && (
                    <div
                      onClick={() => {
                        navigate("/Home/EquipmentsMaster");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Equipments Master</span>
                      )}
                    </div>
                  )}
                  {/* {subAccess.includes("O15-9") && <div onClick={(() => { navigate("/Home/ExternalLabmaster") })}>{isSidebarOpen && <span className="icon-name" >ExternalLab Master</span>}</div>} */}
                  {subAccess.includes("O15-9") && (
                    <div
                      onClick={() => {
                        navigate("/Home/ExternalLabmasterList");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">External Lab Master</span>
                      )}
                    </div>
                  )}
                  {/* {subAccess.includes("O15-11") && (<div onClick={() => { dispatchvalue({ type: 'TestMaster', value: '' }); navigate("/Home/TestMastersNavigation"); }}>
                {isSidebarOpen && <span className="icon-name">Test Masters </span>}</div>)} */}

                  {subAccess.includes("O15-10") && (
                    <div
                      onClick={() => {
                        navigate("/Home/TestmasterList");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Test Masters</span>
                      )}
                    </div>
                  )}
                  {/* {subAccess.includes("O15-13") && <div onClick={(() => { navigate("/Home/GroupMaster"); dispatchvalue({ type: 'GroupMaster', value: [] }); })}>{isSidebarOpen && <span className="icon-name" >Group Master</span>}</div>} */}
                  {subAccess.includes("O15-11") && (
                    <div
                      onClick={() => {
                        navigate("/Home/GroupMasterList");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Group Master</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("O15-12") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Referdoctormaster");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Refer Doctor </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("O15-13") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Ratecardlims");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Ratecard Master</span>
                      )}
                    </div>
                  )}
                  {/* {subAccess.includes("O15-17") && <div onClick={(() => { navigate("/Home/OfferPackage") })}>{isSidebarOpen && <span className="icon-name" >Offer Package</span>}</div>} */}
                  {subAccess.includes("O15-14") && (
                    <div
                      onClick={() => {
                        navigate("/Home/OfferPackageList");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Offer Package </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("O15-15") && (
                    <div
                      onClick={() => {
                        navigate("/Home/FileUploadForm");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Document Upload</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {mainAccess.includes("P") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu30")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <FontAwesomeIcon
                  icon={faChartBar}
                  className="inventory_sidebar_icon"
                />
                {isSidebarOpen && (
                  <span className="icon-name">User Control</span>
                )}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu30" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>
              {isSidebarOpen && openSubMenu === "subsidebarmenu30" && (
                <div className="subSidebarmenuhover">
                  {subAccess.includes("P16-1") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Role-Management");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Role Management </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("P16-2") && (
                    <div
                      onClick={() => {
                        navigate("/Home/EmployeeQue-List");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Employee Queue List</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("P16-3") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Register-User");
                        dispatchvalue({
                          type: "foredituserregisteremployeedata",
                          value: [],
                        });
                        dispatchvalue({
                          type: "foruserregisteremployeedata",
                          value: [],
                        });
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name"> User Register</span>
                      )}
                    </div>
                  )}

                  {subAccess.includes("P16-4") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Account Settings");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Account Settings </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("P16-5") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Clinic Details");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Lab Details </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("P16-6") && (
                    <div
                      onClick={() => {
                        navigate("/Home/User-List");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">User List </span>
                      )}
                    </div>
                  )}
                  {/* {subAccess.includes("P16-7") && <div onClick={(() => { navigate("/Home/Employee-LeaveManage") })}> {isSidebarOpen && <span className="icon-name" >Leave Management </span>}</div>}
              {subAccess.includes("P16-8") && <div onClick={(() => { navigate("/Home/Employee-AdvanceManagement") })}> {isSidebarOpen && <span className="icon-name" >Advance Management </span>}</div>} */}
                </div>
              )}
            </div>
          )}

          {mainAccess.includes("R") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu14")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <FontAwesomeIcon
                  icon={faBuildingColumns}
                  className="inventory_sidebar_icon"
                />
                {isSidebarOpen && <span className="icon-name">Accounts</span>}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu14" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>

              {isSidebarOpen && openSubMenu === "subsidebarmenu14" && (
                <div className="subSidebarmenuhover">
                  {subAccess.includes("R17-1") && (
                    <div
                      onClick={() => {
                        navigate("/Home/AccountsMasterList");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Accounts Master</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("R17-2") && (
                    <div
                      onClick={() => {
                        navigate("/Home/TransactionTypeMaster");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">
                          Transaction Type Master
                        </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("R17-3") && (
                    <div
                      onClick={() => {
                        navigate("/Home/ContraVoucherListFinance");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Contra Voucher</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("R17-4") && (
                    <div
                      onClick={() => {
                        navigate("/Home/PaymentVoucherFinanceList");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Payment Voucher</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("R17-5") && (
                    <div
                      onClick={() => {
                        navigate("/Home/ReceiptVoucherFinanceList");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Receipt Voucher</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("R17-6") && (
                    <div
                      onClick={() => {
                        navigate("/Home/JournalentryFinanceList");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Journal Voucher</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("R17-7") && (
                    <div
                      onClick={() => {
                        navigate("/Home/CashbookFinance");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Cash book</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("R17-8") && (
                    <div
                      onClick={() => {
                        navigate("/Home/BankbookFinance");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Bank book</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("R17-9") && (
                    <div
                      onClick={() => {
                        navigate("/Home/DayBookFinance");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Day book</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("R17-10") && (
                    <div
                      onClick={() => {
                        navigate("/Home/TrialBalanceFinance");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Trial Balance</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("R17-11") && (
                    <div
                      onClick={() => {
                        navigate("/Home/BalanceSheetFinance");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Balance Sheet</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("R17-12") && (
                    <div
                      onClick={() => {
                        navigate("/Home/ProfitandLossFinance");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Profit and Loss</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          <div
            className={
              isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
            }
            onClick={handleLogoutClick}
            id="font_111"
          >
            <FontAwesomeIcon
              icon={faRightFromBracket}
              className="inventory_sidebar_icon"
            />
            {isSidebarOpen && <span className="icon-name">Logout</span>}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Sidebar;
