import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import { FaEdit } from "react-icons/fa";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

const theme = createTheme({

  components: {
      MuiDataGrid: {
          styleOverrides: {
              columnHeader: {
                  backgroundColor: "var(--ProjectColor)",
                  textAlign: 'Center',
              },
              root: {
                  "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
                      textAlign: 'center',
                      display: 'flex !important',
                      justifyContent: 'center !important'
                  },
                  "& .MuiDataGrid-window": {
                      overflow: "hidden !important",
                  },
              },
              cell: {
                  borderTop: "0px !important",
                  borderBottom: "1px solid  var(--ProjectColor) !important",
                  display: 'flex',
                  justifyContent: 'center'
              },
          },
      },
  },
});
const PatientListforLab = () => {
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const dispatchvalue = useDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [summa, setsumma] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = useState(0);

  const pageSize = 10;
  const totalPages = Math.ceil(filteredRows.length / pageSize);
  const showdown = filteredRows.length;
  useEffect(() => {
    axios
      .get(`${urllink}Billing/getallpatient?location=${userRecord?.location}`)
      .then((response) => {
        console.log(response);

        const data = response.data.map((row, index) => ({
          id: index + 1,
          ...row,
        }));
        setsumma(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [userRecord, urllink]);



  useEffect(() => {
    const lowerCaseNameQuery = searchQuery.toLowerCase();
    const lowerCasePhoneQuery = searchQuery1.toLowerCase();
    const filteredData = summa.filter((row) => {
      const lowerCasePatientName = row.Patient_Name
        ? row.Patient_Name.toLowerCase()
        : "";
      const lowerCasePhone = row.Phone ? row.Phone.toLowerCase() : "";
      return (
        lowerCasePatientName.includes(lowerCaseNameQuery) &&
        lowerCasePhone.includes(lowerCasePhoneQuery)
      );
    });
    setFilteredRows(filteredData);
  }, [searchQuery, searchQuery1, summa]);

  const handleSearchChange = (event, type) => {
    const value = event.target.value;
    if (type === "name") {
      setSearchQuery(value);
    } else if (type === "phone") {
      setSearchQuery1(value);
    }
  };

  
  const handleclick = (params) => {
    console.log(params);
    dispatchvalue({ type: "forpatientdatalab", value: params });
    navigate("/Home/PatientProfile");
  };

  const handleclick1 = (params) => {
    console.log(params);
 dispatchvalue({ type: "forpatienteditdatalab", value: params });
    navigate("/Home/PatientsEdit");
  };



  const dynamicColumns = [
    { field: 'id', headerName: 'S No', width: 80 },
    { field: 'Patient_Id', headerName: 'Patient Id', width: 100 },
    { field: 'Patient_Name', headerName: 'Patient Name', width: 150 },
    { field: 'Age', headerName: 'Age', width: 100 },
    { field: 'Gender', headerName: 'Gender', width: 130 },
    { field: 'Phone', headerName: 'Phone', width: 130 },
    // {
    //     field: 'actions',
    //     headerName: 'Actions',
    //     width: 100,
    //     renderCell: (params) => (
    //         <>
    //             <Button className='cell_btn' onClick={() => {handleclick(params.row)}}>
    //                 <VisibilityIcon />
    //             </Button>
    //         </>
    //     ),
    // },
{
  key: "EditActions",
  name: "Edit",
  width: 80,
  renderCell: (params) => (
    <>
      <Button
        onClick={() => handleclick1(params.row)}
        startIcon={<FaEdit />}
        sx={{
          color: "var(--ProjectColor)",
          "&:hover": {
            color: "var(--ProjectColorhover)",
          },
        }}
      ></Button>
    </>
  ),
},
];
  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Patient List</h4>
        </div>
        <br />
        <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">
                Patient Name <span>:</span>
              </label>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => handleSearchChange(e, "name")}
                placeholder="Enter Patient Name"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">
                Phone <span>:</span>
              </label>
              <input
                type="text"
                value={searchQuery1}
                onChange={(e) => handleSearchChange(e, "phone")}
                placeholder="Enter Phone Number"
              />
            </div>
          </div>
        </div>
        <div className='grid_1'>
                    <ThemeProvider theme={theme}>
                        <DataGrid
                            rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
                            columns={dynamicColumns}
                            pageSize={pageSize}
                            pageSizeOptions={[pageSize]}
                            onPageChange={(newPage) => setPage(newPage)}
                            hideFooterPagination
                            hideFooterSelectedRowCount
                            className='data_grid'
                        />
                        {showdown > 0 && filteredRows.length > pageSize && (
                            <div className='grid_foot'>
                                <button
                                    onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                    disabled={page === 0}
                                >
                                    Previous
                                </button>
                                Page {page + 1} of {totalPages}
                                <button
                                    onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))}
                                    disabled={page === totalPages - 1}
                                >
                                    Next
                                </button>
                            </div>
                        )}
                    </ThemeProvider>
                    {filteredRows.length === 0 && (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
                </div>
      </div>
    </>
  );
};

export default PatientListforLab;
