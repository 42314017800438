import React, { useCallback, useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import '../src/App.css';
import LoginPage from './LoginPage/LoginPage';
import HomePage from './HomePage/HomePage';
import RoleManagement from './UserControls/RoleManagement';
import UserRegister from './UserControls/UserRegister';
import UserList from './UserControls/UserList';
import PatientQueueList from './TreatmentRecords/PatientQueueList';
import RequestList from './AppointmentManagement/RequestList';
import RegisterList from './AppointmentManagement/RegisterList';
import Register from './AppointmentManagement/Register';
import Profile from './Profile/Profile';
import PasswordChange from './PasswordChange/PasswordChange';
import AccountSettings from './UserControls/Account Settings';
import ClinicDetails from './UserControls/ClinicDetails';
import Navigation from './TreatmentRecords/Navigation';
// import BillingHistory from './Billing/BillingHistory';
import ClinicMetrics from './ClinicMetrics/ClinicMetrics';
import PatientProfile from './PatientManagement/PatientProfile';
import AppoinmentCalendar from './AppointmentManagement/AppointmentCalendar';
// import TreatmentPro from './UserControls/TreatmentPro';
import Preview from './TreatmentRecords/Preview';
// import SlotManagement from './UserControls/SlotManagement';
import PatientList from './TreatmentRecords/PatientList';
import PatientQueueList2 from './Therophist/PatientQueueList2';
import Navigation2 from './Therophist/Navigation2';
import Vital2 from './Therophist/Vital2';
import RegistrationForm from './RegistrationForm/Registration';
import PatientList2 from './Therophist/PatientList2';
import NewProcedure from './Therophist/NewProcedure';
// import CounselorQueueList from './Counselor/CounselorQueueList';
// import CounselorProcedure from './Counselor/CounselorProcedure';
// import CounselorFullList from './Counselor/CounselorFullList';
import ExpensesMaster from './Pettycash/ExpensesMaster';
import CashExpenses from './Pettycash/CashExpenses';
import CashExpenseReport from './Pettycash/CashExpenseReport';
// import HandOverSummary from './Pettycash/HandOverSummary';
import DayBookReport from './Pettycash/DayBookReport';
import DayClosing from './Pettycash/DayClosing';
import DueHistoryList from './Billing/Duehistory/DueHistoryList';
// import GeneralBillingQueList from './Billing/OverallBilling/GeneralBillingQueList';
// import GeneralBilling from './Billing/OverallBilling/GeneralBilling';
// import BillCancelation from './Billing/BillCancelation';
import Concern from './AppointmentManagement/ConcernForms/Concern';
import Concernlist from './AppointmentManagement/ConcernForms/Concernlist';
import RateCardMaster from './UserControls/RateCardMaster/RateCardMaster';
import LabQueueList from './AppointmentManagement/LabQueueList';
import LabReportFN from './AppointmentManagement/LabReportFN';
// import PatientQueueList3 from './Nurse/PatientQueueList3';
// import Navigation3 from './Nurse/Navigation3';
import OverallDayreport from './Pettycash/OverallDayreport';
// import Walkinbilling from './Pharmacy/Walkinbilling';
// import PharmacyBillingLIst from './Pharmacy/PharmacyBillingLIst';
// import PharmacyBilling from './Pharmacy/PharmacyBilling';
import DeuHistory from './Billing/Duehistory/DeuHistory';
// import PharmacyBillcancelation from './Pharmacy/Pharmacy_Billcancelation';
import EmployeeRegister from './HR Management/EmployeeRegister';
import EmployeeList from './HR Management/EmployeeList';
import EmployeeProfile from './HR Management/EmployeeProfile';
import EmployeeAttendance from './HR Management/Attendance';
import EmployeeCalendar from './HR Management/EmployeeCalendar';
import EmployeeQueList from './UserControls/EmployeeQueList';
import VisitingDoctorBilling from './UserControls/VisitDoctorBilling';
import LeaveMangement from './HR Management/LeaveMangement';
import PayRoll from './HR Management/PayRoll';
import PaySlip from './HR Management/PaySlip';
// import VisitingDoctor from './VisitingDoctor/VisitingDoctor';
// import PatientVisitingDoctor from './VisitingDoctor/PatientVisitingDoctor';
import PerformanceAppraisal from './HR Management/Performance';
import PerformanceManagement from './HR Management/PerformanceManagement';
import AddvaceApproval from './HR Management/AdvanceApproval';
import AddvaceManagement from './HR Management/AdvanceManagement';
import LeaveNavigation from './EmployeeRequest/LeaveNavigation';
import AdvanceNavigation from './EmployeeRequest/AdvanceNavigation';
import LeaveApproval from './HR Management/LeaveApproval';
// import Accounts from './Accounts/Accounts';
import PaymentVoucher from './Accounts/PaymentVoucher';
// import AccountPayable from './Accounts/AccountPayable';
// import PatientView from './patientView';
// import Patientview from './PatientView'
import NotFound from './404Page';
import PrivacyPolicy from './Footer/PrivacyPolicy';
import TermsOfUse from './Footer/TermsOfUse';
import { useDispatch, useSelector } from 'react-redux';
import PatientListupdate from './AppointmentManagement/patientlistupdate';
// import Expenses from './Pettycash/Expenses';
import DigitalExpenses from './Pettycash/DigitalExpense';
import PurchaseRegister from './Finance/PurchaseRegister';
import AccountGroupMaster from './Finance/AccountGroupMaster';
import SalesRegister from './Finance/SalesRegister';
import Journalentry from './Finance/Journalentry';
import LedgerList from './Finance/LedgerList';
import BalanceSheet from './Finance/BalanceSheet'
import AccountGroupList from './Finance/AccountGroupList'
import Accounts from './Finance/Accounts'



import PatientView from './PatientView';
import VisitingDoctorBillingList from './UserControls/VisitDoctorBillingList';
import Followuplist from './AppointmentManagement/Followuplist';
import DutyManagement from './HR Management/DutyManagement';


import RoomMaster from './IpManagement/RoomCreate/RoomMaster';
import RoomMasterCreate from './IpManagement/RoomCreate/RoomMasterCreate';
import NurseQueList from './IpManagement/IPNurseflow/NurseQueList';
import IpNurseWorkbench from './IpManagement/IPNurseflow/IpNurseWorkbench';
import IpDoctorQueList from './IpManagement/IpDoctorflow/IpDoctorQueList';
import IpDoctorWorkbench from './IpManagement/IpDoctorflow/IpDoctorWorkbench';
import IpRegistrationlist from './IpManagement/IPRegistration/IpRegistrationlist';
import RoomsAvail from './IpManagement/RoomCreate/RoomsAvail';
import IpRegistration1 from './IpManagement/IPRegistration/IpRegistration1';
import TheatreBooking from './IpManagement/Operationtheatre/TheatreBooking';
import IPrateCardMaster from './UserControls/Ipratecard';


import GroupMaster from './Masters/GroupMaster';
import Units from './Masters/Unit';
import Container from './Masters/Container';
import Specimen from './Masters/Specimen';
import Methods from './Masters/Methods';
import PatientGroupName from './Masters/PatientGroupName';
import OrganismMaster from './Masters/OrganismMaster';
import GroupMasterList from './Masters/GroupMasterList';
import ExternalLab from './Masters/ExternalLab';
import ExternalLabList from './Masters/ExternalLabList';
import Equipments from './Masters/Equipments';
import Department from './Masters/Department';
import AssetType from './Masters/AssetType';
import AntibioticGroup from './Masters/AntibioticGroup';
import AntibioticMaster from './Masters/AntibioticMaster';
import TestMasterNavigation from './Masters/TestMasterNavigation';
import Billing from './Receptionist/Billing';
// import Billcancelation from './Receptionist/BillCancelation';
// import PatientData from './Receptionist/PatientData';
import PatientListforLab from './Receptionist/PatientList';
import PatientRequestforLab from './Receptionist/PatientRequest';
import MasterList from './Masters/MasterList';
import PaymentHistory from './Receptionist/PaymentHistory';
import Navigationple from './Phelobotomist/Navigationple';
import SampleList from './Phelobotomist/SampleList';
import TransactionHistory from './Phelobotomist/TransactionHistory';
import CaptureSample from './Phelobotomist/CaptureSample';
import Analyzereport from './LabTechnician/AnalyzeReportEntry';
import NavigationLab from './LabTechnician/NavigationLab';
import ValidatedQueue from './LabTechnician/ValidatedQueue';
import Verifyreport from './Sr.LabTechnician/VerifyLabEntry';
import Navigationseior from './Sr.LabTechnician/Navigationsenior';
import Approvereport from './Pathologist/Authorize-Approval';
import Navigationpath from './Pathologist/Navigationpath';
import PathValidatedQueue from './Pathologist/PathValidatedQueue';
import SrValidatedQueue from './Sr.LabTechnician/SrValidatedQueue';
import DispatchList from './Dispatch/DispatchTransactionHistory';
import Reports from './Report/Reports';
import ReferDocs from './Masters/ReferDocs';
import DoctorsPayout from './Pettycash/DoctorsPayout';
import Doctorspayoutlist from './Pettycash/Doctorspayoutlist';
import Cultruretest from './Masters/Cultruretest';
import Billcancelation from './Receptionist/BillCancelationLab';
import RadiologyMaster from './Radiology/RadiologyMasters/RadiologyMaster';
import FranchiseMaster from './Masters/FranchiseMaster';
import RatecardLims from './Masters/RatecardLims';
import ConsultantcyMaster from './HR Management/ConsultantcyMaster';
import JobRequirement from './HR Management/JobRequiremet';
import RequestToHR from './HR Management/RequestToHR';
import MailFromConsultancy from './HR Management/MailFromConsultancy';
import ShortlistedResumelist from './HR Management/ShortlistedResumelist';
import Reportspreview from './Sr.LabTechnician/Reportspreview';
import PathologyEdit from './Pathologist/PathologyEdit';
import { BarcodePrint } from './Receptionist/BarcodePrint';

import Outsourceratecard from './Masters/Outsourceratecard';
import DueBillinglab from './Pettycash/DueBillinglab';
import TAT from './Masters/TAT';


import ReferDocreport from './Report/ReferDocreport';
import Userwisebillreport from './Report/UserWiseBillReport';
import AuditTrial from './Report/AuditTrial';
import ReferDocreviewreport from './Report/ReferingDocReview';
import RevenueByDepartment from './Report/RevenueByDepartment';
import OfferPackage from './Masters/OfferPackage';
import OfferPackageList from './Masters/OfferPackageList';
import FileUploadForm from './Masters/wordfile';
import DoctorsList from './HR Management/DoctorsList';
import LeaveManagementNavigation from './HR Management/LeaveManagementNavigation';
import AdvanceManagementNavigation from './HR Management/AdvanceManagementNavigation';
import AppraisalNavigation from './HR Management/AppraisalNavigation';
import PayslipView from './EmployeeRequest/PayslipView';
import ShiftDetails from './EmployeeRequest/ShiftDetails';
import AccountsMaster from './FinanceModule/AccountsMaster';
import AccountsMasterList from './FinanceModule/AccountsMasterList';
import ContraVoucher from './Finance/ContraVoucher';
import ContraVoucherFinance from './FinanceModule/ContraVoucherFinance';
import PaymentVoucherFinance from './FinanceModule/PaymentVoucherFinance';
import PaymentVoucherFinanceList from './FinanceModule/PaymentVoucherFinanceList';
import ReceiptVoucherFinanceList from './FinanceModule/ReceiptVoucherFinanceList';
import ReceiptVoucherFinance from './FinanceModule/ReceiptVoucherFinance';
import JournalentryFinance from './FinanceModule/JournalentryFinance';
import JournalentryFinanceList from './FinanceModule/JournalentryFinanceList';
import CashbookFinance from './FinanceModule/CashbookFinance';
import BankbookFinance from './FinanceModule/BankbookFinance';
import DayBookFinance from './FinanceModule/DayBookFinance';
import TrialBalanceFinance from './FinanceModule/TrialBalanceFinance';
import BalanceSheetFinance from './FinanceModule/BalanceSheetFinance';
import ProfitandLossFinance from './FinanceModule/ProfitandLossFinance';
import TransactionTypeMaster from './FinanceModule/TransactionTypeMaster';
import ReferingHospitalReport from './Report/ReferingHospitalReport';
import DutyRoster from './HR Management/DutyRoster';
import EmployeeReport from './HR Management/EmployeeReport';
import RequestListLab from './Receptionist/RequestListLab';






import SupplierStock from './Stock/SupplierPay/SupplierStock';
import SupplierDetails from './Stock/SupplierPay/SupplierDetails';
import SupplierList from './Stock/SupplierMaster/SupplierList';
import SupplierMaster from './Stock/SupplierMaster/SupplierMaster';
import ProductList from './Stock/ProductMaster/ProductList';
import ProductMaster from './Stock/ProductMaster/ProductMaster';

import IndentRaiseMaster from './Stock/IndentRaise/IndentRaiseMaster';
import IndentRaiseList from './Stock/IndentRaise/IndentRaiseList';
import IndentRecieveList from './Stock/IndentRecieve/IndentRecieveList';
import IndentRecieveMaster from './Stock/IndentRecieve/IndentRecieveMaster';
import IndentIssueMaster from './Stock/IndentIssue/IndentIssueMaster';
import IndentIssueList from './Stock/IndentIssue/IndentIssueList';
import CentralStockList from './Stock/CentralStock/CentralStockList';
import LocationStock from './Stock/CentralStock/LocationStock';
import QuickStockMasterLoc from './Stock/QuickStockRecieve/QuickStockMasterLoc';
import SuplierPaidlist from './Stock/SupplierPay/SuplierPaidlist';

import PurchaseReturnLocMaster from './Stock/PurchaseReturn/PurchaseReturnLocMaster';
import PharmachyMasterList from './Stock/PharmachyMaster/PharmachyMasterList';
import PharmachyMaster from './Stock/PharmachyMaster/PharmachyMaster';
import GeneralMasterList from './Stock/GeneralMaster/GeneralMasterList';
import GeneralMaster from './Stock/GeneralMaster/GeneralMaster';
import ProductItemMaster from './Stock/PharmachyMaster/ProductItemMaster';
import PurchaseReturnLocMasterlist from './Stock/PurchaseReturn/PurchaseReturnLocMasterlist';
import PurchaseRecieceListLocal from './Stock/QuickStockRecieve/PurchaseRecieveListLocal';
import DuepayHistoryList from './Pettycash/Duepayhospitallist';
import Doctorpayment from './Pettycash/Doctorpayment';
import NewMasterList from './Masters/NewMasterList';
import FinanceReport from './Report/FinanceReport';
import LabpatientsEdit from './Receptionist/LabpatientsEdit';
import Salesreporthospital from './Report/Salesreporthospital';



const App = () => {


  const [userRecord, setUserRecord] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const dispatchvalue = useDispatch();

  const isModalOpen = useSelector(state => state.userRecord?.isModalOpen)

  const getuserrecord = useCallback(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      const decodedToken = (token) => {
        const payloadBase64 = token.split('.')[1];
        const decodedPayload = atob(payloadBase64);
        return JSON.parse(decodedPayload);
      };
      const decodedTokenData = decodedToken(storedToken);
      setUserRecord(decodedTokenData)
      dispatchvalue({ type: 'UserData', value: decodedTokenData })
    } else {
      if (location.pathname !== '/') {
        navigate('/')
      }
    }
  }, [navigate, location.pathname])



  useEffect(() => {
    const loc = location.pathname
    if (loc === '/') {
      localStorage.clear()
    }
    getuserrecord();
  }, [location, location.pathname, navigate, getuserrecord])

  // console.log(userRecord)


  useEffect(() => {
    const sessionTimeout = 30 * 60 * 1000; // 3 minutes in milliseconds
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        localStorage.clear();
        navigate('/');
      }, sessionTimeout);
    };

    // Add event listeners to reset the timeout on user activity
    window.addEventListener('mousemove', resetTimeout);
    window.addEventListener('keydown', resetTimeout);

    resetTimeout(); // Initial setup

    // Clean up event listeners on unmount
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', resetTimeout);
      window.removeEventListener('keydown', resetTimeout);
    };
  }, [navigate]);


  useEffect(() => {
    const inputs = document.querySelectorAll('input');
    inputs.forEach(input => {
      input.setAttribute('autocomplete', 'off');
      input.addEventListener('copy', (event) => {
        event.preventDefault();
      });
    });
  }, []);

  // Function to open the modal
  // const openModal = () => {
  //   dispatchvalue({ type: 'isModalOpen', value: true })
  // };

  // // Function to close the modal
  // const closeModal = () => {
  //   dispatchvalue({ type: 'isModalOpen', value: false })
  // };

  return (
    <>
      <Routes>
        <Route path='/Home' element={<HomePage />}>
          {/* Header */}
          <Route path="Profile" element={<Profile />} />
          <Route path="PasswordChange" element={<PasswordChange />} />

          {/* Patient Management */}
          <Route path="PatientProfile" element={<PatientProfile />} />


          {/* Dashboard */}
          <Route path="ClinicMetrics" element={<ClinicMetrics />} />


          {/* Front Office */}
          <Route path="Register-Patient2" element={<RegistrationForm />} />
          <Route path="Appoinment-RequestList" element={<RequestList />} />
          <Route path="Register" element={<Register />} />
          <Route path="Appoinment-RegisterList" element={<RegisterList />} />
          <Route path="Appointment-Calendar" element={<AppoinmentCalendar />} />
          <Route path="Register_concern_List" element={<Concernlist />} />
          <Route path="Register_concern_Form" element={<Concern />} />
          <Route path="Lab-QueueList" element={<LabQueueList />} />
          <Route path="Lab-ReportFN" element={<LabReportFN />} />
          <Route path="Patient-List-update" element={<PatientListupdate />} />
          <Route path="Followuplist" element={<Followuplist />} />



          {/* Nurse */}
          {/* <Route path="Treament-QueueList3" element={<PatientQueueList3 />} /> */}
          {/* <Route path="Navigation3" element={<Navigation3 />} /> */}


          {/* ip management*/}
          {/* IP Management */}
          <Route path="IP-Registration1" element={<IpRegistration1 />} />
          <Route path="OT-Management" element={<TheatreBooking />} />
          <Route path="Room_Master" element={<RoomMaster />} />
          <Route path="Room-Management" element={<RoomsAvail />} />
          <Route path="IpRegistrationlist" element={<IpRegistrationlist />} />
          <Route path="IpNurseQuelist" element={<NurseQueList />} />
          <Route path="IpNurseWorkBench" element={<IpNurseWorkbench />} />
          <Route path="IpDocQuelist" element={<IpDoctorQueList />} />
          <Route path="IpDocWorkBench" element={<IpDoctorWorkbench />} />
          <Route path="IPratecard" element={<IPrateCardMaster />} />
          <Route path="Room_Create_Master" element={<RoomMasterCreate />} />

         {/* {stock} */}
         
            {/* supplier Master */}

            <Route path='Supplier_List' element={<SupplierList  />} />
          <Route path='Supplier_Master' element={<SupplierMaster  />} />
          {/*  Product Master */}
          <Route path='Product_List' element={<ProductList  />} />
          <Route path='Product_Master' element={<ProductMaster  />} />


      {/* Pahrmachy Master */}
          <Route path='Pharmacy_MasterList' element={<PharmachyMasterList  />} />
          <Route path='Pharmacy_Master' element={<PharmachyMaster  />} />

          {/* General Master */}

          <Route path='General_MasterList' element={<GeneralMasterList />} />
          <Route path='General_Master' element={<GeneralMaster  />} />
          <Route path='Productitemmaster' element={<ProductItemMaster/>}/>





         
          {/* purchase Indent Raise master */}
          <Route path='Indent_Raise_list' element={<IndentRaiseList  />} />
          <Route path='Indent_Raise_Master' element={<IndentRaiseMaster  />} />
          {/* purchase Indent Recieve master */}
          <Route path='Indent_Recieve_list' element={<IndentRecieveList  />} />
          <Route path='Indent_Recieve_Master' element={<IndentRecieveMaster  />} />
          {/* purchase Indent Issue master */}
          <Route path='Indent_Issue_list' element={<IndentIssueList  />} />
          <Route path='Indent_Issue_Master' element={<IndentIssueMaster  />} />



          

          {/* expiry products */}
          <Route path='PurchaseReturn_Loc_Master' element={<PurchaseReturnLocMaster />} />

          <Route path='PurchaseReturnLocMasterlist' element={<PurchaseReturnLocMasterlist />} />



          {/* stock list */}
          <Route path='CentralStock_List' element={<CentralStockList />} />
          <Route path='LocationStock_List' element={<LocationStock  />} />
          {/* QuickStock Location */}
          <Route path='Quick_Stock_Recieve' element={<QuickStockMasterLoc  />} />

          <Route path='PurchaseRecieceListLocal' element={<PurchaseRecieceListLocal/>} />




          {/* Supplier Pay */}
          <Route path='Supplier-Stock-Manager' element={<SupplierStock  />} />
          <Route path='Supplier-Stock-Details' element={<SupplierDetails  />} />
          <Route path='Supplier_Pay_List' element={<SuplierPaidlist />} />


          {/*------------------------------ Finance -------------------------*/}
          <Route path='PurchaseRegister' element={<PurchaseRegister />} />
          <Route path='SalesRegister' element={<SalesRegister />} />
          <Route path='Journalentry' element={<Journalentry />} />
          <Route path='LedgerList' element={<LedgerList />} />
          <Route path="Add_Accounts" element={<Accounts />} />
          <Route path="BalanceSheet" element={<BalanceSheet />} />
          <Route path="AccountGroupList" element={<AccountGroupList />} />
          {/* Accounts */}
          <Route path="Payment-Voucher" element={<PaymentVoucher />} />
          {/* <Route path="/Accounts_payable" element={<AccountPayable/>} /> */}
          <Route path='AccountGroupMaster' element={<AccountGroupMaster />} />
          <Route path='Finance-report' element={<FinanceReport />} />


          {/* Doctor work bench */}
          <Route path="Treament-QueueList" element={<PatientQueueList />} />

          <Route path="Navigation" element={<Navigation />} />
          <Route path="Patient-List" element={<PatientList />} />
          <Route path="PatientsEdit" element={<LabpatientsEdit />} />
          <Route path="vitalForm2" element={<Vital2 />} />   {/*Pending formData*/}

          <Route path="PatientView" element={<PatientView />} />

          <Route path="Preview" element={<Preview />} /> {/*Pending formData*/}

          {/*   <Route path="vitalForm3" element={<Vital3 userRecord={userRecord} />} /> */}




          {/* Theraphist */}
          <Route path="Treament-QueueList2" element={<PatientQueueList2 />} />
          <Route path="Navigation2" element={<Navigation2 />} />
          <Route path="Patient-List2" element={<PatientList2 />} />
          <Route path="Treament-NewProcedure" element={<NewProcedure />} />


          {/* Counselor */}
          {/* <Route path="Treament-CounselorQueueList" element={<CounselorQueueList  />} />
          <Route path="Treament-CounselorProcedure" element={<CounselorProcedure  />} />
          <Route path="Treament-CounselorFullList" element={<CounselorFullList />} /> */}


          {/* Billing */}
          {/* <Route path="Billing-History" element={<BillingHistory />} /> */}
          
          {/* <Route path="Deu-Close" element={<DueHistory />} /> */}
          {/* <Route path="Billing-Invoice" element={<GeneralBillingQueList />} /> */}
          {/* <Route path="Billing-Invoice-All" element={<GeneralBilling />} /> */}

          {/* <Route path="Pharmacy_Billing_List" element={<PharmacyBillingLIst />} /> */}
          {/* <Route path="Pharmacy-Billing" element={<PharmacyBilling />} /> */}
          {/* <Route path="Pharmacy-Walkinbilling" element={<Walkinbilling />} /> */}

          {/* <Route path="Pharmacy_Billcancelation" element={<PharmacyBillcancelation />} /> */}
          <Route path="Bill-Report" element={<OverallDayreport />} />



          {/* Petty Cash */}
          <Route path="Expenses-Master" element={<ExpensesMaster />} />
          <Route path="Cash-Expenses" element={<CashExpenses />} />
          <Route path="Digital-Expenses" element={<DigitalExpenses />} />
          <Route path="Cash-Expenses-Report" element={<CashExpenseReport />} />
          {/* <Route path="Hand-Over-Summary" element={<HandOverSummary />} /> */}
          <Route path="Day-Book-Report" element={<DayBookReport />} />
          <Route path="Day-Closing" element={<DayClosing />} />
          <Route path="DoctorsPayout" element={<DoctorsPayout />} />
          <Route path="DoctorsPayoutReport" element={<Doctorspayoutlist />} />
          <Route path="DueBillPayout" element={<DueBillinglab />} />
          <Route path="DuereferBillPayout" element={<DuepayHistoryList />} />
          <Route path="Duehospitalpayout" element={<Doctorpayment/>}/>


          {/* HR management */}
          <Route path="Employee-Register" element={<EmployeeRegister />} />
          <Route path="Employee-List" element={<EmployeeList />} />
          <Route path="Doctor-List" element={< DoctorsList />} />
          <Route path="Employee-Profile" element={<EmployeeProfile />} />
          <Route path="Employee-Attendance" element={<EmployeeAttendance />} />
          <Route path="Employee-Performance" element={<PerformanceAppraisal />} />
          <Route path="Employee-PerformanceManagement" element={<PerformanceManagement />} />
          <Route path="Employee-AdvanceApproval" element={<AddvaceApproval />} />
          <Route path="Employee-LeaveApproval" element={<LeaveApproval />} />
          <Route path="Employee-LeaveManage" element={<LeaveMangement />} />
          <Route path="Employee-AdvanceManagement" element={<AddvaceManagement />} />
          <Route path="Employee-AttendanceCalendar" element={<EmployeeCalendar />} />
          <Route path="Employee-PayRoll" element={<PayRoll />} />
          <Route path="Employee-PaySlip" element={<PaySlip />} />
          <Route path="Duty-Management" element={<DutyManagement />} />
          <Route path="Consultancy-Typeup-List" element={<ConsultantcyMaster />} />
          <Route path="Job-Requirements" element={<JobRequirement />} />
          <Route path="RequestTo-HrManagementList" element={<RequestToHR />} />
          <Route path="MailFrom-Consultancy" element={<MailFromConsultancy />} />
          <Route path="Shortlisted-Resume-list" element={<ShortlistedResumelist />} />
          <Route path="Leave-Management-Navigation" element={<LeaveManagementNavigation />} />
          <Route path="Advance-Management-Navigation" element={<AdvanceManagementNavigation />} />
          <Route path="Performance-Appraisal-Navigation" element={<AppraisalNavigation />} />
          <Route path="Duty-Roster-Master" element={<DutyRoster />} />
          <Route path="Employee-Reports" element={<EmployeeReport />} />

          
          
          

          {/* Employee Request */}
          <Route path="Navigation-leave" element={<LeaveNavigation />} />
          <Route path="Navigation-Advance" element={<AdvanceNavigation />} />
          <Route path="Employee-PaySlip-View" element={<PayslipView />} />
          <Route path="Shift-Details" element={<ShiftDetails />} />
        
          {/* User Control */}
          <Route path="Role-Management" element={<RoleManagement />} />
          <Route path="Register-User" element={<UserRegister />} />
          <Route path="User-List" element={<UserList />} />
          <Route path="Account Settings" element={<AccountSettings />} />
          <Route path="Clinic Details" element={<ClinicDetails />} />
          {/* <Route path="Doctor Consulation Charges" element={<DoctorCharges closeModal={closeModal} openModal={openModal} isModalOpen={isModalOpen} isSidebarOpen={isSidebarOpen} userRecord={userRecord} />} /> */}
          <Route path="EmployeeQue-List" element={<EmployeeQueList />} />
          {/* <Route path="Employee-LeaveManagement" element={<LeaveMangement userRecord={userRecord} setSelectedRowData={setSelectedRowData} isSidebarOpen={isSidebarOpen} />} /> */}
          <Route path="Doctor Consulation Charges" element={<RateCardMaster />} />
          <Route path="Visit-Doctor-Billing-List" element={<VisitingDoctorBillingList />} />
          <Route path="Visit-Doctor-Billing" element={<VisitingDoctorBilling />} />

          {/* Masters */}
          <Route path="GroupMaster" element={<GroupMaster />} />
          <Route path="GroupMasterList" element={<GroupMasterList />} />
          <Route path="Units" element={<Units />} />
          <Route path="Container" element={<Container />} />
          <Route path="Specimen" element={<Specimen />} />
          <Route path="Methods" element={<Methods />} />
          <Route path="Patient-group" element={<PatientGroupName />} />
          <Route path="OrganismMaster" element={<OrganismMaster />} />
          {/* <Route path="Methods" element={<Methods />} /> */}
          <Route path="ExternalLabmaster" element={<ExternalLab />} />
          <Route path="ExternalLabmasterList" element={<ExternalLabList />} />
          <Route path="EquipmentsMaster" element={<Equipments />} />
          <Route path="DepartmentsMasters" element={<Department />} />
          <Route path="AssetTypemaster" element={<AssetType />} />
          <Route path="AntibioticGroupmaster" element={<AntibioticGroup />} />
          <Route path="Antibioticmaster" element={<AntibioticMaster />} />
          <Route path="TestMastersNavigation" element={<TestMasterNavigation />} />
          <Route path="TestmasterList" element={<NewMasterList />} />
          <Route path="Referdoctormaster" element={<ReferDocs />} />
          <Route path="Culturemaster" element={<Cultruretest />} />
          <Route path="Ratecardlims" element={<RatecardLims />} />
          <Route path="Outsourceratecard" element={<Outsourceratecard />} />
          <Route path="OfferPackage" element={<OfferPackage />} />
          <Route path="OfferPackageList" element={<OfferPackageList />} />
          <Route path="FileUploadForm" element={<FileUploadForm />} />
          <Route path="FileUploadForm" element={<FileUploadForm />} />



          {/* Receptionist */}
          <Route path="ReceptionistBilling" element={<Billing />} />
          {/* <Route path="PatientData" element={<PatientData />} /> */}
          <Route path="Patientlistforlab" element={<PatientListforLab />} />
          <Route path="PatientRequestlistforlab" element={<PatientRequestforLab />} />
          <Route path="PaymentHistroy" element={<PaymentHistory />} />
          <Route path="Bill-Cancellation" element={<Billcancelation />} />
          <Route path="BarcodePrint" element={<BarcodePrint />} />
          <Route path="Deu-History" element={<DeuHistory />} />
          <Route path="RequestListLab" element={<RequestListLab />} />
          <Route path="Deu-History-List" element={<DueHistoryList />} />

          {/* Phelobotomist */}
          <Route path="CaptureSamplelist" element={<CaptureSample />} />
          <Route path="PhelobotomistCapture" element={<Navigationple />} />
          <Route path="SamplelistData" element={<SampleList />} />
          <Route path="TransactionHistory" element={<TransactionHistory />} />

          {/* Lab Technicians */}

          <Route path="AnalayseReportEntry" element={<Analyzereport />} />
          <Route path="NavigationLabtechnician" element={<NavigationLab />} />
          <Route path="ValidatedQue" element={<ValidatedQueue />} />

          {/* Sr. LabTechnician */}
          <Route path="VerifyReportEntry" element={<Verifyreport />} />
          <Route path="NavigationSenior" element={<Navigationseior />} />
          <Route path="SrLabValidatedQue" element={<SrValidatedQueue />} />
          <Route path="SrLabreportpreview" element={<Reportspreview />} />


          {/* Pathologist */}
          <Route path="AuthorizedReportEntry" element={<Approvereport />} />
          <Route path="NavigationPathalogist" element={<Navigationpath />} />
          <Route path="PathValidatedQue" element={<PathValidatedQueue />} />
          <Route path="Pathreportpreview" element={<PathologyEdit />} />

          {/* Dispatch */}
          <Route path="DispatchEntryList" element={<DispatchList />} />
          <Route path="CompleteReports" element={<Reports />} />

          {/* Radiology */}
          <Route path="RadiologyMaster" element={<RadiologyMaster />} />
          <Route path="FranchiseMaster" element={<FranchiseMaster />} />
          <Route path="tatreport" element={<TAT />} />
          <Route path="ReferingDoctorReport" element={<ReferDocreport />} />
          <Route path="Userwisebillreport" element={<Userwisebillreport />} />
          <Route path="Audittrailreport" element={<AuditTrial />} />
          <Route path="ReferDocreviewreport" element={<ReferDocreviewreport />} />
          <Route path="RevenueByDepartment" element={<RevenueByDepartment />} />
          <Route path="Refering-Hospital-Report" element={<ReferingHospitalReport />} />
          <Route path="Salesreport" element={<Salesreporthospital />} />


          
          {/* Finance */}
          <Route path="AccountsMasterCreate" element={<AccountsMaster/>} />
          <Route path="AccountsMasterList" element={<AccountsMasterList/>} />
          <Route path="ContraVoucherFinance" element={<ContraVoucher/>} />
          <Route path="ContraVoucherListFinance" element={<ContraVoucherFinance/>} />
          <Route path="TransactionTypeMaster" element={<TransactionTypeMaster/>} />
          <Route path="PaymentVoucherFinance" element={<PaymentVoucherFinance/>} />
          <Route path="PaymentVoucherFinanceList" element={<PaymentVoucherFinanceList/>} />
          <Route path="ReceiptVoucherFinance" element={<ReceiptVoucherFinance/>} />
          <Route path="ReceiptVoucherFinanceList" element={<ReceiptVoucherFinanceList/>} />
          <Route path="JournalentryFinance" element={<JournalentryFinance/>} />
          <Route path="JournalentryFinanceList" element={<JournalentryFinanceList/>} />
          <Route path="CashbookFinance" element={<CashbookFinance/>} />
          <Route path="BankbookFinance" element={<BankbookFinance/>} />
          <Route path="DayBookFinance" element={<DayBookFinance/>} />
          <Route path="TrialBalanceFinance" element={<TrialBalanceFinance/>} />
          <Route path="BalanceSheetFinance" element={<BalanceSheetFinance/>} />
          <Route path="ProfitandLossFinance" element={<ProfitandLossFinance/>}/>


          {/* Footer */}
          <Route path="Privacy-Policy" element={<PrivacyPolicy />} />
          <Route path="Terms-of-Use" element={<TermsOfUse />} />

          {/* NotFound */}
          <Route path="*" element={<NotFound />} />

        </Route>
        <Route path='/' element={<LoginPage />} />
        {/* NotFound */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

export default App;