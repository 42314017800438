import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function AccountGroupMaster() {
  const navigate = useNavigate();

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const Editdata = useSelector((state) => state.FinanceStore?.GroupEditdata);

  const [formState, setFormState] = useState({
    id: "",
    AliasName: "",
    GroupName: "",
    UnderGroup: "",
    NatureOfGroup: "",
    PrimaryKey: "Yes",
    Location: userRecord.location,
    CreatedBy: userRecord.username,
    Status: "Active",
  });
  console.log("formState :", formState);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [datalistundergroup, setdatalistundergroup] = useState([]);

  const [getAllgroupdata, setgetAllgroupdata] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${urllink}FinanceMagement/get_undergroup?primarygroup=${formState.PrimaryKey}&name=${formState.GroupName}`
      )
      .then((response) => {
        console.log(response.data);
        setdatalistundergroup(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [formState.PrimaryKey]);

  useEffect(() => {
    axios
      .get(
        `${urllink}FinanceMagement/get_accountmaster_group?location=${userRecord.location}`
      )
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        setgetAllgroupdata(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (
      Object.keys(Editdata).length !== 0 &&
      Object.keys(userRecord).length !== 0
    ) {
      console.log("Editdata", userRecord.CreatedBy);

      setFormState({
        AliasName: Editdata.AliasName,
        GroupName: Editdata.GroupName,
        UnderGroup: Editdata.UnderGroup,
        NatureOfGroup: Editdata.NatureOfGroup,
        PrimaryKey: Editdata.PrimaryKey,
        Location: Editdata.Location,
        Status: Editdata.Status,
        S_No: Editdata.S_No,
        CreatedBy: userRecord.username,
      });
    }
  }, [Editdata, userRecord]);

  useEffect(() => {
    if (
      getAllgroupdata.length !== 0 &&
      formState.PrimaryKey === "No" &&
      formState.UnderGroup !== ""
    ) {
      console.log("formState.UnderGroup", formState.UnderGroup);

      console.log("getAllgroupdata", getAllgroupdata);

      let NOGrup = getAllgroupdata.find((ele) => {
        return ele.GroupName === formState.UnderGroup;
      });

      // let SingNOg=NOGrup.NatureOfGroup;

      setFormState((prev) => ({
        ...prev,
        NatureOfGroup: NOGrup.NatureOfGroup || "Nill",
      }));
    }
  }, [getAllgroupdata, formState.PrimaryKey, formState.UnderGroup]);

  const handlecleardata = () => {
    setFormState({
      id: "",
      AliasName: "",
      GroupName: "",
      UnderGroup: "",
      NatureOfGroup: "",
      PrimaryKey: "Yes",
      Location: userRecord.location,
      CreatedBy: userRecord.username,
      Status: "Active",
    });
  };

  const add_data = () => {
    console.log(formState);

    const requiredfields = ["GroupName", "AliasName", "PrimaryKey", "Status"];
    if (formState.PrimaryKey === "No") {
      requiredfields.push("UnderGroup");
    } else {
      requiredfields.push("NatureOfGroup");
    }
    const existing = requiredfields.filter((field) => !formState[field]);
    if (existing.length === 0) {
      axios
        .post(
          `${urllink}FinanceMagement/insert_account_group_master`,
          formState
        )
        .then((response) => {
          console.log(response.data);
          // const data = response.data;
          handlecleardata();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  const update_data = () => {
    console.log(formState);
    const requiredfields = ["GroupName", "AliasName", "PrimaryKey", "Status"];
    if (formState.PrimaryKey === "Yes") {
      requiredfields.push("UnderGroup");
    } else {
      requiredfields.push("NatureOfGroup");
    }
    const existing = requiredfields.filter((field) => !formState[field]);
    if (existing.length === 0) {
      axios
        .post(
          `${urllink}FinanceMagement/Update_Accountmaster_group`,
          formState
        )
        .then((response) => {
          console.log(response.data);
          // const data = response.data;
          handlecleardata();
          navigate("/Home/AccountGroupList");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Account Group Master</h4>
      </div>
      <br />
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="">
            Name <span>:</span>
          </label>
          <input
            type="text"
            name="GroupName"
            required
            value={formState.GroupName}
            onChange={(e) =>
              setFormState({ ...formState, GroupName: e.target.value })
            }
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="">
            Alias Name <span>:</span>
          </label>
          <input
            type="text"
            name="AliasName"
            required
            value={formState.AliasName}
            onChange={(e) =>
              setFormState({ ...formState, AliasName: e.target.value })
            }
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="">
            Primary Group <span>:</span>
          </label>
          <select
            onChange={(e) =>
              setFormState({ ...formState, PrimaryKey: e.target.value })
            }
            value={formState.PrimaryKey}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            Under Group <span>:</span>
          </label>
          {formState.PrimaryKey === "Yes" ? (
            <input readOnly />
          ) : (
            <select
              name="UnderGroup"
              required
              disabled={formState.PrimaryKey === "Yes"}
              value={formState.UnderGroup}
              onChange={(e) =>
                setFormState({ ...formState, UnderGroup: e.target.value })
              }
            >
              <option value=""> Select </option>
              {datalistundergroup.map((option, index) => (
                <option key={index}>{option.Primary_Group}</option>
              ))}
            </select>
          )}
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            Nature Of Group<span>:</span>
          </label>
          <select
            name="NatureOfGroup"
            required
            value={formState.NatureOfGroup}
            readOnly={formState.PrimaryKey === "No"}
            onChange={(e) =>
              setFormState({ ...formState, NatureOfGroup: e.target.value })
            }
          >
            <option value=""> Select </option>
            <option value="Assets">Assets</option>
            <option value="Expenses">Expenses</option>
            <option value="Income">Income</option>
            <option value="Liabilities">Liabilities</option>
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            Location <span>:</span>
          </label>
          <input
            type="text"
            name="ifscCode"
            required
            readOnly
            value={formState.Location}
            onChange={(e) =>
              setFormState({ ...formState, ifscCode: e.target.value })
            }
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            status <span>:</span>
          </label>
          <select
            onChange={(e) =>
              setFormState({ ...formState, Status: e.target.value })
            }
            value={formState.Status}
          >
            <option value="Active">Active</option>
            <option value="InActive">InActive</option>
          </select>
        </div>
      </div>
      <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns"
          type="button"
          onClick={Object.keys(Editdata).length !== 0 ? update_data : add_data}
        >
          {Object.keys(Editdata).length !== 0 ? "Update" : "Submit"}
        </button>
      </div>
    </div>
  );
}

export default AccountGroupMaster;
