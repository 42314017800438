import React, { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";

function ReceiptVoucherFinance() {
  
  const cu_date = format(new Date(), "yyyy-MM-dd");

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [isedit, setisedit] = useState(false);

  const [selectedShow, setSelectedShow] = useState(false);

  const  isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen)

  const [datalistdata, setdatalistdata] = useState([]);

  const [Paymentlist, setPaymentlist] = useState([]);

  const [ExpensLiblitydata, SetExpensLiblitydata] = useState([]);

  const [ArrayForMultiplePayment, setArrayForMultiplePayment] = useState([]);

  const [PaymentReciveState, setPaymentReciveState] = useState({
    VoucherNo: "",
    VoucherDate: cu_date,
    VoucherNarration: "",
    FromAccountID: "",
    FromAccount: "",        
    FromAccountOpnBalance: "",
    FromAccountCurBalance: "",
    DebitAmount: "",
  });

  const [ToAccountstate, setToAccountstate] = useState({
    ToAccountID: "",
    ToAccount: "",
    ToAccountCurBalance: "",
    ToAccountOpnBalance: "",
    BankDetailes: "",
    CreditAmount: "",
  });

  const [MultiplePaymentdata, setMultiplePaymentdata] = useState({
    PaymentType: "",
    PaymentAmount: "",
    Remarks: "",
  });

  useEffect(() => {
    getContraNum();
  }, []);

  const getContraNum = () => {
    axios
      .get(`${urllink}FinanceMagement/get_Receipt_Voucher_invoice_no`)
      .then((response) => {
        const data = response.data.nextContraInvoiceNo;

        setPaymentReciveState((prev) => ({
          ...prev,
          VoucherNo: data,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getdatalistdata();
    Allleddetailes();
  }, []);


  useEffect(()=>{
    if(ToAccountstate.BankDetailes === "Yes" && ToAccountstate.ToAccount !== '' && ToAccountstate.ToAccountCurBalance !== ''){
    setSelectedShow(true)
    }else{
      setSelectedShow(false)
    }
  },[ToAccountstate.BankDetailes,ToAccountstate.ToAccount,ToAccountstate.ToAccountCurBalance === ''])

  const getdatalistdata = () => {
    axios
      .get(`${urllink}FinanceMagement/getledgerbal`)
      .then((response) => {
        const data = response.data.LedgerEntries;

        setdatalistdata(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Allleddetailes = () => {
    axios
      .get(`${urllink}FinanceMagement/get_Ledger_for_PaymentVocher_Cur_balance`)
      .then((response) => {
        const data = response.data.LedgerEntries;

        SetExpensLiblitydata(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const PaymentstateFun = (event) => {
    const { name, value } = event.target;

    if (name === "FromAccount" && ExpensLiblitydata.length !== 0) {
      let findData = ExpensLiblitydata.find((ele) => ele.Account_Name === value);

      if (findData) {
        setPaymentReciveState((prev) => ({
          ...prev,
          [name]: value,
          FromAccountOpnBalance: findData.Current_Balance,
          FromAccountCurBalance: findData.Current_Balance,
          FromAccountID:findData.AccountID,
        }));
      } else {
        setPaymentReciveState((prev) => ({
          ...prev,
          [name]: value,
          FromAccountOpnBalance: "",
          FromAccountCurBalance: "",
          FromAccountID: "",
        }));
      }
    }else if (name === "FromAccountID" && ExpensLiblitydata.length !== 0) {
      let findData = ExpensLiblitydata.find((ele) => +ele.AccountID === +value);

      if (findData) {
        setPaymentReciveState((prev) => ({
          ...prev,
          [name]: value,
          FromAccountOpnBalance: findData.Current_Balance,
          FromAccountCurBalance: findData.Current_Balance,
          FromAccount:findData.Account_Name,
        }));
      } else {
        setPaymentReciveState((prev) => ({
          ...prev,
          [name]: value,
          FromAccountOpnBalance: "",
          FromAccountCurBalance: "",
          FromAccount: "",
        }));
      }
    } else {
      setPaymentReciveState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const ToAccountstateFun = (event) => {
    const { name, value } = event.target;

    if (name === "ToAccount" && datalistdata.length !== 0) {
      let findData = datalistdata.find((ele) => ele.Account_Name === value);

      if (findData) {
        setToAccountstate((prev) => ({
          ...prev,
          [name]: value,
          ToAccountOpnBalance: findData.Current_Balance,
          ToAccountCurBalance: findData.Current_Balance,
          BankDetailes: findData.BankDetailes,
          ToAccountID:findData.AccountID,
        }));
      } else {
        setToAccountstate((prev) => ({
          ...prev,
          [name]: value,
          ToAccountCurBalance: "",
          ToAccountOpnBalance: "",
          BankDetailes:"",
          ToAccountID:'',
          CreditAmount:'',
        }));
      }
    } else if (name === "ToAccountID" && datalistdata.length !== 0) {
      let findData = datalistdata.find((ele) => +ele.AccountID === +value);

      if (findData) {
        setToAccountstate((prev) => ({
          ...prev,
          [name]: value,
          ToAccountOpnBalance: findData.Current_Balance,
          ToAccountCurBalance: findData.Current_Balance,
          BankDetailes: findData.BankDetailes,
          ToAccount:findData.Account_Name,
        }));
      } else {
        setToAccountstate((prev) => ({
          ...prev,
          [name]: value,
          ToAccountCurBalance: "",
          ToAccountOpnBalance: "",
          ToAccount: "",
          BankDetailes:'',
          CreditAmount:'',
        }));
      }
    } else {
      setToAccountstate((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const MultiplePaymentFun = (event) => {
    const { name, value } = event.target;
    setMultiplePaymentdata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (ToAccountstate.CreditAmount !== "") {
      const CrdAmo =
        +ToAccountstate.ToAccountOpnBalance +
        +ToAccountstate.CreditAmount;

      setToAccountstate((prev) => ({
        ...prev,
        ToAccountCurBalance: CrdAmo,
      }));
    } else {
      setToAccountstate((prev) => ({
        ...prev,
        ToAccountCurBalance: +ToAccountstate.ToAccountOpnBalance,
      }));
    }
  }, [ToAccountstate.CreditAmount]);

  useEffect(() => {
    if (PaymentReciveState.DebitAmount !== "") {
      const balAmo =
        +PaymentReciveState.FromAccountOpnBalance - +PaymentReciveState.DebitAmount;

      if (balAmo) {
        setPaymentReciveState((prev) => ({
          ...prev,
          FromAccountCurBalance: balAmo,
        }));
      } else {
        setPaymentReciveState((prev) => ({
          ...prev,
          FromAccountCurBalance: PaymentReciveState.FromAccountOpnBalance,
        }));
      }
    }
  }, [PaymentReciveState.DebitAmount]);

  useEffect(() => {
    if (Paymentlist.length !== 0) {
      const Amounttotal = Paymentlist.reduce(
        (total, ele) => total + +ele.CreditAmount,
        0
      );

      setPaymentReciveState((prev) => ({
        ...prev,
        DebitAmount: Amounttotal,
      }));
    } else {
      setPaymentReciveState((prev) => ({
        ...prev,
        DebitAmount: 0,
      }));
    }
  }, [Paymentlist]);

  useEffect(() => {
    if (
      ArrayForMultiplePayment.length !== 0 &&
      selectedShow
    ) {
      const Amounttotal = ArrayForMultiplePayment.reduce(
        (total, ele) => total + +ele.PaymentAmount,
        0
      );

      setToAccountstate((prev) => ({
        ...prev,
        CreditAmount: Amounttotal,
        ToAccountCurBalance:
          +ToAccountstate.ToAccountOpnBalance + +Amounttotal,
      }));
    } else if (
      ArrayForMultiplePayment.length === 0 &&
      selectedShow
    ) {
      setToAccountstate((prev) => ({
        ...prev,
        CreditAmount: 0,
        ToAccountCurBalance: ToAccountstate.ToAccountOpnBalance,
      }));
    }
  }, [ArrayForMultiplePayment,selectedShow]);

  const ClearContradata = () => {
    setPaymentReciveState({
      VoucherNo: "",
      VoucherDate: cu_date,
      VoucherNarration: "",
      FromAccountID: "",
      FromAccount: "",
      FromAccountUnderGroup: "",
      FromAccountOpnBalance: "",
      FromAccountCurBalance: "",
      DebitAmount: "",
    });
  };

  const handlecleardata = () => {
    setToAccountstate({
      ToAccountID: "",
      ToAccount: "",
      ToAccountCurBalance: "",
      ToAccountOpnBalance: "",
      BankDetailes: "",
      CreditAmount: "",
    });
  };

  const clearmultiplpaydata = () => {
    setMultiplePaymentdata({
      PaymentType: "",
      PaymentAmount: "",
      Remarks: "",
    });
  };

  const Addmuldiplepayment = () => {
    const requiredfields = ["PaymentType", "PaymentAmount", "Remarks"];

    const existing = requiredfields.filter(
      (field) => !MultiplePaymentdata[field]
    );

    if (existing.length === 0) {
      setArrayForMultiplePayment((prev) => [
        ...prev,
        {
          id: prev.length + 1,
          ...MultiplePaymentdata,
        },
      ]);

      clearmultiplpaydata();
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  const add_data = () => {
    const requiredfields = [
      "ToAccount",
      "ToAccountCurBalance",
      "CreditAmount",
    ];

    const existing = requiredfields.filter(
      (field) => !ToAccountstate[field]
    );

    if (existing.length === 0) {
      const CheckName = Paymentlist.find(
        (ele) =>
          ele.ToAccount.toLowerCase() ===
          ToAccountstate.ToAccount.toLowerCase()
      );

      if (CheckName) {
        alert("ToAccount Already Exists");
      } else {
        setPaymentlist((prev) => [
          ...prev,
          {
            id: prev.length + 1,
            MulitPayment: ArrayForMultiplePayment,
            ...ToAccountstate,
          },
        ]);

        handlecleardata();
      }

      setArrayForMultiplePayment([]);
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  const Editdataform = (row) => {
    if (row) {
      let paydatas = row.MulitPayment;
      setToAccountstate((prev) => ({
        ...prev,
        id: row.id,
        ToAccount: row.ToAccount,
        ToAccountCurBalance: row.ToAccountCurBalance,
        ToAccountOpnBalance: row.ToAccountOpnBalance,
        BankDetailes: row.BankDetailes,
        CreditAmount: row.CreditAmount,
        ToAccountID: row.ToAccountID,
      }));
      setArrayForMultiplePayment(paydatas);
    }
  };

  const Deletedataform = (row) => {
    setPaymentlist((prevList) => {
      const updatedList = prevList.filter((ele) => ele.id !== row.id);
      return updatedList.map((newRow, ind) => ({ ...newRow, id: ind + 1 }));
    });
  };

  const Deletemultipay = (row) => {
    setArrayForMultiplePayment((prevList) => {
      const updatedList = prevList.filter((ele) => ele.id !== row.id);
      return updatedList.map((newRow, ind) => ({ ...newRow, id: ind + 1 }));
    });
  };

  const update_data = () => {
    const requiredfields = [
      "ToAccount",
      "ToAccountCurBalance",
      "CreditAmount",
    ];

    const existing = requiredfields.filter(
      (field) => !ToAccountstate[field]
    );

    if (existing.length === 0) {
      const CheckName = Paymentlist.filter(
        (ele) => ele.id !== ToAccountstate.id
      ).find(
        (ele) =>
          ele.ToAccount.toLowerCase() ===
          ToAccountstate.ToAccount.toLowerCase()
      );

      if (CheckName) {
        alert("ToAccount Already Exists");
      } else {
        setPaymentlist((prevList) => {
          const updatedList = prevList.filter(
            (ele) => ele.ToAccount !== ToAccountstate.ToAccount
          );
          return [
            ...updatedList,
            { ...ToAccountstate, MulitPayment: ArrayForMultiplePayment },
          ];
        });

        handlecleardata();
        setArrayForMultiplePayment([]);
        setisedit(false);
      }
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  const Postalldata = () => {
    const requiredfields = [
      "VoucherNo",
      "VoucherDate",
      "VoucherNarration",
      "FromAccount",
      "FromAccountCurBalance",
      "DebitAmount",
    ];

    const existing = requiredfields.filter((field) => !PaymentReciveState[field]);

    if (existing.length === 0) {
      let Insertdata = {
        PaymentReciveState: PaymentReciveState,
        Paymentlist: Paymentlist,
        Location: userRecord.location,
        CreatedBy: userRecord.username,
      };

      axios
        .post(
          `${urllink}FinanceMagement/insert_Receipt_Voucher_Entry_details`,
          Insertdata
        )
        .then((response) => {
          alert(response.data.Message);
          ClearContradata();
          setPaymentlist([]);
          getContraNum();
          getdatalistdata();
          Allleddetailes();
        })
        .catch((error) => {
          console.error(error);
          alert("Failed to add data. Please try again.");
        });
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Receipt Voucher F</h4>
      </div>
      <br />
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="VoucherNo">
            Voucher No <span>:</span>
          </label>
          <input
            type="text"
            name="VoucherNo"
            required
            readOnly
            value={PaymentReciveState.VoucherNo}
            onChange={PaymentstateFun}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="VoucherDate">
            Voucher Date <span>:</span>
          </label>
          <input
            type="date"
            name="VoucherDate"
            required
            value={PaymentReciveState.VoucherDate}
            onChange={PaymentstateFun}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="VoucherNarration">
            Short Narration <span>:</span>
          </label>
          <textarea
            type="text"
            name="VoucherNarration"
            required
            value={PaymentReciveState.VoucherNarration}
            onChange={PaymentstateFun}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="FromAccount">
            From Account ID<span>:</span>
          </label>
          <input
            type="text"
            name="FromAccountID"
            list="DrAC_listID"
            required
            value={PaymentReciveState.FromAccountID}
            onChange={PaymentstateFun}
            autoComplete="off"
          />
           <datalist id="DrAC_listID">
            {ExpensLiblitydata.map((item, index) => (
              <option key={index+'key'} value={item.AccountID}></option>
            ))}
          </datalist>
          
        </div>

        <div className="RegisForm_1">
          <label htmlFor="FromAccount">
            From Account <span>:</span>
          </label>
          <input
            type="text"
            name="FromAccount"
            list="DrAC_list"
            required
            value={PaymentReciveState.FromAccount}
            onChange={PaymentstateFun}
          />
           <datalist id="DrAC_list">
            {ExpensLiblitydata.map((item, index) => (
              <option key={index+'key'} value={item.Account_Name}></option>
            ))}
          </datalist>
          
        </div>

        <div className="RegisForm_1">
          <label htmlFor="FromAccountCurBalance">
            Cur Balance<span>:</span>
          </label>
          <input
            type="text"
            name="FromAccountCurBalance"
            id="FromAccountCurBalance"
            required
            readOnly
            value={PaymentReciveState.FromAccountCurBalance}
            onChange={PaymentstateFun}
          />
        </div>


        <div className="RegisForm_1">
          <label htmlFor="DebitAmount">
            Debit <span>:</span>
          </label>
          <input
            type="text"
            name="DebitAmount"
            id="DebitAmount"
            required
            readOnly
            value={PaymentReciveState.DebitAmount}
            onChange={PaymentstateFun}
          />
        </div>
        
        <div className="RegisForm_1">
          <label>
            To Account ID<span>:</span>
          </label>
          <input
            type="text"
            name="ToAccountID"
            id="ToAccount"
            list="CrAC_listID"
            required
            value={ToAccountstate.ToAccountID}
            onChange={ToAccountstateFun}
            autoComplete="off"
          />
          <datalist id="CrAC_listID">
            {datalistdata.map((item, index) => (
              <option key={index +'Key'} value={item.AccountID}></option>
            ))}
          </datalist>
         
        </div>

        <div className="RegisForm_1">
          <label>
            To Account <span>:</span>
          </label>
          <input
            type="text"
            name="ToAccount"
            id="ToAccount"
            list="CrAC_list"
            required
            value={ToAccountstate.ToAccount}
            onChange={ToAccountstateFun}
          />
          <datalist id="CrAC_list">
            {datalistdata.map((item, index) => (
              <option key={index +'Key'} value={item.Account_Name}></option>
            ))}
          </datalist>
         
        </div>

        <div className="RegisForm_1">
          <label htmlFor="ToAccountCurBalance">
            Cur Balance<span>:</span>
          </label>
          <input
            type="text"
            name="ToAccountCurBalance"
            required
            readOnly
            value={ToAccountstate.ToAccountCurBalance}
            onChange={ToAccountstateFun}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="CreditAmount">
            Credit <span>:</span>
          </label>
          <input
            type="number"
            name="CreditAmount"
            required
            disabled={selectedShow === true}
            value={ToAccountstate.CreditAmount}
            onChange={ToAccountstateFun}
          />
        </div>
      </div>

      <br />

      {selectedShow === true ? <></>: (
        <>
        <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns"
          type="button"
          onClick={isedit ? update_data : add_data}
        >
          {isedit ? "Update" : "Add"}
        </button>
        </div>          
        </>
      ) }


      

      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Particulars</th>
              <th>Current Balance</th>
              <th>CreditAmount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Paymentlist.map((row, index) => (
              <tr key={index}>
                <td>{row.id}</td>
                <td>{row.ToAccount}</td>
                <td>{row.ToAccountCurBalance}</td>
                <td>{row.CreditAmount}</td>
                <td>
                  <EditIcon
                    onClick={() => {
                      Editdataform(row);
                      setisedit(true);
                    }}
                  />

                  <DeleteIcon
                    onClick={() => {
                      Deletedataform(row);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="for_total9">
        <div className="for_total9_xyz">
          Total<span>:</span>
        </div>
        <div className="for_total9_xyz">
          {PaymentReciveState.DebitAmount || 0}
        </div>
      </div>

      <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns"
          type="button"
          onClick={Postalldata}
        >
          Submit
        </button>
      </div>
      {selectedShow && (
          <div
            className={
              isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
            } onClick={() => {
              setSelectedShow(false);
            }}

          >
            <div className="newwProfiles" style={{padding:'10px',boxSizing:'border-box'}} onClick={(e) => e.stopPropagation()}>
              <div className="appointment">
              <div className="calendar_head">
                  <h3>Payment List</h3>
                </div>
                <>
                    <div className="RegisFormcon">
                        <div className="RegisForm_1">
                        <label htmlFor="PaymentType">
                            Payment Type <span>:</span>
                        </label>
                        <select
                            name="PaymentType"
                            required
                            value={MultiplePaymentdata.PaymentType}
                            onChange={MultiplePaymentFun}
                        >
                            <option value="">Select</option>
                            <option value="Cheque">Cheque</option>
                            <option value="OnlinePayment">OnlinePayment</option>
                            <option value="Others">Others</option>
                        </select>
                        </div>

                        <div className="RegisForm_1">
                        <label htmlFor="PaymentAmount">
                            Amount <span>:</span>
                        </label>
                        <input
                            type="number"
                            name="PaymentAmount"
                            required
                            value={MultiplePaymentdata.PaymentAmount}
                            onChange={MultiplePaymentFun}
                        />
                        </div>

                        <div className="RegisForm_1">
                        <label htmlFor="Remarks">
                            Remark <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="Remarks"
                            required
                            value={MultiplePaymentdata.Remarks}
                            onChange={MultiplePaymentFun}
                        />
                        </div>
                    </div>
                    <div className="Register_btn_con">
                        <div className="RegisterForm_1_btns">
                        <AddIcon
                            style={{ cursor: "pointer" }}
                            onClick={Addmuldiplepayment}
                        />
                        </div>
                    </div>

                    <div className="Selected-table-container">
                        <table className="selected-medicine-table2">
                        <thead>
                            <tr>
                            <th>S.No</th>
                            <th>Payment Type</th>
                            <th>Amount</th>
                            <th>Remarks</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ArrayForMultiplePayment.map((row, index) => (
                            <tr key={index}>
                                <td>{row.id}</td>
                                <td>{row.PaymentType}</td>
                                <td>{row.PaymentAmount}</td>
                                <td>{row.Remarks}</td>
                                <td>
                                <DeleteIcon
                                    onClick={() => {
                                    Deletemultipay(row);
                                    }}
                                />
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>

                    <div className="for_total9">
                        <div className="for_total9_xyz">Total</div>:
                        <div className="for_total9_xyz">
                        {ToAccountstate.CreditAmount || 0}
                        </div>
                    </div>
                </>
                
                <div className="Register_btn_con">
                  <button
                    className="RegisterForm_1_btns"
                    onClick={isedit ? update_data : add_data}
                  >
                    {isedit ? "Update" : "Add"}
                  </button>

                  <button
                    className="RegisterForm_1_btns"
                    onClick={() => {
                      setSelectedShow(false);
                      handlecleardata()
                      setArrayForMultiplePayment([])
                    }}

                  >
                    close
                  </button>
                </div>
               
              </div>


            </div>
          </div>
        )}
    </div>
  );
}

export default ReceiptVoucherFinance;
