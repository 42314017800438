import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Header.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { FaLocationDot } from "react-icons/fa6";
import profileImg from '../assets/profileimg.jpeg';
import { useDispatch, useSelector } from 'react-redux';



function Header() {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  const dispatchvalue = useDispatch();
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const isSidebarOpen = useSelector(state => state.userRecord?.isSidebarOpen)

  console.log(isSidebarOpen);

  const toggled = useSelector(state => state.userRecord?.toggled)
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const [ClinicDetails, setClinicDetails] = useState([]);
  const [selectedOption, setSelectedOption] = useState('logged');
  const [profiletogg, setprofiletogg] = useState(false);
  const [loctntogg, setloctntogg] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [newImageProfile, setNewImageProfile] = useState(null);
  const [locationOptions, setlocationOptions] = useState(null);
  const [Locationget, setLocationget] = useState('');


useEffect(()=>{
  setLocationget(userRecord?.location)
},[userRecord?.location])

  useEffect(() => {
    if (userRecord?.username) {
      axios
        .get(
          `${urllink}usercontrol/getlocationdataforlogin?username=${userRecord?.username}`
        )
        .then((response) => {
          const data = response.data;
          if (data) {
            setlocationOptions(data);
          } else {
            setlocationOptions([])
          }
        })
        .catch((error) => {
          console.error("Error fetching Location options:", error);
        });
    }
  }, [userRecord?.username])

  const handleprofiletoggle = () => {
    setprofiletogg(!profiletogg);
    setDropdownVisible(!isDropdownVisible);

  };

  const handleloctntoggle = () => {
    setloctntogg(!loctntogg);
  };

  const handleSidebarToggle = () => {
    dispatchvalue({ type: 'isSidebarOpen', value: !isSidebarOpen });
    dispatchvalue({ type: 'toggled', value: !toggled });
  };



  const handleProfile = (event) => {
    const value = event.target.value;

    if (value === 'Profile') {
      navigate('/Home/Profile');
      setSelectedOption('Profile'); // Update the selected option after navigation
      setDropdownVisible(false);

    } else if (value === 'PasswordChange') {
      navigate('/Home/PasswordChange');

      setSelectedOption('PasswordChange'); // Update the selected option after navigation
      setDropdownVisible(false);
    } else {
      event.preventDefault();
    }
  };

  useEffect(() => {
    // Move side effects to useEffect

    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        if (response.data) {
          const firstClinic = response.data;

          setClinicDetails(firstClinic); // Access the "Data" key
        } else {
          console.error('No record found');
        }
      })
      .catch((error) => console.error('Error fetching data'));

  }, []);



  useEffect(() => {
    if (userRecord) {
      axios.get(`${urllink}profile/getuserprofile`, {
        params: {
          user_id: userRecord?.user_id,
        },
      })
        .then((response) => {
          if (response.data && response.data.Profile_image) {
            const userData = response.data;
            console.log(userRecord?.user_id);
            setNewImageProfile(
              `data:${userData?.contentType};base64,${userData?.Profile_image}`
            );
          } else {
            console.log("User data or Profile_image not found or empty");
            // If user data or Profile_image is empty, set the default profile image
            setNewImageProfile(profileImg);
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          // If there's an error, set the default profile image
          setNewImageProfile(profileImg);
        });
    } else {
      // If userRecord is null, set the default profile image
      setNewImageProfile(profileImg);
    }
  }, [userRecord]);

  useEffect(() => {
    if (Locationget) {
      const data = { ...userRecord }
      data.location = Locationget
      
      axios.post(`${urllink}loginpage/location_Change`,data)
        .then((response)=>{
          const resdata= response.data.token
          localStorage.setItem('token', resdata)
          const storedToken = localStorage.getItem('token');
          if (storedToken) {

            const decodedToken = (token) => {
              const payloadBase64 = token.split('.')[1];
              const decodedPayload = atob(payloadBase64);
              return JSON.parse(decodedPayload);
            };
            const decodedTokenData = decodedToken(storedToken);
        
            dispatchvalue({ type: 'UserData', value: decodedTokenData })
          } else {
            if (location.pathname !== '/') {
              navigate('/')
            }
          }
        })
        .catch((error)=>{
          console.log(error);
        })

      
    }
  }, [Locationget])
  // Check if the current location matches the path for each option and update the selected option accordingly
  useEffect(() => {
    const path = location.pathname;
    if (path === '/Home/Profile') {
      setSelectedOption('Profile');
      setDropdownVisible(false);
    } else if (path === '/Home/PasswordChange') {
      setSelectedOption('PasswordChange');
      setDropdownVisible(false);
    } else {
      setSelectedOption('logged');
    }
  }, [location.pathname]);




  return (
    <>
      <div className='header_container_sidebar'>
        <div className="header mrgn_logo" >

          <div className='icn-tle-cntrfx'>

          {/*  <h1 className="header_name" >
              {ClinicDetails.clinicName}
            </h1>*/}

            {ClinicDetails.clinicLogo && (
              <img
                src={`data:${ClinicDetails.contentType};base64,${ClinicDetails.clinicLogo}`}
                alt={ClinicDetails.clinicName}
                className="logosmc"
              />
            )}
          </div>

          <div className='prfl-lctn-adjs'>
            <div className='flx-lct-icn4'>

              <div><FaLocationDot className="loc_icon" /></div>
              {/* <div> <h5 className='loc_input'>{userRecord?.location}v</h5></div> */}
              <div >

                <select className=' loc_input' value={Locationget} onChange={((e) => setLocationget(e.target.value))}>
                  {locationOptions && locationOptions.map((p, index) => (
                    <option key={index} value={p.location_name}>
                      {p.location_name}
                    </option>
                  ))}
                </select>
              </div>

            </div>

            <div className="select-container">
              <img
                onClick={handleprofiletoggle}
                style={{ width: '45px', height: '45px', borderRadius: '50%', cursor: 'pointer', marginTop: '5px' }}
                src={newImageProfile || profileImg}
                alt="Profile"
              />



              {isDropdownVisible && (
                <div className="dropdown-container">
                  <div className="select-container">
                    <div className="select-style" value={selectedOption} onChange={handleProfile}>
                      <div value="logged" className='eifjidfj'>
                        {userRecord ? (
                          <><span>{userRecord?.username} ( {userRecord?.role_name} )</span></>
                        ) : (
                          <>Not logged in</>
                        )}
                      </div>
                      <br></br>
                      <div className="selectrole" value="Profile" onClick={(() => { navigate("/Home/Profile") })}>Profile</div>
                      <div className="selectrole" value="PasswordChange" onClick={(() => { navigate("/Home/PasswordChange") })}>Password Change</div>
                    </div>
                  </div>
                </div>
              )}
            </div>

          </div>
        </div>
      </div>


      <div className='header_container_sidebar1'>
        <div className="headersmc" >
          <IconButton
            onClick={handleSidebarToggle}
            color="inherit"
            aria-label={toggled ? 'Close menu' : 'Open menu'}
          >
            {toggled ? <CloseIcon /> : <MenuIcon />}
          </IconButton>


          <div  >
            {ClinicDetails.Clinic_Logo && (
              <img
                src={`data:${ClinicDetails.contentType};base64,${ClinicDetails.clinicLogo}`}
                alt={ClinicDetails.clinicName}
                className="logosmc"
              />
            )}

          </div>

          <div className='flx-lct-icn4'>


            <div className="header_logo_toggle" >
              <div className='prfl-lctn-adjs'>

                <div><FaLocationDot className="loc_icon" onClick={handleloctntoggle} /></div>
                {loctntogg &&
                  <div className='toggled_box_header'>
                    <div> <h5 className='loc_input'>{userRecord?.location}</h5></div>
                  </div>}

                <img
                  onClick={handleprofiletoggle}
                  style={{ width: '40px', height: '40px', borderRadius: '50%', cursor: 'pointer' }}
                  src={newImageProfile || profileImg}
                  alt="Profile"
                />




                {profiletogg &&
                  <div className='toggled_box_header'>
                    <div className="">
                      {userRecord ? (
                        <span>{userRecord?.username} ( {userRecord?.role_name} )</span>
                      ) : (
                        <span>Not logged in</span>
                      )}
                    </div>
                    <div className="toggled_box_header_txt" onClick={(() => { navigate("/Home/Profile") })}>
                      Profile
                    </div>
                    <div className="toggled_box_header_txt" onClick={(() => { navigate("/Home/PasswordChange") })}>
                      Password Change
                    </div>
                  </div>

                }
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}

export default Header;
