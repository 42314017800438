import React, { useRef, useState, useEffect, useCallback } from "react";
// import Barcode from "react-barcode";
import "./Report.css";
import axios from "axios";
import { useSelector } from "react-redux";
// import QRCode from "qrcode.react";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import { toPng } from "html-to-image";
import { useNavigate } from "react-router-dom";
// import { fontSize } from "@mui/system";
import Docsign from "../assets/aparna.jpg";
import Microsign from "../assets/Micro.png";
// import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import JsBarcode from "jsbarcode";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TiArrowDownThick, TiArrowUpThick } from "react-icons/ti";
// import location from "../assets/location.jpg";
// import phoneicon from "../assets/phoneicon.jpg"
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import ReactToPrint from "react-to-print";
import mammoth from "mammoth";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "../LabTechnician/ckeditor-custom.css";
import { Button, Select, MenuItem } from '@mui/material';

// const chunkArray = (array, chunkSize) => {
//   const result = [];
//   for (let i = 0; i < array.length; i += chunkSize) {
//     result.push(array.slice(i, i + chunkSize));
//   }
//   return result;
// };

import LocIcon from "../assets/LocIcon.png";
import PhoneIcon from "../assets/PhoneIcon.png";
import MailIcon from "../assets/MailIcon.png";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});



function CancelAppointmentDialog({
  open,
  onClose,
  onConfirm,
  cancelReason,
  setCancelReason,
}) {
  const handleConfirm = () => {
    // Call the onConfirm callback with the cancellation reason
    onConfirm(cancelReason);
    // Close the dialog
    onClose();
  };

  const handleClose = () => {
    setCancelReason("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Reprint Register Reason</DialogTitle>
      <DialogContent>
        <TextField
          label="Reprint Reason"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={cancelReason}
          onChange={(e) => setCancelReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const generateBarcode = (value) => {
  try {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, value, {
      format: "CODE128",
      lineColor: "black",
      width: 2,
      height: 100,
      displayValue: true,
    });

    const image = canvas.toDataURL("image/png");
    console.log("image", image); // Check the generated image data URL
    return image;
  } catch (error) {
    console.error("Error generating barcode:", error);
  }
};

const TableComponent = React.forwardRef(
  (
    {
      patinetbillingbarcode,
      patinetbillingQr,
      capturedatas,
      report,
      summa,
      ClinicDetials,
      navigate,
      getSensitivityColor,
      uniqueOrganisms,
      alphabetHeaders,
      summa1,
      emailsent,
      SignPerson,
      printpapaer,
      isNoRendered,
      microandhistonumber,
      formatData,
      formatHeading,
      editMode,
      autoResizeTextarea,
      handleeditchange,
      editHistoMode,
      setFormatData,
      formatMethodName,
      openmodels,
      setopenmodels,
      contentRef,
      uploadPlugin,
      isSidebarOpen,
      setContent,
      content,
      editmicrodata,
      formatName,
      touppercasefun,

      handleContentEditableInputChange,
      showmodel,
      setshowmodel,
      culturevalue,
      page,
      pageSize,
      dynamicColumns,
      setPage,
      showdown,
      totalPages,
      setEditMode,
      handleupdatesencitivity,
      handleSensitivityChange,
      
      imagePreview
    },
    ref
  ) => {
    return (
      <div>
        {summa?.length > 0 && (
          <table ref={ref} className="print-table33">
            <thead className="print_header">
              <div>
                {!emailsent && (
                  <div
                    style={{
                      height: "127px",
                      width: "100%",
                    }}
                  ></div>
                )}

                {emailsent && (
                  <div className="dsdwd_p9o">
                    <tr className="ededfedf_9jui">
                      <td className="hdyd_p05421">
                        <div>
                          <img
                            src={ClinicDetials.ClinicLogo}
                            alt="logo"
                            style={{ position: "relative", right: "85px" }}
                          />
                          {/* <p style={{fontSize:'15px',marginBottom:'3px'
                          }}>A True Care Forever</p> */}
                        </div>

                        <div className="dchfejjj0p">
                          <div className="dchfejjj0p_hhdc">
                            <img src={LocIcon}></img>
                            <span>
                              {touppercasefun(ClinicDetials.ClinicAddress)},{" "}
                              <br />
                              {touppercasefun(ClinicDetials?.ClinicCity)} -{" "}
                              {ClinicDetials.ClinicCode}
                              {touppercasefun(ClinicDetials.ClinicCountry)}
                            </span>{" "}
                          </div>

                          <div className="dchfejjj0p_hhdc">
                            <img src={PhoneIcon}></img>
                            <span>
                              TEL : (+91) {ClinicDetials.ClinicMobileNo + " ; "}
                              (+91) {ClinicDetials.ClinicLandLineNo}
                            </span>{" "}
                          </div>

                          <div className="dchfejjj0p_hhdc">
                            <img src={MailIcon}></img>
                            <span> EMAIL : {ClinicDetials.ClinicMailID}</span>
                          </div>
                        </div>
                      </td>

                      <td
                        className="jjkd8i_line"
                        style={{
                          height: "3px",
                          width: "155%",
                          backgroundColor: "grey",
                          marginBottom: "5px",
                        }}
                      ></td>
                    </tr>
                  </div>
                )}

                {summa[0].tests.some((p) => p.Report_Type === "Ot") && (
                  <>
                    <div className="wiioo900">
                      <td>
                        <h3>
                          {capturedatas.Patient_Name}
                          {" , "}
                          {capturedatas.Refering_Hospital}
                        </h3>
                      </td>
                    </div>
                    <div className="wiioo900 nbjui_94d">
                      {/* <tr className="new_billing_address_2 ededfedf_9jui">
                    <td className="Register_btn_con_barcode">
                      <div id="get_imagecontent_1">
                        <img
                          src={
                            patinetbillingbarcode
                              ? generateBarcode(patinetbillingbarcode)
                              : ""
                          }
                          alt="barcodeimage"
                          style={{
                            width: "170px",
                            height: "40px",
                            objectFit: "fill",
                          }}
                        />
                      </div>
                    </td>
                    
                    <td className="new_billing_div_report">
                      <label>
                        Visit ID <p>:</p>
                      </label>
                      <span>{capturedatas.Visit_Id}</span>
                    </td>
                    <td className="new_billing_div_report">
                      <label>
                        Age/Gender <p>:</p>
                      </label>
                      <span>
                        {capturedatas.Age} {capturedatas?.Time_Period} /{" "}
                        {capturedatas.Gender}
                      </span>
                    </td>
                   
                    <td className="new_billing_div_report">
                      <label>
                        Reference Doctor <p>:</p>
                      </label>
                      <span>{capturedatas.Refering_Doctor}</span>
                    </td>
                  </tr> */}

                      <tr className="new_billing_address_2 ededfedf_9jui">
                        {/* <td className="Register_btn_con_barcode">
                      <div id="get_imagecontent_2">
                        <img
                          src={
                            capturedatas && capturedatas.Barcode
                              ? generateBarcode(capturedatas.Barcode)
                              : ""
                          }
                          alt="barcodeimage"
                          style={{
                            width: "170px",
                            height: "40px",
                            objectFit: "fill",
                          }}
                        />
                      </div>
                    </td> */}
                        {/* <td className="new_billing_div_report">
                      <label>
                        Invoice No <p>:</p>
                      </label>
                      <span>{capturedatas.Billing_Invoice}</span>
                    </td> */}
                        <td className="new_billing_div_report hhh_p2q">
                          <label>
                            Collected <p>:</p>
                          </label>
                          <span>{report.Collected}</span>
                        </td>
                        <td className="new_billing_div_report hhh_p2q">
                          <label>
                            Received <p>:</p>
                          </label>
                          <span>{report.Received}</span>
                        </td>
                        {/* <td className="new_billing_div_report">
                      <label>
                        Reported <p>:</p>
                      </label>
                      <span>{report.Reported}</span>
                    </td> */}
                        <td className="new_billing_div_report hhh_p2q">
                          <label>
                            Barcode <p>:</p>
                          </label>
                          <span>{capturedatas.Barcode}</span>
                        </td>
                      </tr>
                    </div>
                  </>
                )}

                {!summa[0].tests.some((p) => p.Report_Type === "Ot") && (
                  <div className="wiioo900">
                    <tr className="new_billing_address_2 ededfedf_9jui">
                      <td className="Register_btn_con_barcode">
                        <div id="get_imagecontent_1">
                          <img
                            src={
                              patinetbillingbarcode
                                ? generateBarcode(patinetbillingbarcode)
                                : ""
                            }
                            alt="barcodeimage"
                            style={{
                              width: "170px",
                              height: "40px",
                              objectFit: "fill",
                            }}
                          />
                        </div>
                      </td>
                      <td className="new_billing_div_report">
                        <label>
                          Patient Name <p>:</p>
                        </label>
                        <span>
                          {capturedatas.Title}. {capturedatas.Patient_Name}
                        </span>
                      </td>
                      {microandhistonumber === "" && (
                        <td className="new_billing_div_report">
                          <label>
                            Visit ID <p>:</p>
                          </label>
                          <span>{capturedatas.Visit_Id}</span>
                        </td>
                      )}
                      <td className="new_billing_div_report">
                        <label>
                          Age/Gender <p>:</p>
                        </label>
                        <span>
                          {capturedatas.Age} {capturedatas?.Time_Period}s/{" "}
                          {capturedatas.Gender}
                        </span>
                      </td>
                      {/* <td className="new_billing_div_report">
                      <label>
                        Phone <p>:</p>
                      </label>
                      <span>{capturedatas.Phone}</span>
                    </td> */}
                      <td className="new_billing_div_report">
                        <label>
                          Ref.Dr <p>:</p>
                        </label>
                        <span>{capturedatas.Refering_Doctor}</span>
                      </td>
                      {summa?.some((p) => p.Department === "MICROBIOLOGY") && (
                        <td className="new_billing_div_report">
                          <label>
                            Micro.no <p>:</p>
                          </label>
                          <span>
                            {summa
                              ?.filter((p) => p.Department === "MICROBIOLOGY")
                              .map((item) => item.tests[0].micronumber)}
                          </span>
                        </td>
                      )}

                      {microandhistonumber?.HistoNumber && (
                        <td className="new_billing_div_report">
                          <label>
                            Histo.no <p>:</p>
                          </label>
                          <span>{microandhistonumber?.HistoNumber}</span>
                        </td>
                      )}

                      <td className="new_billing_div_report">
                        <label>
                          Ref Hospital <p>:</p>
                        </label>
                        <span>{capturedatas.Refering_Hospital}</span>
                      </td>
                    </tr>

                    <tr className="new_billing_address_2 ededfedf_9jui">
                      <td className="Register_btn_con_barcode">
                        <div id="get_imagecontent_2">
                          <img
                            src={
                              capturedatas && capturedatas.Barcode
                                ? generateBarcode(capturedatas.Barcode)
                                : ""
                            }
                            alt="barcodeimage"
                            className="get_imagecontent_2_imhhhh"
                          />
                        </div>
                      </td>
                      <td className="new_billing_div_report ffddffn">
                        <label>
                          Invoice No <p>:</p>
                        </label>
                        <span>{capturedatas.Billing_Invoice}</span>
                      </td>
                      <td className="new_billing_div_report ffddffn">
                        <label>
                          Collected <p>:</p>
                        </label>
                        <span>{report.Collected}</span>
                      </td>
                      <td className="new_billing_div_report ffddffn">
                        <label>
                          Received <p>:</p>
                        </label>
                        <span>{report.Received}</span>
                      </td>
                      <td className="new_billing_div_report ffddffn">
                        <label>
                          Reported <p>:</p>
                        </label>
                        <span>{report.Reported}</span>
                      </td>
                    </tr>
                  </div>
                )}
              </div>
            </thead>

            <tbody className="print_body Selected-table-container">
              {console.log(summa)}
              {console.log(summa1, "klkkl")}
              {summa?.map((dpt, undex) => {
                if (
                  dpt.Department !== "HISTOPATHOLOGY" && // Exclude HISTOPATHOLOGY
                  dpt.tests.some((p) => p.Test_Code !== "RD0098")
                ) {
                  if (dpt.culturetest === "Yes") {
                    return (
                      <table
                        key={undex}
                        style={{
                          width: "81%",
                          position: "relative",
                          left: "95px",
                        }}
                      >
                        <>
                          {summa
                            .filter(
                              (p) =>
                                p.culturetest === "Yes" &&
                                p.Department !== "HISTOPATHOLOGY"
                            )
                            .map((group, groupIndex) => (
                              <div key={groupIndex} className="print-page3">
                                <div className="culture_report_data">
                                  <div className="culture_test_header">
                                    {/* <p>{group.Department} - ({group.groupName})</p> */}
                                    <p> Service - {group.Department}</p>
                                    {/* <p></p> */}
                                  </div>
                                  {console.log(summa1, "ufeuhfruejhrtjirtjiyjitjureytjrnjfitrih")}
                                  {group.tests[0]?.Report_Type === "Ot" &&
                                    summa1
                                      .filter(
                                        (p) => p.Department === "MICROBIOLOGY"
                                      )
                                      .map((group, index) => (
                                        <table
                                          className="report_table report_table_for_micro"
                                          key={index}
                                        >
                                          <tbody className="print_body Selected-table-container">
                                            <tr>
                                              <td>
                                                <h3>{group.groupName}</h3>

                                                <div className="completed_report_1111">
                                                  <div className="completed_report completed_report04948 gghbuy_o9">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent:
                                                          "center",
                                                        width: "100%",
                                                      }}
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          group.Tests[0]
                                                            ?.Content,
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      ))}
                                  {/* <br /> */}
                                  {console.log(summa1)}
                                  {(group.tests[0]?.Report_Type ===
                                    "NoGrowth" ||
                                    group.tests[0]?.Report_Type ===
                                      "Sensitivity" || 
                                    group.tests[0]?.Report_Type ===
                                      "Isolated") && (
                                    <div className="culturetest_div_head jgg_9i9kj Histo_new_css_div_head_po90">
                                      {group.Department === "MICROBIOLOGY" && (
                                        <span className="mkdijcfisd_o99">
                                          Visit ID : {capturedatas.Visit_Id}{" "}
                                        </span>
                                      )}

<div className="culturetest_div Histo_new_css_div">
                                        <label className="headerculture">
                                          Nature of specimen<span>:</span>
                                        </label>
                                        <p className="dataforprint">
                                          {group.tests[0]?.Sample}
                                        </p>
                                      </div>
                                      <div className="culturetest_div Histo_new_css_div">
                                        <label className="headerculture">
                                          Test Requested<span>:</span>
                                        </label>
                                        <p className="dataforprint">
                                          {group?.Display_Text}
                                        </p>
                                      </div>
                                     

                                      {(group.tests[0]?.Report_Type ===
                                        "NoGrowth" || group.tests[0]?.Report_Type ===
                                        "Isolated") && (
                                        <>
                                          <div className="culturetest_div Histo_new_css_div hewty_8j">
                                            <label className="headerculture">
                                              Report Status<span>:</span>
                                            </label>
                                            {editMode ? (
                                              <select
                                                className=""
                                                name="reportstatus"
                                                // value={reportstatus}
                                                // onChange={(e) =>
                                                //   setreportstatus(e.target.value)
                                                // }
                                                onChange={handleeditchange}
                                              >
                                                <option value="">Select</option>
                                                <option value="Final Report">
                                                  Final Report{" "}
                                                </option>
                                                <option value="Preliminary Report">
                                                  Preliminary Report
                                                </option>
                                              </select>
                                            ) : (
                                              <p className="dataforprint">
                                                {group.tests[0]?.Report_Status}
                                              </p>
                                            )}
                                          </div>
                                        </>
                                      )}
                                      {group.tests[0].Microscopy_Data !==
                                        "" && (
                                        <div className="culturetest_div Histo_new_css_div">
                                          <label className="headerculture">
                                            Microscopy<span>:</span>
                                          </label>
                                          {editMode ? (
                                            <textarea
                                              name="microscopy"
                                              onChange={handleeditchange}
                                              defaultValue={
                                                group.tests[0]?.Microscopy_Data
                                              }
                                              onBeforeInput={autoResizeTextarea}
                                              onInput={autoResizeTextarea}
                                            />
                                          ) : (
                                            <p className="dataforprint">
                                              {group.tests[0]?.Microscopy_Data}
                                            </p>
                                          )}
                                        </div>
                                      )}  

                                      {group.tests[0]?.Report_Type ===
                                        "NoGrowth" &&
                                        group.tests[0].Culture_Report !==
                                          "" && (
                                          <>
                                            <div className="culturetest_div Histo_new_css_div">
                                              <label className="headerculture">
                                                Culture Report<span>:</span>
                                              </label>
                                              {editMode ? (
                                                <textarea
                                                  name="culturereport"
                                                  onChange={handleeditchange}
                                                  defaultValue={
                                                    group.tests[0]
                                                      ?.Culture_Report
                                                  }
                                                  onBeforeInput={
                                                    autoResizeTextarea
                                                  }
                                                  onInput={autoResizeTextarea}
                                                />
                                              ) : (
                                                <p className="dataforprint">
                                                  {
                                                    group.tests[0]
                                                      ?.Culture_Report
                                                  }
                                                </p>
                                              )}
                                            </div>
                                          </>
                                        )}
                                        {group.tests[0]?.Report_Type ===
                                        "Isolated" &&
                                        group.tests[0].Bacteria_Isolated !==
                                          "" && (
                                          <>
                                            <div className="culturetest_div Histo_new_css_div">
                                              <label className="headerculture">
                                              Bacteria Isolated<span>:</span>
                                              </label>
                                              {editMode ? (
                                                <textarea
                                                  name="bacteriaisolated"
                                                  onChange={handleeditchange}
                                                  defaultValue={
                                                    group.tests[0]
                                                      ?.Bacteria_Isolated
                                                  }
                                                  onBeforeInput={
                                                    autoResizeTextarea
                                                  }
                                                  onInput={autoResizeTextarea}
                                                />
                                              ) : (
                                                <p className="dataforprint">
                                                  {
                                                    group.tests[0]
                                                      ?.Bacteria_Isolated
                                                  }
                                                </p>
                                              )}
                                            </div>
                                          </>
                                        )}
                                    </div>
                                  )}

                                  {group.tests[0]?.Report_Type ===
                                    "Sensitivity" && (
                                    <>
                                      {/* <br /> */}
                                      <div className="culturetest_div Histo_new_css_div ergfregreg_64">
                                        <label className="headerculture">
                                          Organism Isolated<span>:</span>
                                        </label>
                                        <p className="dataforprint summaprintsummauuu">
                                          <ol
                                            style={{
                                              listStyleType: "upper-alpha",
                                              textAlign: "start",
                                              paddingLeft: "10px",
                                            }}
                                          >
                                            {uniqueOrganisms.map(
                                              (org, index) => (
                                                <li key={index}>
                                                  <i
                                                    style={{
                                                      fontWeight: "600",
                                                    }}
                                                  >
                                                    {org}
                                                  </i>{" "}
                                                  - <span>Colony Count</span> -{" "}
                                                  {group.tests[0]?.colony_count}{" "}
                                                  {group.tests[0]?.UOM}
                                                </li>
                                              )
                                            )}
                                          </ol>
                                        </p>
                                      </div>
                                      {/* <br/> */}
                                      {/* <div className="culturetest_div">
                                        <label className="headerculture">
                                          Colony Count<span>:</span>
                                        </label>
                                        <p className="dataforprint">
                                          {group.tests[0]?.colony_count}{" "}
                                          {group.tests[0]?.UOM}
                                        </p>
                                      </div> */}
                                      <br />

                                      <div className="new_billing_Report_details">
                                        <div className="table-container554 dsfgrh_fx">
                                          {/* First Table */}
                                          <table className="report_table report_table_for_micro">
                                            <thead>
                                              <tr>
                                                <th>Antibiotics</th>
                                                {alphabetHeaders.map(
                                                  (alpha, index) => (
                                                    <>
                                                      <th key={index}>
                                                        {alpha}
                                                      </th>
                                                    </>
                                                  )
                                                )}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {group.Sensitive
                                                // Sort antibiotics alphabetically
                                                .slice(
                                                  0,
                                                  Math.ceil(
                                                    group.Sensitive.length / 3
                                                  )
                                                )
                                                .map((item, index) => (
                                                  <tr key={index}>
                                                    <td>{item.antibiotic}</td>
                                                    {uniqueOrganisms.map(
                                                      (org, index) => (
                                                        <td key={index}>
                                                          <p
                                                            style={{
                                                              color:
                                                                getSensitivityColor(
                                                                  item
                                                                    .organisms[
                                                                    org
                                                                  ]
                                                                ),
                                                            }}
                                                          >
                                                            {(item.organisms[
                                                              org
                                                            ] === "Sensitive" &&
                                                              "S") ||
                                                              (item.organisms[
                                                                org
                                                              ] ===
                                                                "Intermediate" &&
                                                                "I") ||
                                                              (item.organisms[
                                                                org
                                                              ] ===
                                                                "Resistant" &&
                                                                "R") ||
                                                              "-"}
                                                          </p>
                                                        </td>
                                                      )
                                                    )}
                                                  </tr>
                                                ))}
                                            </tbody>

                                            <tfoot>
                                              <tr>
                                                <td
                                                  colSpan={
                                                    uniqueOrganisms.length + 1
                                                  }
                                                >
                                                  <br />
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      gap: "10px",
                                                    }}
                                                  >
                                                    <p
                                                      style={{ color: "green" }}
                                                    >
                                                      S
                                                    </p>{" "}
                                                    ----{" "}
                                                    <p
                                                      style={{
                                                        fontWeight: "600",
                                                      }}
                                                    >
                                                      Sensitive
                                                    </p>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tfoot>
                                          </table>

                                          {/* Second Table */}
                                          <table className="report_table report_table_for_micro">
                                            <thead>
                                              <tr>
                                                <th>Antibiotics</th>
                                                {alphabetHeaders.map(
                                                  (alpha, index) => (
                                                    <>
                                                      <th key={index}>
                                                        {alpha}
                                                      </th>
                                                    </>
                                                  )
                                                )}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {group.Sensitive.slice(
                                                Math.ceil(
                                                  group.Sensitive.length / 3
                                                ),
                                                2 *
                                                  Math.ceil(
                                                    group.Sensitive.length / 3
                                                  )
                                              ).map((item, index) => (
                                                <tr key={index}>
                                                  <td>{item.antibiotic}</td>
                                                  {uniqueOrganisms.map(
                                                    (org, index) => (
                                                      <td key={index}>
                                                        <p
                                                          style={{
                                                            color:
                                                              getSensitivityColor(
                                                                item.organisms[
                                                                  org
                                                                ]
                                                              ),
                                                          }}
                                                        >
                                                          {(item.organisms[
                                                            org
                                                          ] === "Sensitive" &&
                                                            "S") ||
                                                            (item.organisms[
                                                              org
                                                            ] ===
                                                              "Intermediate" &&
                                                              "I") ||
                                                            (item.organisms[
                                                              org
                                                            ] === "Resistant" &&
                                                              "R") ||
                                                            "-"}
                                                        </p>
                                                      </td>
                                                    )
                                                  )}
                                                </tr>
                                              ))}
                                            </tbody>
                                            <tfoot>
                                              <tr>
                                                <td
                                                  colSpan={
                                                    uniqueOrganisms.length + 1
                                                  }
                                                >
                                                  <br />
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      gap: "10px",
                                                    }}
                                                  >
                                                    <p
                                                      style={{ color: "blue" }}
                                                    >
                                                      I
                                                    </p>{" "}
                                                    ----
                                                    <p
                                                      style={{
                                                        fontWeight: "600",
                                                      }}
                                                    >
                                                      Intermediate
                                                    </p>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tfoot>
                                          </table>

                                          {/* Third Table */}
                                          <table className="report_table report_table_for_micro">
                                            <thead>
                                              <tr>
                                                <th>Antibiotics</th>
                                                {alphabetHeaders.map(
                                                  (alpha, index) => (
                                                    <>
                                                      <th key={index}>
                                                        {alpha}
                                                      </th>
                                                    </>
                                                  )
                                                )}
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {group.Sensitive.slice(
                                                2 *
                                                  Math.ceil(
                                                    group.Sensitive.length / 3
                                                  )
                                              ).map((item, index) => (
                                                <tr key={index}>
                                                  <td>{item.antibiotic}</td>
                                                  {uniqueOrganisms.map(
                                                    (org, index) => (
                                                      <>
                                                        <td key={index}>
                                                          <p
                                                            style={{
                                                              color:
                                                                getSensitivityColor(
                                                                  item
                                                                    .organisms[
                                                                    org
                                                                  ]
                                                                ),
                                                            }}
                                                          >
                                                            {(item.organisms[
                                                              org
                                                            ] === "Sensitive" &&
                                                              "S") ||
                                                              (item.organisms[
                                                                org
                                                              ] ===
                                                                "Intermediate" &&
                                                                "I") ||
                                                              (item.organisms[
                                                                org
                                                              ] ===
                                                                "Resistant" &&
                                                                "R") ||
                                                              "-"}
                                                          </p>
                                                        </td>
                                                      </>
                                                    )
                                                  )}
                                                </tr>
                                              ))}
                                            </tbody>

                                            <tfoot>
                                              <tr>
                                                <td
                                                  colSpan={
                                                    uniqueOrganisms.length + 1
                                                  }
                                                >
                                                  <br />
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      gap: "10px",
                                                    }}
                                                  >
                                                    <p style={{ color: "red" }}>
                                                      R
                                                    </p>{" "}
                                                    ----{" "}
                                                    <p
                                                      style={{
                                                        fontWeight: "600",
                                                      }}
                                                    >
                                                      Resistant
                                                    </p>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tfoot>
                                          </table>
                                        </div>
                                      </div>
                                      <br />
                                      {(group.tests[0]?.Report_Type !==
                                    "Ot" && group?.tests[0].Notes !=="") &&
                                        
                                        <div className="culturetest_div tffyfcgyt_o Histo_new_css_div">
                                        {" "}
                                        <label className="headerculture">
                                          Notes<span>:</span>
                                        </label>
                                        {editMode ? (
                                          <textarea
                                            name="notes"
                                            onChange={handleeditchange}
                                            defaultValue={group?.tests[0].Notes}
                                            onBeforeInput={
                                              autoResizeTextarea
                                            }
                                            onInput={autoResizeTextarea}
                                          />
                                        ) : (
                                          <p className="dataforprint">
                                            {group?.tests[0].Notes}
                                          </p>
                                        )}
                                      </div>}
                                     
                                    </>
                                  )}

{(group.tests[0]?.Report_Type !==
                                    "Ot" && group.tests[0]?.Report_Type !==
                                    "Ot" && group?.tests[0].Notes !=="") &&
                                        
                                        <div className="culturetest_div Histo_new_css_div micronotessiumam">
                                        {" "}
                                        <label className="headerculture">
                                          Notes<span>:</span>
                                        </label>
                                        {editMode ? (
                                          <textarea
                                            name="notes"
                                            onChange={handleeditchange}
                                            defaultValue={group?.tests[0].Notes}
                                            onBeforeInput={
                                              autoResizeTextarea
                                            }
                                            onInput={autoResizeTextarea}
                                          />
                                        ) : (
                                          <p className="dataforprint">
                                            {group?.tests[0].Notes}
                                          </p>
                                        )}
                                      </div>}

                                  <br />

                                  {group.tests[0]?.Report_Status !==
                                    "Preliminary Report" && (
                                    <div className="Add_items_Purchase_Master">
                                      <span> - End of Report - </span>
                                    </div>
                                  )}

                                  <tfoot className="print_footerr">
                                    <tr className="jjxcdsjjej_">
                                      <td className="footer_fix_conten_fyft microejeo">
                                        <div className="dcnnmjy0s66">
                                          <div className="Register_btn_con_QRcode_fix">
                                            <img
                                              src={patinetbillingQr}
                                              alt="qrcode"
                                              style={{
                                                width: "80px",
                                                height: "80px",
                                                objectFit: "fill",
                                              }}
                                            />
                                          </div>

                                          <div className="doctr_sign_fix7cc doctr_sign_fix7cc_secddg">
                                            {dpt.Department ===
                                              "MICROBIOLOGY" && (
                                              <>
                                                <img
                                                  src={Microsign}
                                                  alt=""
                                                  className="docsign_fix"
                                                />
                                                <p
                                                  style={{
                                                    fontSize: "11px",
                                                    width: "200px",
                                                  }}
                                                >
                                                  Dr. M. Suresh Ph.D.,
                                                </p>
                                                <p
                                                  style={{
                                                    fontSize: "11px",
                                                    width: "200px",
                                                  }}
                                                >
                                                  Molecular Microbiologist
                                                </p>
                                                <br />
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>

                                    <tr className="ehdhe_9ikw">
                                      <td className="shshxhxs_secfooter">
                                        {!emailsent && (
                                          <div
                                            style={{
                                              height: "150px",
                                              width: "100%",
                                            }}
                                          ></div>
                                        )}

                                        {emailsent && (
                                          <>
                                            <div className="doctr_sign_fix7ccdddd_head">
                                              <div>
                                                <h4>Consultants</h4>
                                              </div>

                                              <div className="doctr_sign_fix7ccdddd">
                                                <div className="edhwud_oo">
                                                  <p
                                                    style={{
                                                      fontSize: "11px",
                                                      // width: "300px",
                                                    }}
                                                  >
                                                    Dr.C. APARNA DEVI M.D.,
                                                    D.M.,
                                                  </p>

                                                  <p
                                                    style={{
                                                      fontSize: "11px",
                                                      // width: "200px",
                                                    }}
                                                  >
                                                    TN's 1st Dedicated DM
                                                    Oncopathologist
                                                  </p>
                                                  <br />
                                                </div>

                                                <div className="edhwud_oo">
                                                  <p
                                                    style={{
                                                      fontSize: "11px",
                                                      // width: "300px",
                                                    }}
                                                  >
                                                    Dr.C. SURESH Ph.D.,
                                                    <br />
                                                    Diagnostic Molecular
                                                    Microbiologist
                                                  </p>

                                                  <p
                                                    style={{
                                                      fontSize: "11px",
                                                      // width: "200px",
                                                    }}
                                                  >
                                                    <h6>
                                                      Ranked as World's Top 2%
                                                      scientist for the year
                                                      <br />
                                                      2022 & 2023 by Stanford
                                                      University - USA{" "}
                                                    </h6>{" "}
                                                  </p>
                                                  <br />
                                                </div>

                                                <div className="edhwud_oo">
                                                  <p
                                                    style={{
                                                      fontSize: "11px",
                                                      // width: "300px",
                                                    }}
                                                  >
                                                    Mr.P.R. SURESH BABU M.Sc.,
                                                    <br />
                                                    Sr. Medical Biochemist
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                  </tfoot>
                                </div>
                              </div>
                            ))}
                        </>
                      </table>
                    );
                  } else if (!isNoRendered && dpt.culturetest !== "Yes") {
                    isNoRendered = true;
                    return (
                      <table
                        key={undex}
                        style={{
                          width: "81%",
                          position: "relative",
                          left: "90px",
                        }}
                      >
                        <div className="print-page3">
                          <div className="ewdwedcsdwe">
                            <>
                              {summa?.some((group) =>
                                group.tests.some((test) => {
                                  const CaptureValue = parseFloat(
                                    test.CaptureValue
                                  );
                                  const panichigh = parseFloat(test.panichigh);
                                  const paniclow = parseFloat(test.paniclow);

                                  return (
                                    CaptureValue >= panichigh ||
                                    CaptureValue <= paniclow
                                  );
                                })
                              ) && (
                                <>
                                  <div className="culture_test_header">
                                    <p>{dpt.Department} - Summary</p>
                                    {/* <p></p> */}
                                  </div>
                                  {/* <h4 style={{ textAlign: 'center' }}></h4> */}
                                  <br />
                                  <div className="table-container554">
                                    <table
                                      className="report_table report_table_for_micro hesyyd_p_Bicochemis0"
                                      style={{

                                      }}

                                    >
                                      <thead className="report_table_p99c">
                                        <tr>
                                          <th>Test Name</th>
                                          <th>Result</th>
                                          <th>Unit</th>
                                          <th>Biological Reference</th>
                                          <th>Method</th>
                                        </tr>
                                      </thead>
                                      <tbody   className="hesyyd_p0 report_table_p99c">
                                        {console.log(summa, "0987654321")}
                                        {summa
                                          ?.filter(
                                            (p) =>
                                              p.culturetest !== "Yes" &&
                                              p.Department !== "HISTOPATHOLOGY"
                                          )
                                          .map((group) => {
                                            const getStatus = (
                                              captureValue,
                                              referenceArray,
                                              filteredReference
                                            ) => {
                                              console.log(captureValue);
                                              console.log(referenceArray);

                                              let valueStyle = {};
                                              let panicLevel = null;

                                              if (referenceArray.length > 0) {
                                                for (let ref of referenceArray) {
                                                  if (ref.label === "Normal") {
                                                    // Normal range
                                                    if (
                                                      captureValue >=
                                                        ref.minValue &&
                                                      captureValue <=
                                                        ref.maxValue
                                                    ) {
                                                      return {
                                                        valueStyle: {},
                                                        panicLevel: null,
                                                      };
                                                    } else if (
                                                      captureValue <=
                                                      ref.minValue
                                                    ) {
                                                      return {
                                                        valueStyle: {
                                                          fontWeight: "bold",
                                                          fontSize: "15px",
                                                        },
                                                        panicLevel: (
                                                          <span className="indicator">
                                                            <TiArrowDownThick />
                                                          </span>
                                                        ),
                                                      };
                                                    }
                                                  } else if (
                                                    ref.label === "Borderline"
                                                  ) {
                                                    // Borderline range
                                                    if (
                                                      captureValue >=
                                                        ref.minValue &&
                                                      captureValue <=
                                                        ref.maxValue
                                                    ) {
                                                      return {
                                                        valueStyle: {
                                                          fontWeight: "bold",
                                                          fontSize: "15px",
                                                        },
                                                        panicLevel: (
                                                          <span className="indicator">
                                                            <TiArrowDownThick />
                                                          </span>
                                                        ),
                                                      };
                                                    }
                                                  } else if (
                                                    ref.label === "High Risk"
                                                  ) {
                                                    let highRiskThreshold =
                                                      null;
                                                    // Determine the high risk threshold
                                                    const minVal = ref.minValue;
                                                    const maxVal = ref.maxValue;

                                                    let minThreshold = null;
                                                    let maxThreshold = null;

                                                    // Parse minValue if it's a string with '>'
                                                    if (
                                                      typeof minVal ===
                                                        "string" &&
                                                      minVal.includes(">")
                                                    ) {
                                                      minThreshold = parseFloat(
                                                        minVal.split(">")[1]
                                                      );
                                                    } else if (
                                                      typeof minVal === "number"
                                                    ) {
                                                      minThreshold = minVal;
                                                    } else if (
                                                      typeof minVal ===
                                                        "string" &&
                                                      minVal.includes(">=")
                                                    ) {
                                                      minThreshold = parseFloat(
                                                        minVal.split(">=")[1]
                                                      );
                                                    }

                                                    // Parse maxValue if it's a string with '>'
                                                    if (
                                                      typeof maxVal ===
                                                        "string" &&
                                                      maxVal.includes(">")
                                                    ) {
                                                      maxThreshold = parseFloat(
                                                        maxVal.split(">")[1]
                                                      );
                                                    } else if (
                                                      typeof maxVal === "number"
                                                    ) {
                                                      maxThreshold = maxVal;
                                                    } else if (
                                                      typeof maxVal ===
                                                        "string" &&
                                                      maxVal.includes(">=")
                                                    ) {
                                                      maxThreshold = parseFloat(
                                                        maxVal.split(">=")[1]
                                                      );
                                                    }

                                                    // Set the highest threshold for high risk
                                                    if (
                                                      minThreshold !== null &&
                                                      maxThreshold !== null
                                                    ) {
                                                      highRiskThreshold =
                                                        Math.max(
                                                          minThreshold,
                                                          maxThreshold
                                                        );
                                                    } else if (
                                                      minThreshold !== null
                                                    ) {
                                                      highRiskThreshold =
                                                        minThreshold;
                                                    } else if (
                                                      maxThreshold !== null
                                                    ) {
                                                      highRiskThreshold =
                                                        maxThreshold;
                                                    }

                                                    // Check if captureValue is above the high risk threshold
                                                    if (
                                                      highRiskThreshold !==
                                                        null &&
                                                      captureValue >
                                                        highRiskThreshold
                                                    ) {
                                                      return {
                                                        valueStyle: {
                                                          fontWeight: "bold",
                                                          fontSize: "15px",
                                                        },
                                                        panicLevel: (
                                                          <span className="indicator">
                                                            <TiArrowUpThick />
                                                          </span>
                                                        ),
                                                      };
                                                    }
                                                  }
                                                }
                                              } else {
                                                const exceedsPanicHigh =
                                                  filteredReference.some(
                                                    (ref) =>
                                                      parseFloat(
                                                        captureValue
                                                      ) >=
                                                      parseFloat(ref.PanicHigh)
                                                  );
                                                const belowPanicLow =
                                                  filteredReference.some(
                                                    (ref) =>
                                                      parseFloat(
                                                        captureValue
                                                      ) <=
                                                      parseFloat(ref.Paniclow)
                                                  );
                                                // console.log('belowPanicLow', belowPanicLow);
                                                // console.log('exceedsPanicHigh', exceedsPanicHigh);
                                                // console.log('Test_Name', filteredReference[0].Test_Name);
                                                // console.log(typeof (filteredReference[0].captureValue));

                                                valueStyle = {
                                                  fontWeight:
                                                    exceedsPanicHigh ||
                                                    belowPanicLow
                                                      ? "bold"
                                                      : "normal",
                                                  fontSize: 15,
                                                };

                                                if (exceedsPanicHigh) {
                                                  panicLevel = (
                                                    <span>
                                                      <TiArrowUpThick />
                                                    </span>
                                                  );
                                                } else if (belowPanicLow) {
                                                  panicLevel = (
                                                    <span>
                                                      <TiArrowDownThick />
                                                    </span>
                                                  );
                                                }
                                              }
                                              return { valueStyle, panicLevel };
                                            };

                                            const parseReferenceData = (
                                              referenceData
                                            ) => {
                                              console.log(referenceData);
                                              return referenceData
                                                .split(",")
                                                .map((item) => {
                                                  const [label, value] = item
                                                    .split(":")
                                                    .map((str) => str.trim());
                                                  let minValue = null;
                                                  let maxValue = null;

                                                  if (value?.includes("<")) {
                                                    maxValue = parseFloat(
                                                      value.replace("<", "")
                                                    );
                                                  } else if (
                                                    value?.includes(">=")
                                                  ) {
                                                    minValue = parseFloat(
                                                      value.replace(">=", "")
                                                    );
                                                  } else if (
                                                    value?.includes("-")
                                                  ) {
                                                    [minValue, maxValue] = value
                                                      .split("-")
                                                      .map((str) =>
                                                        parseFloat(str.trim())
                                                      );
                                                  } else if (
                                                    value?.includes(">")
                                                  ) {
                                                    minValue = parseFloat(
                                                      value.replace(">", "")
                                                    );
                                                  }

                                                  return {
                                                    label,
                                                    minValue,
                                                    maxValue,
                                                  };
                                                });
                                            };

                                            const filteredTests =
                                              group?.tests.filter((test) => {
                                                const filteredReference =
                                                  group.reference.filter(
                                                    (p) =>
                                                      p.Test_Code ===
                                                      test.Test_Code
                                                  );
                                                const referenceArray =
                                                  filteredReference
                                                    .map((ref) => {
                                                      if (
                                                        ref.normal_range ===
                                                        "Yes"
                                                      ) {
                                                        return parseReferenceData(
                                                          ref.referenceranfe
                                                        );
                                                      }
                                                      return [];
                                                    })
                                                    .flat();

                                                const {
                                                  valueStyle,
                                                  panicLevel,
                                                } = getStatus(
                                                  test.CaptureValue,
                                                  referenceArray,
                                                  filteredReference
                                                );
                                                return (
                                                  panicLevel !== null ||
                                                  valueStyle.fontWeight ===
                                                    "bold"
                                                );
                                              });

                                            return filteredTests.map(
                                              (test, index) => {
                                                const filteredReference =
                                                  group.reference.filter(
                                                    (p) =>
                                                      p.Test_Code ===
                                                      test.Test_Code
                                                  );
                                                const referenceArray =
                                                  filteredReference
                                                    .map((ref) => {
                                                      if (
                                                        ref.normal_range ===
                                                        "Yes"
                                                      ) {
                                                        return parseReferenceData(
                                                          ref.referenceranfe
                                                        );
                                                      }
                                                      return [];
                                                    })
                                                    .flat();

                                                const {
                                                  valueStyle,
                                                  panicLevel,
                                                } = getStatus(
                                                  test.CaptureValue,
                                                  referenceArray,
                                                  filteredReference
                                                );

                                                return (
                                                  <React.Fragment key={index}>
                                                    {/* {group.method === "Group" && index === 0 && (
                                                  <tr>
                                                    <td colSpan={6}>
                                                      <p style={{ fontWeight: 600 }}>{dpt.Department}-{group.groupName}</p>
                                                    </td>
                                                  </tr>
                                                )} */}
                                                    <tr>
                                                      <td>
                                                        <div className="uyhfgr_gf">
                                                          <p>
                                                            {test.Test_Name}
                                                          </p>
                                                          {console.log(group,"783783783")}
                                                          <i
                                                          style={{
                                                            visibility: group.groupcode === "GM0019" ? "hidden" : "visible",
                                                          }}
                                                        >
                                                          Sample-{test.Sample}
                                                        </i>
                                                        </div>
                                                      </td>
                                                      <td style={valueStyle}>
                                                        {test.CaptureValue}
                                                      </td>
                                                      <td>
  {["nil", "negative", "-"].includes(test.CaptureValue.toLowerCase()) ? "" : test.UOM}
</td>
                                                      <td>
                                                        {group.reference
                                                          ?.filter(
                                                            (p) =>
                                                              p.Test_Code ===
                                                              test.Test_Code
                                                          )
                                                          .map(
                                                            (ref, refIndex) => (
                                                              <div
                                                                key={refIndex}
                                                              >
                                                                {ref.normal_range ===
                                                                "Yes" ? (
                                                                  <span>{`${ref.referenceranfe}`}</span>
                                                                ) : (
                                                                  <span>{`${ref.Paniclow}-${ref.PanicHigh}`}</span>
                                                                )}
                                                              </div>
                                                            )
                                                          )}
                                                      </td>
                                                      <td>
                                                        {formatMethodName(
                                                          test.Method_Name
                                                        )}
                                                      </td>
                                                    </tr>
                                                    {test.Head !== null &&
                                                      test.Detail !== null && (
                                                        <tr>
                                                          <td
                                                            colSpan={8}
                                                            style={{
                                                              textAlign:
                                                                "start",
                                                            }}
                                                          >
                                                            <h4>{test.Head}</h4>
                                                            <p>{test.Detail}</p>
                                                          </td>
                                                        </tr>
                                                      )}
                                                  </React.Fragment>
                                                );
                                              }
                                            );
                                          })}
                                      </tbody>

                       
                                    </table>
                                  </div>
                                </>
                              )}
                            </>
                            {/* <br /> */}
                            {/* {summa?.some(group => group.tests.some(test => { */}
                            <div className="culture_test_header">
                              <p>{dpt.Department} - Test Report</p>
                              {/* <p></p> */}
                            </div>
                            {/* }))} */}
                            {/* <br /> */}
                            <div className="table-container554">
                              <table className="report_table report_table_for_micro hesyyd_p_Bicochemis0">
                                <thead className="report_table_p99c">
                                  <tr>
                                    <th>Test Name</th>
                                    <th>Result</th>

                                    <th>Unit</th>

                                    <th>Biological Referencedd</th>
                                    <th>Method</th>
                                  </tr>
                                </thead>
                                <tbody className="hesyyd_p0 report_table_p99c">
                                  {console.log(summa, "1234567890")}
                                  {summa
                                    ?.filter(
                                      (p) =>
                                        p.culturetest !== "Yes" &&
                                        p.Department !== "HISTOPATHOLOGY"
                                    )
                                    .map((group) => {
                                      const getStatus = (
                                        captureValue,
                                        referenceArray,
                                        filteredReference
                                      ) => {
                                        console.log(captureValue);
                                        console.log(referenceArray);

                                        let valueStyle = {};
                                        let panicLevel = null;

                                        if (referenceArray.length > 0) {
                                          for (let ref of referenceArray) {
                                            if (ref.label === "Normal") {
                                              // Normal range
                                              if (
                                                captureValue >= ref.minValue &&
                                                captureValue <= ref.maxValue
                                              ) {
                                                return {
                                                  valueStyle: {},
                                                  panicLevel: (
                                                    <span
                                                      className="indicator"
                                                      style={{ color: "green" }}
                                                    ></span>
                                                  ),
                                                };
                                              } else if (
                                                captureValue <= ref.minValue
                                              ) {
                                                return {
                                                  valueStyle: {
                                                    fontWeight: "bold",
                                                    fontSize: "15px",
                                                  },
                                                  panicLevel: (
                                                    <span className="indicator">
                                                      <TiArrowDownThick />
                                                    </span>
                                                  ),
                                                };
                                              }
                                            } else if (
                                              ref.label === "Borderline"
                                            ) {
                                              // Borderline range
                                              if (
                                                captureValue >= ref.minValue &&
                                                captureValue <= ref.maxValue
                                              ) {
                                                return {
                                                  valueStyle: {
                                                    fontWeight: "bold",
                                                    fontSize: "15px",
                                                  },
                                                  panicLevel: (
                                                    <span className="indicator">
                                                      <TiArrowDownThick />
                                                    </span>
                                                  ),
                                                };
                                              }
                                            } else if (
                                              ref.label === "High Risk"
                                            ) {
                                              let highRiskThreshold = null;
                                              // Determine the high risk threshold
                                              const minVal = ref.minValue;
                                              const maxVal = ref.maxValue;

                                              let minThreshold = null;
                                              let maxThreshold = null;

                                              // Parse minValue if it's a string with '>'
                                              if (
                                                typeof minVal === "string" &&
                                                minVal.includes(">")
                                              ) {
                                                minThreshold = parseFloat(
                                                  minVal.split(">")[1]
                                                );
                                              } else if (
                                                typeof minVal === "number"
                                              ) {
                                                minThreshold = minVal;
                                              } else if (
                                                typeof minVal === "string" &&
                                                minVal.includes(">=")
                                              ) {
                                                minThreshold = parseFloat(
                                                  minVal.split(">=")[1]
                                                );
                                              }

                                              // Parse maxValue if it's a string with '>'
                                              if (
                                                typeof maxVal === "string" &&
                                                maxVal.includes(">")
                                              ) {
                                                maxThreshold = parseFloat(
                                                  maxVal.split(">")[1]
                                                );
                                              } else if (
                                                typeof maxVal === "number"
                                              ) {
                                                maxThreshold = maxVal;
                                              } else if (
                                                typeof maxVal === "string" &&
                                                maxVal.includes(">=")
                                              ) {
                                                maxThreshold = parseFloat(
                                                  maxVal.split(">=")[1]
                                                );
                                              }

                                              // Set the highest threshold for high risk
                                              if (
                                                minThreshold !== null &&
                                                maxThreshold !== null
                                              ) {
                                                highRiskThreshold = Math.max(
                                                  minThreshold,
                                                  maxThreshold
                                                );
                                              } else if (
                                                minThreshold !== null
                                              ) {
                                                highRiskThreshold =
                                                  minThreshold;
                                              } else if (
                                                maxThreshold !== null
                                              ) {
                                                highRiskThreshold =
                                                  maxThreshold;
                                              }

                                              // Check if captureValue is above the high risk threshold
                                              if (
                                                highRiskThreshold !== null &&
                                                captureValue > highRiskThreshold
                                              ) {
                                                return {
                                                  valueStyle: {
                                                    fontWeight: "bold",
                                                    fontSize: "15px",
                                                  },
                                                  panicLevel: (
                                                    <span className="indicator">
                                                      <TiArrowUpThick />
                                                    </span>
                                                  ),
                                                };
                                              }
                                            }
                                          }
                                        } else {
                                          const exceedsPanicHigh =
                                            filteredReference.some(
                                              (ref) =>
                                                parseFloat(captureValue) >=
                                                parseFloat(ref.PanicHigh)
                                            );
                                          const belowPanicLow =
                                            filteredReference.some(
                                              (ref) =>
                                                parseFloat(captureValue) <=
                                                parseFloat(ref.Paniclow)
                                            );
                                          // console.log('belowPanicLow', belowPanicLow);
                                          // console.log('exceedsPanicHigh', exceedsPanicHigh);
                                          // console.log('Test_Name', filteredReference[0].Test_Name);
                                          // console.log(typeof (filteredReference[0].captureValue));

                                          valueStyle = {
                                            fontWeight:
                                              exceedsPanicHigh || belowPanicLow
                                                ? "bold"
                                                : "normal",
                                            fontSize: 15,
                                          };

                                          if (exceedsPanicHigh) {
                                            panicLevel = (
                                              <span>
                                                <TiArrowUpThick />
                                              </span>
                                            );
                                          } else if (belowPanicLow) {
                                            panicLevel = (
                                              <span>
                                                <TiArrowDownThick />
                                              </span>
                                            );
                                          }
                                        }
                                        return { valueStyle, panicLevel };
                                      };

                                      const parseReferenceData = (
                                        referenceData
                                      ) => {
                                        console.log(referenceData);
                                        return referenceData
                                          .split(",")
                                          .map((item) => {
                                            const [label, value] = item
                                              .split(":")
                                              .map((str) => str.trim());
                                            let minValue = null;
                                            let maxValue = null;

                                            if (value?.includes("<")) {
                                              maxValue = parseFloat(
                                                value.replace("<", "")
                                              );
                                            } else if (value?.includes(">=")) {
                                              minValue = parseFloat(
                                                value.replace(">=", "")
                                              );
                                            } else if (value?.includes("-")) {
                                              [minValue, maxValue] = value
                                                .split("-")
                                                .map((str) =>
                                                  parseFloat(str.trim())
                                                );
                                            } else if (value?.includes(">")) {
                                              minValue = parseFloat(
                                                value.replace(">", "")
                                              );
                                            }

                                            return {
                                              label,
                                              minValue,
                                              maxValue,
                                            };
                                          });
                                      };

                                      return group?.tests.map((test, index) => {
                                        const filteredReference =
                                          group.reference.filter(
                                            (p) =>
                                              p.Test_Code === test.Test_Code
                                          );
                                        const referenceArray = filteredReference
                                          .map((ref) => {
                                            if (ref.normal_range === "Yes") {
                                              return parseReferenceData(
                                                ref.referenceranfe
                                              );
                                            }
                                            return [];
                                          })
                                          .flat();

                                        const { valueStyle, panicLevel } =
                                          getStatus(
                                            test.CaptureValue,
                                            referenceArray,
                                            filteredReference
                                          );

                                        return (
                                          <React.Fragment key={index}>
                                            {group.method === "Group" &&
                                              index === 0 && (
                                                <tr>
                                                  <td colSpan={6}>
                                                    <p
                                                      style={{
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      {group.Department}-
                                                      {group.groupName}
                                                    </p>
                                                  </td>
                                                </tr>
                                              )}
                                            <tr >
                                              <td>
                                                <div className="uyhfgr_gf">
                                                  {group.method === "Group" &&
                                                  index === 0 ? (
                                                    <>
                                                      <p>{test.Test_Name}</p>
                                                      {console.log(group,"783783783")}
                                                      <i
                                                      style={{
                                                        visibility: group.groupcode === "GM0019" || group.groupcode === "GM0024" || group.groupcode === "GM0004" ? "hidden" : "visible",
                                                      }}
                                                      
                                                    >
                                                      Sample-{test.Sample}
                                                    </i>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <p>{test.Test_Name}</p>
                                                      {console.log(group,"783783783")}
                                                      <i
                                                      style={{
                                                        visibility: group.groupcode === "GM0019"|| group.groupcode === "GM0024" || group.groupcode === "GM0004"  ? "hidden" : "visible",
                                                      }}
                                                    >
                                                      Sample-{test.Sample}
                                                    </i>
                                                    </>
                                                  )}
                                                </div>
                                              </td>
                                              <td style={valueStyle}>
                                                {test.CaptureValue}
                                              </td>
                                              <td>
  {["nil", "negative", "-"].includes(test.CaptureValue.toLowerCase()) ? "" : test.UOM}
</td>
                                              <td>
                                                {group.reference
                                                  ?.filter(
                                                    (p) =>
                                                      p.Test_Code ===
                                                      test.Test_Code
                                                  )
                                                  .map((ref, refIndex) => (
                                                    <div key={refIndex}>
                                                      {ref.normal_range ===
                                                      "Yes" ? (
                                                        <span>{`${ref.referenceranfe}`}</span>
                                                      ) : (
                                                        <span>{`${ref.Paniclow}-${ref.PanicHigh}`}</span>
                                                      )}
                                                    </div>
                                                  ))}
                                              </td>

                                              <td>
                                                {formatMethodName(
                                                  test.Method_Name
                                                )}
                                              </td>
                                            </tr>
                                            {test.Head !== null &&
                                              test.Detail !== null && (
                                                <tr>
                                                  <td
                                                    colSpan={8}
                                                    style={{
                                                      textAlign: "start",
                                                    }}
                                                  >
                                                    <h4>{test.Head}</h4>
                                                    <p>{test.Detail}</p>
                                                  </td>
                                                </tr>
                                              )}
                                          </React.Fragment>
                                        );
                                      });
                                    })}
                                  <div className="Add_items_Purchase_Master rtfdfr_kuj">
                                    <span> - End of Report - </span>
                                  </div>
                                </tbody>

                              
                              </table>
                            </div>
                          </div>
                        </div>

                        <tfoot className="print_footerr">
                                  <tr className="jjxcdsjjej_ ewdwe3redvv">
                                    <td className="footer_fix_conten_fyft microejeo">
                                      <div className="dcnnmjy0s66 biachemist_clicll">
                                        <div className="Register_btn_con_QRcode_fix">
                                          <img
                                            src={patinetbillingQr}
                                            alt="qrcode"
                                            style={{
                                              width: "80px",
                                              height: "80px",
                                              objectFit: "fill",
                                              borderBottom:'none',
                                            }}
                                          />
                                        </div>

                                        <div className="doctr_sign_fix7cc doctr_sign_fix7cc_secddg">
                                          {dpt.Department !==
                                            "MICROBIOLOGY" && (
                                            <>
                                              <img
                                                src={Docsign}
                                                alt=""
                                                className="docsign_fix"
                                              />
                                              <p
                                                style={{
                                                  fontSize: "11px",
                                                  width: "200px",
                                                }}
                                              >
                                                Dr.Aparna Devi C MD DM
                                              </p>
                                              <p
                                                style={{
                                                  fontSize: "11px",
                                                  width: "200px",
                                                }}
                                              >
                                                ONCOPATHOLOGIST
                                              </p>
                                              <p
                                                style={{
                                                  fontSize: "11px",
                                                  width: "200px",
                                                }}
                                              >
                                                Reg no - 112494
                                              </p>
                                              <br />
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr className="ehdhe_9ikw">
                                    <td className="shshxhxs_secfooter">
                                      {!emailsent && (
                                        <div
                                          style={{
                                            height: "150px",
                                            width: "100%",
                                          }}
                                        ></div>
                                      )}

                                      {emailsent && (
                                        <>
                                          <div className="doctr_sign_fix7ccdddd_head">
                                            <div>
                                              <h4>Consultants</h4>
                                            </div>

                                            <div className="doctr_sign_fix7ccdddd">
                                              <div className="edhwud_oo">
                                                <p
                                                  style={{
                                                    fontSize: "11px",
                                                    // width: "300px",
                                                  }}
                                                >
                                                  Dr.C. APARNA DEVI M.D., D.M.,
                                                </p>

                                                <p
                                                  style={{
                                                    fontSize: "11px",
                                                    // width: "200px",
                                                  }}
                                                >
                                                  TN's 1st Dedicated DM
                                                  Oncopathologist
                                                </p>
                                                
                                                <br />
                                              </div>

                                              <div className="edhwud_oo">
                                                <p
                                                  style={{
                                                    fontSize: "11px",
                                                    // width: "300px",
                                                  }}
                                                >
                                                  Dr.C. SURESH Ph.D.,
                                                  <br />
                                                  Diagnostic Molecular
                                                  Microbiologist
                                                </p>

                                                <p
                                                  style={{
                                                    fontSize: "11px",
                                                    // width: "200px",
                                                  }}
                                                >
                                                  <h6>
                                                    Ranked as World's Top 2%
                                                    scientist for the year
                                                    <br />
                                                    2022 & 2023 by Stanford
                                                    University - USA{" "}
                                                  </h6>{" "}
                                                </p>
                                                <br />
                                              </div>

                                              <div className="edhwud_oo">
                                                <p
                                                  style={{
                                                    fontSize: "11px",
                                                    // width: "300px",
                                                  }}
                                                >
                                                  Mr.P.R. SURESH BABU M.Sc.,
                                                  <br />
                                                  Sr. Medical Biochemist
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                </tfoot>
                      </table>
                    );
                  }
                } else if (dpt.Department === "HISTOPATHOLOGY") {
                  console.log("summa1", summa1);
                  return (
                    <>
                      {summa1
                        .filter((p) => p.Department === "HISTOPATHOLOGY")
                        .map((group, index) => (
                          <table className="report_table report_table_for_micro ">
                            <div className="report_table_oju7 new_report_table_oju7">
                              {formatData.length > 0 &&
                              formatData[0]?.format ? (
                                <h3>{formatHeading(formatData[0].format)}</h3>
                              ) : (
                                <h3>Report Details</h3>
                              )}

                              <br />

                              <table className="report_table_inner_p">
                                <tbody>
                                  {formatData.length > 0 ? (
                                    <>
                                      <div className="jdjd_8ji8">
                                        <div>
                                          {formatData
                                            .filter(
                                              (item) =>
                                                item.value !== null && // Ensure item.value is not null
                                                item.value !== undefined && // Ensure item.value is not undefined
                                                item.name // Ensure item.name exists
                                            ) // Ensure item.name exists
                                            .slice(0, 4) // Get the first four items
                                            .map((item, index) => (
                                              <tr key={index}>
                                                <h3>{item.format}</h3>
                                                <div className="ewhgdew_o">
                                                  <div>
                                                    <td>
                                                      <strong
                                                        style={{
                                                          fontSize:
                                                            item.name
                                                              .toLowerCase()
                                                              .includes(
                                                                "note"
                                                              ) &&
                                                            item.name
                                                              .toLowerCase()
                                                              .includes(
                                                                "disclaimer"
                                                              )
                                                              ? item.name
                                                                  .toLowerCase()
                                                                  .includes(
                                                                    "disclaimer"
                                                                  )
                                                                ? "10px" // Set font size to 10px for disclaimer
                                                                : "12px" // Set font size to 12px for note
                                                              : item.name.toLowerCase() ===
                                                                "note"
                                                              ? "12px"
                                                              : item.name.toLowerCase() ===
                                                                "disclaimer"
                                                              ? "10px" // Set font size to 10px for disclaimer
                                                              : "20px", // Default font size for other cases
                                                          color: "#333", // Optional: Apply consistent styling
                                                        }}
                                                      >
                                                        {formatName(item.name)}
                                                        <span>:</span>
                                                      </strong>
                                                    </td>
                                                  </div>
                                                  <div className="yudsd_4rdf dwexeh_i9">
                                                    <td>
                                                      {editHistoMode ? (
                                                        <div
                                                          contentEditable
                                                          suppressContentEditableWarning
                                                          onMouseUp={(e) =>
                                                            handleContentEditableInputChange(
                                                              e,
                                                              item.name
                                                            )
                                                          } // Track selection
                                                          style={{
                                                            border:
                                                              "1px solid #ccc",
                                                            padding: "5px",
                                                            minHeight: "30px",
                                                          }}
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              item.value || "",
                                                          }} // Set initial value
                                                        ></div>
                                                      ) : item.name
                                                          .toLowerCase()
                                                          .includes("note") &&
                                                        item.name
                                                          .toLowerCase()
                                                          .includes(
                                                            "disclaimer"
                                                          ) ? (
                                                        <p
                                                          style={{
                                                            fontSize: item.name
                                                              .toLowerCase()
                                                              .includes(
                                                                "disclaimer"
                                                              )
                                                              ? "10px"
                                                              : "12px", // Font size for combined case
                                                          }}
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              item.value || "",
                                                          }}
                                                        ></p>
                                                      ) : item.name.toLowerCase() ===
                                                        "note" ? (
                                                        <p
                                                          style={{
                                                            fontSize: "12px", // Font size for note
                                                          }}
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              item.value || "",
                                                          }}
                                                        ></p>
                                                      ) : item.name.toLowerCase() ===
                                                        "disclaimer" ? (
                                                        <p
                                                          style={{
                                                            fontSize: "10px", // Font size for disclaimer
                                                          }}
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              item.value || "",
                                                          }}
                                                        ></p>
                                                      ) : (
                                                        <p
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              item.value || "",
                                                          }}
                                                        ></p>
                                                      )}
                                                    </td>
                                                  </div>
                                                </div>
                                              </tr>
                                            ))}
                                        </div>

                                        <div>
                                          {formatData
                                            .filter(
                                              (item) =>
                                                item.name &&
                                                item.name
                                                  .toLowerCase()
                                                  .includes("imagedata") &&
                                                item.value !== null && // Ensure item.value is not null
                                                item.value !== undefined // Ensure item.value is not undefined
                                            )
                                            .map((item, index) => (
                                              <tr key={index + 4}>
                                                <div>
                                                  <div>
                                                    <td>
                                                      <div>
                                                      
                                                      
                                                        {imagePreview ? (
                                                        <img
                                                          src={imagePreview} // Show the new image preview
                                                          alt="Preview"
                                                          accept="image/*"
                                                          style={{
                                                            width: "222px",
                                                            height: "180px",
                                                            objectFit:
                                                              "contain",
                                                          }}
                                                        />
                                                      ) : item.value ? (
                                                        <img
                                                          src={item.value} // Show the existing image from the table (backend)
                                                          alt="Existing"
                                                          accept="image/*"
                                                          style={{
                                                            width: "222px",
                                                            height: "180px",
                                                            objectFit:
                                                              "contain",
                              }}
                            />
                          ) : null}
                                                        
                                                      </div>
                                                    </td>
                                                  </div>
                                                </div>
                                              </tr>
                                            ))}
                                        </div>
                                      </div>

                                      {formatData
                                        .filter(
                                          (item) =>
                                            item.value !== null && // Ensure item.value is not null
                                            item.value !== undefined && // Ensure item.value is not undefined
                                            item.name &&
                                            !item.name
                                              .toLowerCase()
                                              .includes("imagedata") // Exclude imagedata items
                                        )
                                        .slice(4) // Get the remaining items after the first four
                                        .map((item, index) => (
                                          <tr key={index + 4}>
                                            <div className="ewhgdew_o">
                                              <div>
                                                <td>
                                                  <strong
                                                    style={{
                                                      fontSize:
                                                        item.name
                                                          .toLowerCase()
                                                          .includes("note") &&
                                                        item.name
                                                          .toLowerCase()
                                                          .includes(
                                                            "disclaimer"
                                                          )
                                                          ? item.name
                                                              .toLowerCase()
                                                              .includes(
                                                                "disclaimer"
                                                              )
                                                            ? "10px" // Set font size to 10px for disclaimer
                                                            : "12px" // Set font size to 12px for note
                                                          : item.name.toLowerCase() ===
                                                            "note"
                                                          ? "12px"
                                                          : item.name.toLowerCase() ===
                                                            "disclaimer"
                                                          ? "10px" // Set font size to 10px for disclaimer
                                                          : "20px", // Default font size for other cases
                                                      color: "#333", // Optional: Apply consistent styling
                                                    }}
                                                  >
                                                    {formatName(item.name)}
                                                    <span>:</span>
                                                  </strong>
                                                </td>
                                              </div>
                                              <div className="yudsd_4rdf">
                                                <td>
                                                  {editHistoMode ? (
                                                    <div
                                                      contentEditable
                                                      suppressContentEditableWarning
                                                      onMouseUp={(e) =>
                                                        handleContentEditableInputChange(
                                                          e,
                                                          item.name
                                                        )
                                                      } // Track selection
                                                      style={{
                                                        border:
                                                          "1px solid #ccc",
                                                        padding: "5px",
                                                        minHeight: "30px",
                                                      }}
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          item.value || "",
                                                      }} // Set initial value
                                                    ></div>
                                                  ) : item.name
                                                      .toLowerCase()
                                                      .includes("note") &&
                                                    item.name
                                                      .toLowerCase()
                                                      .includes(
                                                        "disclaimer"
                                                      ) ? (
                                                    <p
                                                      style={{
                                                        fontSize: item.name
                                                          .toLowerCase()
                                                          .includes(
                                                            "disclaimer"
                                                          )
                                                          ? "10px"
                                                          : "12px", // Font size for combined case
                                                      }}
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          item.value || "",
                                                      }}
                                                    ></p>
                                                  ) : item.name.toLowerCase() ===
                                                    "note" ? (
                                                    <p
                                                      style={{
                                                        fontSize: "12px", // Font size for note
                                                      }}
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          item.value || "",
                                                      }}
                                                    ></p>
                                                  ) : item.name.toLowerCase() ===
                                                    "disclaimer" ? (
                                                    <p
                                                      style={{
                                                        fontSize: "10px", // Font size for disclaimer
                                                      }}
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          item.value || "",
                                                      }}
                                                    ></p>
                                                  ) : (
                                                    <p
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          item.value || "",
                                                      }}
                                                    ></p>
                                                  )}
                                                </td>
                                              </div>
                                            </div>
                                          </tr>
                                        ))}
                                    </>
                                  ) : (
                                    <tr>
                                      <td colSpan="2">
                                        No report data available
                                      </td>
                                    </tr>
                                  )}
                                </tbody>

                                <div className="Add_items_Purchase_Master">
                                  <span> - End of Report - </span>
                                </div>
                              </table>
                            </div>

                            <tfoot className="print_footerr">
                              <tr className="jjxcdsjjej_">
                                <td className="footer_fix_conten_fyft microejeo">
                                  <div className="dcnnmjy0s66">
                                    <div className="Register_btn_con_QRcode_fix">
                                      <img
                                        src={patinetbillingQr}
                                        alt="qrcode"
                                        style={{
                                          width: "80px",
                                          height: "80px",
                                          objectFit: "fill",
                                        }}
                                      />
                                      <div style={{width:'180px'}}>
                                        Processed / Typed By:{group.createdby}
                                      </div>
                                    </div>
                                  

                                    <div className="doctr_sign_fix7cc">
                                      {dpt.Department !== "MICROBIOLOGY" && (
                                        <>
                                          <img
                                            src={Docsign}
                                            alt=""
                                            className="docsign_fix"
                                          />
                                          <p
                                            style={{
                                              fontSize: "11px",
                                              width: "200px",
                                            }}
                                          >
                                            Dr.Aparna Devi C MD DM
                                          </p>
                                          <p
                                            style={{
                                              fontSize: "11px",
                                              width: "200px",
                                            }}
                                          >
                                            ONCOPATHOLOGIST
                                          </p>
                                          <p
                                                style={{
                                                  fontSize: "11px",
                                                  width: "200px",
                                                }}
                                              >
                                                Reg no - 112494
                                              </p>
                                          <br />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr className="ehdhe_9ikw">
                                <td className="shshxhxs_secfooter">
                                  {!emailsent && (
                                    <div
                                      style={{
                                        height: "150px",
                                        width: "100%",
                                      }}
                                    ></div>
                                  )}

                                  {emailsent && (
                                    <>
                                      <div className="doctr_sign_fix7ccdddd_head">
                                        <div>
                                          <h4>Consultants</h4>
                                        </div>

                                        <div className="doctr_sign_fix7ccdddd">
                                          <div className="edhwud_oo">
                                            <p
                                              style={{
                                                fontSize: "11px",
                                                // width: "300px",
                                              }}
                                            >
                                              Dr.C. APARNA DEVI M.D., D.M.,
                                            </p>

                                            <p
                                              style={{
                                                fontSize: "11px",
                                                // width: "200px",
                                              }}
                                            >
                                              TN's 1st Dedicated DM
                                              Oncopathologist
                                            </p>
                                            <br />
                                          </div>

                                          <div className="edhwud_oo">
                                            <p
                                              style={{
                                                fontSize: "11px",
                                                // width: "300px",
                                              }}
                                            >
                                              Dr.C. SURESH Ph.D.,
                                              <br />
                                              Diagnostic Molecular
                                              Microbiologist
                                            </p>

                                            <p
                                              style={{
                                                fontSize: "11px",
                                                // width: "200px",
                                              }}
                                            >
                                              <h6>
                                                Ranked as World's Top 2%
                                                scientist for the year
                                                <br />
                                                2022 & 2023 by Stanford
                                                University - USA{" "}
                                              </h6>{" "}
                                            </p>
                                            <br />
                                          </div>

                                          <div className="edhwud_oo">
                                            <p
                                              style={{
                                                fontSize: "11px",
                                                // width: "300px",
                                              }}
                                            >
                                              Mr.P.R. SURESH BABU M.Sc.,
                                              <br />
                                              Sr. Medical Biochemist
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        ))}
                    </>
                  );
                } else if (dpt.tests.some((p) => p.Test_Code === "RD0098")) {
                  {
                    console.log("poossijdijgoyijyyoykokfo");
                  }
                  return (
                    <>
                      {summa1
                        .filter((p) => p.Department === "HEMATOLOGY")
                        .map((group, index) => (
                          <table className="report_table report_table_for_micro ">
                            <div className="report_table_oju7 new_report_table_oju7">
                              {formatData.length > 0 &&
                              formatData[0]?.format ? (
                                <h3>{formatHeading(formatData[0].format)}</h3>
                              ) : (
                                <h3>Report Details</h3>
                              )}
                              {console.log("dssddssd", formatData)}
                              <br />

                              <table className="report_table_inner_p">
                                <tbody>
                                  {formatData.length > 0 ? (
                                    <>
                                      <div className="jdjd_8ji8">
                                        <div>
                                          {formatData
                                            .filter(
                                              (item) =>
                                                item.value !== null && // Ensure item.value is not null
                                                item.value !== undefined && // Ensure item.value is not undefined
                                                item.name // Ensure item.name exists
                                            ) // Ensure item.name exists
                                            .slice(0, 4) // Get the first four items
                                            .map((item, index) => (
                                              <tr key={index}>
                                                <h3>{item.format}</h3>
                                                <div className="ewhgdew_o">
                                                  <div>
                                                    <td>
                                                      <strong
                                                        style={{
                                                          fontSize:
                                                            item.name
                                                              .toLowerCase()
                                                              .includes(
                                                                "note"
                                                              ) &&
                                                            item.name
                                                              .toLowerCase()
                                                              .includes(
                                                                "disclaimer"
                                                              )
                                                              ? item.name
                                                                  .toLowerCase()
                                                                  .includes(
                                                                    "disclaimer"
                                                                  )
                                                                ? "10px" // Set font size to 10px for disclaimer
                                                                : "12px" // Set font size to 12px for note
                                                              : item.name.toLowerCase() ===
                                                                "note"
                                                              ? "12px"
                                                              : item.name.toLowerCase() ===
                                                                "disclaimer"
                                                              ? "10px" // Set font size to 10px for disclaimer
                                                              : "20px", // Default font size for other cases
                                                          color: "#333", // Optional: Apply consistent styling
                                                        }}
                                                      >
                                                        {formatName(item.name)}
                                                        <span>:</span>
                                                      </strong>
                                                    </td>
                                                  </div>
                                                  <div className="yudsd_4rdf dwexeh_i9">
                                                    <td>
                                                      {editHistoMode ? (
                                                        <div
                                                          contentEditable
                                                          suppressContentEditableWarning
                                                          onMouseUp={(e) =>
                                                            handleContentEditableInputChange(
                                                              e,
                                                              item.name
                                                            )
                                                          } // Track selection
                                                          style={{
                                                            border:
                                                              "1px solid #ccc",
                                                            padding: "5px",
                                                            minHeight: "30px",
                                                          }}
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              item.value || "",
                                                          }} // Set initial value
                                                        ></div>
                                                      ) : item.name
                                                          .toLowerCase()
                                                          .includes("note") &&
                                                        item.name
                                                          .toLowerCase()
                                                          .includes(
                                                            "disclaimer"
                                                          ) ? (
                                                        <p
                                                          style={{
                                                            fontSize: item.name
                                                              .toLowerCase()
                                                              .includes(
                                                                "disclaimer"
                                                              )
                                                              ? "10px"
                                                              : "12px", // Font size for combined case
                                                          }}
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              item.value || "",
                                                          }}
                                                        ></p>
                                                      ) : item.name.toLowerCase() ===
                                                        "note" ? (
                                                        <p
                                                          style={{
                                                            fontSize: "12px", // Font size for note
                                                          }}
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              item.value || "",
                                                          }}
                                                        ></p>
                                                      ) : item.name.toLowerCase() ===
                                                        "disclaimer" ? (
                                                        <p
                                                          style={{
                                                            fontSize: "10px", // Font size for disclaimer
                                                          }}
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              item.value || "",
                                                          }}
                                                        ></p>
                                                      ) : (
                                                        <p
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              item.value || "",
                                                          }}
                                                        ></p>
                                                      )}
                                                    </td>
                                                  </div>
                                                </div>
                                              </tr>
                                            ))}
                                        </div>

                                        <div>
                                          {formatData
                                            .filter(
                                              (item) =>
                                                item.name &&
                                                item.name
                                                  .toLowerCase()
                                                  .includes("imagedata") &&
                                                item.value !== null && // Ensure item.value is not null
                                                item.value !== undefined // Ensure item.value is not undefined
                                            )
                                            .map((item, index) => (
                                              <tr key={index + 4}>
                                                <div>
                                                  <div>
                                                    <td>
                                                      <div>
                                                        <img
                                                          src={item.value}
                                                          alt="Image data"
                                                          style={{
                                                            width: "222px",
                                                            height: "180px",
                                                            objectFit:
                                                              "contain",
                                                          }}
                                                        />
                                                      </div>
                                                    </td>
                                                  </div>
                                                </div>
                                              </tr>
                                            ))}
                                        </div>
                                      </div>

                                      {formatData
                                        .filter(
                                          (item) =>
                                            item.value !== null && // Ensure item.value is not null
                                            item.value !== undefined && // Ensure item.value is not undefined
                                            item.name &&
                                            !item.name
                                              .toLowerCase()
                                              .includes("imagedata") // Exclude imagedata items
                                        )
                                        .slice(4) // Get the remaining items after the first four
                                        .map((item, index) => (
                                          <tr key={index + 4}>
                                            <div className="ewhgdew_o">
                                              <div>
                                                <td>
                                                  <strong
                                                    style={{
                                                      fontSize:
                                                        item.name
                                                          .toLowerCase()
                                                          .includes("note") &&
                                                        item.name
                                                          .toLowerCase()
                                                          .includes(
                                                            "disclaimer"
                                                          )
                                                          ? item.name
                                                              .toLowerCase()
                                                              .includes(
                                                                "disclaimer"
                                                              )
                                                            ? "10px" // Set font size to 10px for disclaimer
                                                            : "12px" // Set font size to 12px for note
                                                          : item.name.toLowerCase() ===
                                                            "note"
                                                          ? "12px"
                                                          : item.name.toLowerCase() ===
                                                            "disclaimer"
                                                          ? "10px" // Set font size to 10px for disclaimer
                                                          : "20px", // Default font size for other cases
                                                      color: "#333", // Optional: Apply consistent styling
                                                    }}
                                                  >
                                                    {formatName(item.name)}
                                                    <span>:</span>
                                                  </strong>
                                                </td>
                                              </div>
                                              <div className="yudsd_4rdf">
                                                <td>
                                                  {editHistoMode ? (
                                                    <div
                                                      contentEditable
                                                      suppressContentEditableWarning
                                                      onMouseUp={(e) =>
                                                        handleContentEditableInputChange(
                                                          e,
                                                          item.name
                                                        )
                                                      } // Track selection
                                                      style={{
                                                        border:
                                                          "1px solid #ccc",
                                                        padding: "5px",
                                                        minHeight: "30px",
                                                      }}
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          item.value || "",
                                                      }} // Set initial value
                                                    ></div>
                                                  ) : item.name
                                                      .toLowerCase()
                                                      .includes("note") &&
                                                    item.name
                                                      .toLowerCase()
                                                      .includes(
                                                        "disclaimer"
                                                      ) ? (
                                                    <p
                                                      style={{
                                                        fontSize: item.name
                                                          .toLowerCase()
                                                          .includes(
                                                            "disclaimer"
                                                          )
                                                          ? "10px"
                                                          : "12px", // Font size for combined case
                                                      }}
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          item.value || "",
                                                      }}
                                                    ></p>
                                                  ) : item.name.toLowerCase() ===
                                                    "note" ? (
                                                    <p
                                                      style={{
                                                        fontSize: "12px", // Font size for note
                                                      }}
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          item.value || "",
                                                      }}
                                                    ></p>
                                                  ) : item.name.toLowerCase() ===
                                                    "disclaimer" ? (
                                                    <p
                                                      style={{
                                                        fontSize: "10px", // Font size for disclaimer
                                                      }}
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          item.value || "",
                                                      }}
                                                    ></p>
                                                  ) : (
                                                    <p
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          item.value || "",
                                                      }}
                                                    ></p>
                                                  )}
                                                </td>
                                              </div>
                                            </div>
                                          </tr>
                                        ))}
                                    </>
                                  ) : (
                                    <tr>
                                      <td colSpan="2">
                                        No report data available
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                                <div className="Add_items_Purchase_Master">
                                  <span> - End of Report - </span>
                                </div>
                              </table>
                            </div>

                            <tfoot className="print_footerr">
                              <tr className="jjxcdsjjej_">
                                <td className="footer_fix_conten_fyft microejeo">
                                  <div className="dcnnmjy0s66">
                                    <div className="Register_btn_con_QRcode_fix">
                                      <img
                                        src={patinetbillingQr}
                                        alt="qrcode"
                                        style={{
                                          width: "80px",
                                          height: "80px",
                                          objectFit: "fill",
                                        }}
                                      />
                                    </div>

                                    <div className="doctr_sign_fix7cc">
                                      {dpt.Department !== "MICROBIOLOGY" && (
                                        <>
                                          <img
                                            src={Docsign}
                                            alt=""
                                            className="docsign_fix"
                                          />
                                          <p
                                            style={{
                                              fontSize: "11px",
                                              width: "200px",
                                            }}
                                          >
                                            Dr.Aparna Devi C MD DM
                                          </p>
                                          <p
                                            style={{
                                              fontSize: "11px",
                                              width: "200px",
                                            }}
                                          >
                                            ONCOPATHOLOGIST
                                          </p>
                                          <p
                                                style={{
                                                  fontSize: "11px",
                                                  width: "200px",
                                                }}
                                              >
                                                Reg no - 112494
                                              </p>
                                          <br />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </td>
                              </tr>

                              <tr className="ehdhe_9ikw">
                                <td className="shshxhxs_secfooter">
                                  {!emailsent && (
                                    <div
                                      style={{
                                        height: "150px",
                                        width: "100%",
                                      }}
                                    ></div>
                                  )}

                                  {emailsent && (
                                    <>
                                      <div className="doctr_sign_fix7ccdddd_head">
                                        <div>
                                          <h4>Consultants</h4>
                                        </div>

                                        <div className="doctr_sign_fix7ccdddd">
                                          <div className="edhwud_oo">
                                            <p
                                              style={{
                                                fontSize: "11px",
                                                // width: "300px",
                                              }}
                                            >
                                              Dr.C. APARNA DEVI M.D., D.M.,
                                            </p>

                                            <p
                                              style={{
                                                fontSize: "11px",
                                                // width: "200px",
                                              }}
                                            >
                                              TN's 1st Dedicated DM
                                              Oncopathologist
                                            </p>
                                            <br />
                                          </div>

                                          <div className="edhwud_oo">
                                            <p
                                              style={{
                                                fontSize: "11px",
                                                // width: "300px",
                                              }}
                                            >
                                              Dr.C. SURESH Ph.D.,
                                              <br />
                                              Diagnostic Molecular
                                              Microbiologist
                                            </p>

                                            <p
                                              style={{
                                                fontSize: "11px",
                                                // width: "200px",
                                              }}
                                            >
                                              <h6>
                                                Ranked as World's Top 2%
                                                scientist for the year
                                                <br />
                                                2022 & 2023 by Stanford
                                                University - USA{" "}
                                              </h6>{" "}
                                            </p>
                                            <br />
                                          </div>

                                          <div className="edhwud_oo">
                                            <p
                                              style={{
                                                fontSize: "11px",
                                                // width: "300px",
                                              }}
                                            >
                                              Mr.P.R. SURESH BABU M.Sc.,
                                              <br />
                                              Sr. Medical Biochemist
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        ))}
                    </>
                  );
                }

                return null;
              })}
            </tbody>
          </table>
        )}

        {openmodels && (
          <div
            className={
              isSidebarOpen
                ? "sideopen_showcamera_profile"
                : "showcamera_profile"
            }
            onClick={() => {
              setopenmodels(false);
            }}
          >
            <div
              className="newwProfiles newwPopupforreason"
              onClick={(e) => e.stopPropagation()}
            >
              <div ref={contentRef} className="editor_life">
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  data={content}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setContent(data);
                  }}
                />

                {/* {!showdown && <div className="Register_btn_con">
              <button className="RegisterForm_1_btns" onClick={handleSubmitAnalyseReport}>
                Save
              </button>
            </div>} */}
              </div>

              <div className="Register_btn_con regster_btn_contsai">
                <button
                  className="RegisterForm_1_btns"
                  onClick={() => setopenmodels(false)}
                >
                  Close
                </button>
                {/* <button className="RegisterForm_1_btns" onClick={handleSavedoc}>
                                Save
                            </button> */}
              </div>
            </div>
          </div>
        )}

{showmodel && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setshowmodel(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="grid_1">
              <ThemeProvider theme={theme}>
                <DataGrid
                  rows={culturevalue.slice(
                    page * pageSize,
                    (page + 1) * pageSize
                  )}
                  columns={dynamicColumns}
                  pageSize={pageSize}
                  pageSizeOptions={[pageSize]}
                  onPageChange={(newPage) => setPage(newPage)}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  className="data_grid"
                />
                {showdown > 0 && culturevalue.length > pageSize && (
                  <div className="grid_foot">
                    <button
                      onClick={() =>
                        setPage((prevPage) => Math.max(prevPage - 1, 0))
                      }
                      disabled={page === 0}
                    >
                      Previous
                    </button>
                    Page {page + 1} of {totalPages}
                    <button
                      onClick={() =>
                        setPage((prevPage) =>
                          Math.min(prevPage + 1, totalPages - 1)
                        )
                      }
                      disabled={page === totalPages - 1}
                    >
                      Next
                    </button>
                  </div>
                )}
              </ThemeProvider>
              <br />
              
              {culturevalue.length === 0 && (
                <div className="IP_norecords">
                  <span>No Records Found</span>
                </div>
              )}
            </div>

            <div className="Register_btn_con regster_btn_contsai">
              <button
                className="RegisterForm_1_btns"
                onClick={() => setshowmodel(false)}
              >
                Close
              </button>
              <button
                className="RegisterForm_1_btns"
                onClick={editMode ? handleupdatesencitivity :'' }
              >
              {editMode?'Update' : 'Edit'  }  
              </button>
            </div>
          </div>
        </div>
      )}
        {summa.length === 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px",
              marginTop: "15px",
            }}
          >
            <p style={{ color: "red" }}> Your Report is not Ready Now</p>

            <button
              className="RegisterForm_1_btns  Rhugt_report"
              onClick={() => navigate("/Home/DispatchEntryList")}
            >
              Back
            </button>
          </div>
        )}
      </div>
    );
  }
);

function Reports() {
  const componentRef = useRef();

  const touppercasefun = (value) => {
    return value?.toUpperCase();
  };

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log(userRecord, "record");
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const SignPerson = useSelector((state) => state.userRecord?.SignPerson);
  const printpapaer = useSelector((state) => state.userRecord?.printpapaer);
  const [microandhistonumber, setmicroandhistonumber] = useState({});
  let isNoRendered = false;
  const [Dispatch, setdispatch] = useState("");
  const [summa, setsumma] = useState([]);
  const [summa1, setsumma1] = useState([]);
  const [formatData, setFormatData] = useState([]);
  const [content, setContent] = useState("");
  const [showmodel, setshowmodel] = useState(false);
  const [culturevalue, setculturevalue] = useState([]);
  const [editmicrodata, seteditmicrodata] = useState({
    microscopy: "",
    culturereport: "",
    reportstatus: "",
    notes: "",
    bacteriaisolated :"",
  });

  const [cancelReason, setCancelReason] = useState("");
  // const [selectedId, setselectedId] = useState(null);
  const [trues, settrues] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [patinetbillingbarcode, setpatinetbillingbarcode] = useState();
  const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);
  console.log("jhdahjadjadjjajhsdjhsd", capturedatas);
  const [editMode, setEditMode] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [page, setPage] = useState(0);
  const [show, setshow] = useState(false);
  const navigate = useNavigate();
  const pageSize = 10;
  const totalPages = Math.ceil(culturevalue.length / pageSize);
  const showdown = culturevalue.length;
  const [patinetbillingQr, setpatinetbillingQr] = useState("");
  const [editmicromode, setmicromode] = useState(false);
  const [emailsent, setemailsent] = useState(false);
  const [openmodels, setopenmodels] = useState(false);
  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: "",
    ClinicGST: "",
    ClinicAddress: "",
    ClinicCity: "",
    ClinicState: "",
    ClinicCode: "",
    ClinicMobileNo: "",
    ClinicLandLineNo: "",
    ClinicMailID: "",
    InvoiceNo: "",
  });
  const contentRef = useRef(null);
  const [report, setreport] = useState({
    Collected: "",
    Received: "",
    Reported: "",
    Head: "",
    Detail: "",
  });

  const handleCheckboxChange = (e) => {
    setemailsent(e.target.value === "yes");
  };
  const formatHeading = (format) => {
    const formatMap = {
      histopathologySecondOpinion: "Histopathology (Second Opinion)",
      peripheralSmear: "PeripheralSmear (PS)",
      lbc: "LBC",
      biopsy: "Biopsy",
      fnac: "FNAC",
      pathologyReport: "Pathology Report",
    };

    return formatMap[format] || format; // Fallback to original format if not found
  };

  // const [pageChunks, setPageChunks] = useState([]);

  // useEffect(() => {
  //   const calculateChunks = () => {
  //     const pageHeight = 1120; // Example page height in pixels (e.g., for A4 paper)
  //     const headerHeight = 300; // Example header height in pixels
  //     const footerHeight = 200; // Example footer height in pixels
  //     const rowHeight = 20; // Example row height in pixels

  //     const bodyHeight = pageHeight - headerHeight - footerHeight;
  //     // const rowsPerPage = Math.floor(bodyHeight / rowHeight);

  //     // const pages = chunkArray(summa, rowsPerPage);
  //     // setPageChunks(pages);
  //   };

  //   calculateChunks();
  //   window.addEventListener("resize", calculateChunks);

  //   return () => {
  //     window.removeEventListener("resize", calculateChunks);
  //   };
  // }, [summa]);


  const handleshowculturetest = () => {
    axios
      .get(
        `${urllink}Phelobotomist/get_for_culture_report_completion?Billinginvoice=${capturedatas?.Billing_Invoice}&Visitid=${capturedatas?.Visit_Id}`
      )
      .then((response) => {
        console.log(response);
        const data = response.data.map((p, index) => ({
          id: index + 1,
          ...p,
        }));
        setculturevalue(data);
        setshowmodel(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .get(
        `${urllink}Phelobotomist/get_for_preview_examinations_dispatch?Visitid=${capturedatas?.Visit_Id}&patientid=${capturedatas.Patient_Id}&Billinginvoice=${capturedatas?.Billing_Invoice}`
      )
      .then((response) => {
        console.log(response, "1234567890");

        const groupsData = response.data;
        let latestCollectedDate = new Date(0);
        let latestReceivedDate = new Date(0);
        let latestReportedDate = new Date(0);

        const parseDate = (dateString) => {
          const date = new Date(dateString);
          return isNaN(date.getTime()) ? new Date() : date; // Default to current date if parsing fails
        };

        groupsData.forEach((group) => {
          const analyseResultDate = parseDate(
            group.Updated_At.AnalyseInvestigationResult
          );
          const analyseSeniorDate =
            group.Updated_At.AnalyseInvestigationSenior !== "N/A"
              ? parseDate(group.Updated_At.AnalyseInvestigationSenior)
              : new Date();
          const approvePathologistDate = parseDate(
            group.Updated_At.Approve_Test_Pathologist
          );

          if (analyseResultDate > latestCollectedDate) {
            latestCollectedDate = analyseResultDate;
          }

          if (analyseSeniorDate > latestReceivedDate) {
            latestReceivedDate = analyseSeniorDate;
          }

          if (approvePathologistDate > latestReportedDate) {
            latestReportedDate = approvePathologistDate;
          }
        });

        const formatDateAndTime = (date) => {
          let d = new Date(date),
            day = "" + d.getDate(),
            month = "" + (d.getMonth() + 1),
            year = d.getFullYear(),
            hours = "" + d.getHours(),
            minutes = "" + d.getMinutes();

          if (day.length < 2) day = "0" + day;
          if (month.length < 2) month = "0" + month;
          if (hours.length < 2) hours = "0" + hours;
          if (minutes.length < 2) minutes = "0" + minutes;

          return (
            [day, month, year].join("/") + " " + [hours, minutes].join(":")
          );
        };

        setreport((prev) => ({
          ...prev,
          Collected: formatDateAndTime(latestCollectedDate),
          Received: formatDateAndTime(latestReceivedDate),
          Reported: formatDateAndTime(latestReportedDate),
        }));

        const reportsData = groupsData.map((group) => {
          return {
            method: group.Method,
            Department: group.Department,
            groupName: group.Group_Name,
            createdby: group.CreatedBy,
            Tests: group.Tests.map((test) => ({
              Test_Name: test.Test_Name || "",
              Method_Name: test.Method_Name || "",
              Department: test.Department || "",
              Sample: test.Specimen || "",
              Content: test.EditContent || "",
              Samplepicture: test.Samplepic
                ? `data:image/jpeg;base64,${test.Samplepic}`
                : null,
            })),
          };
        });

        setsumma1(reportsData);
      })
      .catch((error) => {
        console.error(
          "Error fetching data:",
          error.response || error.message || error
        );
      });
  }, [capturedatas, urllink, editmicrodata]);

  const autoResizeTextarea = (e) => {
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set it to the scroll height
  };

  useEffect(() => {
    axios
      .get(
        `${urllink}Billing/getMicro_and_HistoNumber?Invoice=${capturedatas?.Billing_Invoice}`
      )
      .then((res) => {
        console.log(res);
        setmicroandhistonumber(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [urllink, capturedatas]);

  useEffect(() => {
    axios
      .get(
        `${urllink}Phelobotomist/get_for_dispatch_report_status?invoice=${capturedatas?.Billing_Invoice}`
      )
      .then((response) => {
        setdispatch(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    if (capturedatas) {
      const barval = `${urllink}Billing/get_report?inv=${capturedatas?.Barcode}`;
      axios
        .get(`${urllink}Phelobotomist/get_qrcode_image?qrValue=${barval}`)
        .then((res) => {
          const datass = res.data.qrImage;
          setpatinetbillingQr(datass);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [urllink, capturedatas, trues]);

  // const innerHeightOfPDF = getInnerHeightOfPDF();
  const getUniqueAntibiotics = (sensitiveArray) => {
    const antibioticMap = new Map();
    sensitiveArray?.forEach(({ antibiotic, sensitivetype, Organism }) => {
      if (!antibioticMap.has(antibiotic)) {
        antibioticMap.set(antibiotic, { antibiotic, organisms: {} });
      }
      if (!antibioticMap.get(antibiotic).organisms[Organism]) {
        antibioticMap.get(antibiotic).organisms[Organism] = sensitivetype;
      }
    });
    return Array.from(antibioticMap.values());
  };

  const getAllUniqueOrganisms = (groups) => {
    const organismSet = new Set();
    groups.forEach((group) => {
      group.Sensitive.forEach((item) => {
        Object.keys(item.organisms).forEach((organism) => {
          organismSet.add(organism);
        });
      });
    });
    return Array.from(organismSet);
  };

  const getSensitivityColor = (sensitivetype) => {
    switch (sensitivetype) {
      case "Sensitive":
        return "green";
      case "Intermediate":
        return "blue";
      case "Resistant":
        return "red";
      default:
        return "black";
    }
  };

  const uniqueOrganisms = getAllUniqueOrganisms(summa);

  const getAlphabetHeaders = (length) => {
    const alphabets = [];
    for (let i = 0; i < length; i++) {
      alphabets.push(String.fromCharCode(65 + i));
    }
    return alphabets;
  };

  const alphabetHeaders = getAlphabetHeaders(uniqueOrganisms.length);

  console.log("summa", summa);
  // const uniqueOrganisms = [...new Set(summa[0]?.Sensitive.map(item => item.oraganism))];
  console.log("uniqueOrganisms", uniqueOrganisms);

  console.log(show);

  const dynamicColumns = [
    { field: "id", headerName: "S.No", width: 150 },
    { field: "antibiotic", headerName: "Antibiotic Name", width: 150 },
    { 
      field: "Sensitivetype", 
      headerName: "Sensitive Type", 
      width: 150,
      renderCell: (params) => (
        editMode ? (
          <Select
            value={params.row.Sensitivetype}
            onChange={(e) => handleSensitivityChange(params.row.id, e.target.value)}
            fullWidth
          >
            <MenuItem value="Sensitive">Sensitive</MenuItem>
            <MenuItem value="Intermediate">Intermediate</MenuItem>
            <MenuItem value="Resistant">Resistant</MenuItem>
          </Select>
        ) : (
          params.row.Sensitivetype
        )
      ),
    },
    { field: "Oraganism", headerName: "Organism", width: 150 },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${urllink}Phelobotomist/getdataforreport?Billing_Invoice=${capturedatas.Billing_Invoice}&gender=${capturedatas?.Gender}&age=${capturedatas?.Age}&timeperiod=${capturedatas?.Time_Period}`
        );
        const groupsData = response.data;
        console.log("groupsData", groupsData);

        let latestCollectedDate = new Date(0);
        let latestReceivedDate = new Date(0);
        let latestReportedDate = new Date(0);

        const parseDate = (dateString) => {
          const date = new Date(dateString);
          return isNaN(date.getTime()) ? new Date() : date; // Default to current date if parsing fails
        };

        groupsData.forEach((group) => {
          const analyseResultDate = parseDate(
            group.Updated_At.AnalyseInvestigationResult
          );
          const analyseSeniorDate =
            group.Updated_At.AnalyseInvestigationSenior !== "N/A"
              ? parseDate(group.Updated_At.AnalyseInvestigationSenior)
              : new Date();
          const approvePathologistDate = parseDate(
            group.Updated_At.Approve_Test_Pathologist
          );

          if (analyseResultDate > latestCollectedDate) {
            latestCollectedDate = analyseResultDate;
          }

          if (analyseSeniorDate > latestReceivedDate) {
            latestReceivedDate = analyseSeniorDate;
          }

          if (approvePathologistDate > latestReportedDate) {
            latestReportedDate = approvePathologistDate;
          }
        });

        const formatDateAndTime = (date) => {
          let d = new Date(date),
            day = "" + d.getDate(),
            month = "" + (d.getMonth() + 1),
            year = d.getFullYear(),
            hours = "" + d.getHours(),
            minutes = "" + d.getMinutes();

          if (day.length < 2) day = "0" + day;
          if (month.length < 2) month = "0" + month;
          if (hours.length < 2) hours = "0" + hours;
          if (minutes.length < 2) minutes = "0" + minutes;

          return (
            [day, month, year].join("/") + " " + [hours, minutes].join(":")
          );
        };

        setreport((prev) => ({
          ...prev,
          Collected: formatDateAndTime(latestCollectedDate),
          Received: formatDateAndTime(latestReceivedDate),
          Reported: formatDateAndTime(latestReportedDate),
        }));

        const reportsData = groupsData.map((group) => ({
          method: group.Method,
          Department: group.Department,
          groupName: group.Group_Name,
          groupcode:group.Group_Code,
          culturetest: group.Culturetest,
          Display_Text: group?.Display_Text,
          tests: group.Tests.map((test) => ({
            Test_Name: test.Test_Name || "",
            CaptureValue: test.CaptureValue || "",
            UOM: test.UOM || "",
            Method_Name: test.Method_Name || "",
            Department: test.Department || "",
            Sample: test.Sepcimen || "",
            colony_count: test.Colony_Count || "",
            Capture_Organism: test.Capture_Organism || "",
            Notes: test.Notes || "",
            Microscopy_Data: test.Microscopy_Data || "",
            Culture_Report: test.Culture_Report || "",
            Report_Type: test.Report_Type || "",
            Test_Code: test.Test_Code || "",
            Report_Status: test.Report_Status || "",
            micronumber: test.micronumber || "",
            Bacteria_Isolated: test.Bacteria_Isolated|| "",
            Head:
              group.Method === "Group"
                ? test.Head
                : group.Interpretations
                ? group.Interpretations.Head
                : "",
            Detail:
              group.Method === "Group"
                ? test.Detail
                : group.Interpretations
                ? group.Interpretations.Detail
                : "",
            paniclow: test.Paniclow,
            panichigh: test.Panichigh,
          })),
          reference: group.ReferenceRange?.map((test) => ({
            From_Value: test.From_Value,
            To_Value: test.To_Value,
            gender: test.Gender,
            Paniclow: test.PanicLow,
            PanicHigh: test.PanicHigh,
            Test_Name: test.TestName,
            Test_Code: test.Test_Code,
            normal_range: test.NormalValue,
            referenceranfe: test.Reference_Range,
            CaptureValue: test.CaptureValue || "",
          })),

          Sensitive: getUniqueAntibiotics(group.Sensitive),
        }));

        setsumma(reportsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [capturedatas, urllink, editMode]);

  const [PdfBlob, setPdfBlob] = useState(null);
  console.log(PdfBlob);
  useEffect(() => {
    if (Object.keys(capturedatas).length === 0) {
      navigate("/Home/DispatchEntryList");
    }
  }, [capturedatas, navigate]);

  const handleeditchange = (e) => {
    const { name, value } = e.target;
    seteditmicrodata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getClinic?location=${userRecord?.location}`)
      .then((response) => {
        const data = response.data[0];
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicAddress: data.doorNo + "," + data.area + "," + data.street,
            ClinicGST: data.gstno,
            ClinicCity: data.city,
            ClinicState: data.state,
            ClinicCode: data.pincode,
            ClinicMobileNo: data.phoneNo,
            ClinicLandLineNo: data.landline,
            ClinicMailID: data.email,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        const data = response.data;
        console.log(data, "uiiieueeieiei");
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicName: data.clinicName,
            ClinicLogo: `data:image/png;base64,${data.clinicLogo}`,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(
        `${urllink}Billing/get_billing_patient_barcode?Patientid=${capturedatas?.Patient_Id}&Patientname=${capturedatas.Patient_Name}`
      )
      .then((response) => {
        console.log(response);
        setpatinetbillingbarcode(response.data.Patient_Barcode);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [capturedatas, urllink]);

  const handlebeforereprint = () => {
    // setselectedId(capturedatas?.Billing_Invoice);
    setOpenDialog(true);
  };

  const handleupdatesencitivity = () => {
    
    const postdata = {
      ...culturevalue,
      invoice : capturedatas?.Billing_Invoice,
      visitid :capturedatas?.Visit_Id
    }
  console.log('postdata', postdata);
   axios.post(`${urllink}Phelobotomist/culture_sencitivity_type_update`, postdata)
   .then((response)=>{
    console.log(response);
    // setEditMode(!editMode);
   })
   .catch((error)=>{
    console.log(error);
   })

  };


  // const handlereprint = useReactToPrint({
  //   content: () => document.getElementById("reactprintcontent"),
  //   onBeforePrint: () => {
  //     setshow(true);
  //   },
  //   onAfterPrint: async () => {
  //     setPdfBlob(null);
  //     const printdata = document.getElementById("reactprintcontent");

  //     try {
  //       if (printdata) {
  //         const contentWidth = printdata.offsetWidth;
  //         const contentHeight = printdata.offsetHeight;
  //         const pdfWidth = contentWidth + 2 * 5;
  //         const pdfHeight = contentHeight + 2 * 10;
  //         const pdf = new jsPDF({
  //           orientation: "portrait",
  //           unit: "px",
  //           format: [pdfWidth, 1120],
  //         });

  //         const barcodeImagecon1 = await toPng(
  //           printdata.querySelector("#get_imagecontent_1")
  //         );
  //         const barcodeImagecon2 = await toPng(
  //           printdata.querySelector("#get_imagecontent_2")
  //         );

  //         const barcodeImage1 = await new Promise((resolve, reject) => {
  //           const barcodeImg1 = new Image();
  //           barcodeImg1.onload = () => resolve(barcodeImg1);
  //           barcodeImg1.onerror = reject;
  //           barcodeImg1.src = barcodeImagecon1;
  //         });

  //         const barcodeImage2 = await new Promise((resolve, reject) => {
  //           const barcodeImg2 = new Image();
  //           barcodeImg2.onload = () => resolve(barcodeImg2);
  //           barcodeImg2.onerror = reject;
  //           barcodeImg2.src = barcodeImagecon2;
  //         });

  //         // Append barcode images to their respective containers
  //         const barcodeContainer1 = printdata.querySelector(
  //           "#get_imagecontent_1"
  //         );
  //         barcodeContainer1.innerHTML = ""; // Clear previous content
  //         barcodeContainer1.appendChild(barcodeImage1);

  //         const barcodeContainer2 = printdata.querySelector(
  //           "#get_imagecontent_2"
  //         );
  //         barcodeContainer2.innerHTML = ""; // Clear previous content
  //         barcodeContainer2.appendChild(barcodeImage2);

  //         pdf.html(printdata, {
  //           x: 5, // Set x-coordinate for content
  //           y: 5, // Set y-coordinate for content
  //           callback: () => {
  //             const generatedPdfBlob = pdf.output("blob");
  //             setPdfBlob(generatedPdfBlob);
  //             // pdf.save(`${capturedatas.Patient_Name}_labReport.pdf`);
  //             setshow(false);

  //             const formdata = new FormData();
  //             const postdata = {
  //               patientname: capturedatas.Patient_Name,
  //               patient_Id: capturedatas.Patient_Id,
  //               Visit_Id: capturedatas.Visit_Id,
  //               refering_doc: capturedatas.Refering_Doctor,
  //               invoice: capturedatas.Billing_Invoice,
  //               patinetbillingbarcode: patinetbillingbarcode,
  //               samplecode: capturedatas.Barcode,
  //               location: userRecord?.location,
  //               createdby: userRecord?.username,
  //               cancelReason: cancelReason,
  //             };

  //             // Append each field in postdata to the formdata
  //             for (const key in postdata) {
  //               if (postdata.hasOwnProperty(key)) {
  //                 formdata.append(key, postdata[key]);
  //               }
  //             }

  //             // Append the PDF blob to the formdata
  //             formdata.append(
  //               "report",
  //               generatedPdfBlob,
  //               `${capturedatas.Patient_Name}_labReport.pdf`
  //             );

  //             axios
  //               .post(`${urllink}Phelobotomist/insert_reort_table`, formdata, {
  //                 headers: {
  //                   "Content-Type": "multipart/form-data",
  //                 },
  //               })
  //               .then((response) => {
  //               })
  //               .catch((error) => {
  //                 console.log(error);
  //               });
  //           },
  //         });
  //       } else {
  //         throw new Error("Unable to get the target element");
  //       }
  //     } catch (error) {
  //       console.error("Error generating PDF:", error);
  //     }
  //   },
  // });

  const handleeditsumma = () => {
    console.log("hchifujiffiojfjjh");

    summa
      .filter((item) => item.Department === "MICROBIOLOGY")
      .flatMap((item) =>
        item.tests.forEach((p) =>
          seteditmicrodata((prev) => ({
            ...prev,
            notes: p.Notes,
            culturereport: p.Culture_Report,
            reportstatus: p.Report_Status,
            microscopy: p.Microscopy_Data,
            bacteriaisolated : p.Bacteria_Isolated
          }))
        )
      );

      // if(summa?.som((p)=> p.tests?.some((test) => test.Report_Type !== "Ot"))){
      //   handleshowculturetest()
      // }
      if (summa.filter((p)=> p.Department === "MICROBIOLOGY").some((item)=> item.tests[0].Report_Type === "Ot")){
        handleshowculturetest()
      }

    setEditMode(!editMode);
  };

  console.log("editmicrodata.........", editmicrodata);

  const handleupdatechanges = () => {
    // Use flatMap to iterate over the 'summa' array and extract the 'Test_Code' from tests with 'Department' === 'MICROBIOLOGY'
    // const microTestCode = summa
    //   .filter((item) =>

    //     item.tests.filter((test) => test.Department === "MICROBIOLOGY")
    //   )
    //   .map((test) => test.Test_Code)[0]; // Extract the first Test_Code found (or modify if multiple codes are needed)

    const microTestCode = summa
      .filter((item) => item.Department === "MICROBIOLOGY")
      .flatMap((item) => item.tests.map((p) => p.Test_Code))[0];

    // Prepare the data for the POST request
    let postdata = {
      ...editmicrodata,
      patientname: capturedatas.Patient_Name,
      patient_Id: capturedatas.Patient_Id,
      Visit_Id: capturedatas.Visit_Id,
      invoice: capturedatas.Billing_Invoice,
      testcode: microTestCode,
      content: content, // Add the test code from MICROBIOLOGY department
    };

    console.log("postdata", postdata); // Debugging to check the final post data

    // Make the axios POST request
    axios
      .post(
        `${urllink}Phelobotomist/update_microafter_flow_completed`,
        postdata
      )
      .then((response) => {
        console.log(response);
        setEditMode(!editMode);
        seteditmicrodata({
          notes: "",
          reportstatus: "",
          microscopy: "",
          culturereport: "",
          bacteriaisolated:""
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatMethodName = (methodName) => {
    if (!methodName) return ""; // Handle empty or undefined method names
    return (
      methodName.charAt(0).toUpperCase() + methodName.slice(1).toLowerCase()
    );
  };

  const fetchTemplateData = useCallback(() => {
    const histopathologyTestCode = summa.flatMap((item) =>
      item.tests
        .filter(
          (test) =>
            test.Department === "HISTOPATHOLOGY" ||
            test.Department === "HEMATOLOGY"
        )
        .map((test) => test.Test_Code)
    )[0];

    console.log("Test Code for HISTOPATHOLOGY:", histopathologyTestCode);
    axios
      .get(
        `${urllink}Phelobotomist/Get_Template?Billing_Invoice=${capturedatas.Billing_Invoice}&patient_id=${capturedatas?.Patient_Id}&visitid=${capturedatas?.Visit_Id}&Test_Code=${histopathologyTestCode}`
      )

      .then((res) => {
        console.log(res);
        // Assuming res.data.format contains the format array
        setFormatData(res.data.format);
      })

      .catch((err) => {
        console.error(err);
      });
  }, [urllink, summa, capturedatas, setFormatData]);

  useEffect(() => {
    fetchTemplateData();
  }, [fetchTemplateData]);

  const [editHistoMode, setEditHistoMode] = useState(false); // Local state for edit mode
  const [imagePreview, setImagePreview] = useState(null);

  const handleEditHisto = () => {
    setEditHistoMode(true); // Enter edit mode
    console.log("jfijifjijfijgijgijgjgj");
  };

  const handleUpdateHisto = () => {
    console.log(formatData);
    const formData1 = new FormData();
    const histoTestCode = summa.flatMap((item) =>
      item.tests
        .filter(
          (test) =>
            test.Department === "HISTOPATHOLOGY" ||
            test.Department === "HEMATOLOGY"
        )
        .map((test) => test.Test_Code)
    )[0];
    formData1.append("Billing_Invoice", capturedatas.Billing_Invoice);
    formData1.append("Visit_Id", capturedatas.Visit_Id);
    formData1.append("Patient_Id", capturedatas.Patient_Id);
    let histopostdata = {
      histoformatdata: formatData,
      patientname: capturedatas.Patient_Name,
      patient_Id: capturedatas.Patient_Id,
      Visit_Id: capturedatas.Visit_Id,
      invoice: capturedatas.Billing_Invoice,
      testcode: histoTestCode,
    };
    formatData.forEach((item) => {
      console.log("testCode:", histoTestCode);

      if (item.name === "imageData" && item.value instanceof File) {
        // If the item is an image, append it as a file
        formData1.append(`${histoTestCode}_imageData`, item.value); // Append the testCode to differentiate images
      } else if (item.format) {
        formData1.append(`${histoTestCode}_format`, item.format)
      }

      else if (item.name !== "format") {
        // For other items, append their name and value with the testCode prefix (skip format itself)
        formData1.append(`${histoTestCode}_${item.name}`, item.value || '');
      }
    });
    console.log("histo", histopostdata);
    axios
      .post(`${urllink}Phelobotomist/insert_Histo_Template`, formData1, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });

    // Logic to save or process updated data (if needed)
    setEditHistoMode(false); // Exit edit mode
  };

  console.log(summa1, "uhjfijfiijfijfiijfijfofoifoofjifji");

  const handleeditsummamicro = () => {
    console.log("jfijifjijfijgijgijgjgj");
    setmicromode((prev) => !prev);
    setopenmodels((prev) => !prev);

    const microbiologyContent = summa1
      .filter((p) => p.Department === "MICROBIOLOGY")
      .flatMap((item) => item.Tests.map((test) => test.Content))[0];

    console.log(microbiologyContent, "iopppopohuhhijjijookbhhh");
    setContent(microbiologyContent);
  };


  const handleSensitivityChange = (id, value) => {
    const updatedRows = culturevalue.map((row) =>
      row.id === id ? { ...row, Sensitivetype: value } : row
    );
    setculturevalue(updatedRows);
  };

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
    

  //   onBeforePrint: () => {
  //     setshow(true);
  //   },
  //   onAfterPrint: async () => {
  //     setPdfBlob(null);
  //     const printdata = componentRef.current;

  //     try {
  //       if (printdata) {
  //         const contentWidth = printdata.offsetWidth;
  //         // const contentHeight = printdata.offsetHeight;
  //         const pdfWidth = contentWidth + 2 * 3;
  //         // const pdfHeight = contentHeight + 2 * 10;
  //         const pdf = new jsPDF({
  //           orientation: "portrait",
  //           unit: "px",
  //           format: [pdfWidth, 1120],
  //         });

  //         const barcodeImagecon1 = await toPng(
  //           printdata.querySelector("#get_imagecontent_1")
  //         );
  //         const barcodeImagecon2 = await toPng(
  //           printdata.querySelector("#get_imagecontent_2")
  //         );

  //         const barcodeImage1 = await new Promise((resolve, reject) => {
  //           const barcodeImg1 = new Image();
  //           barcodeImg1.onload = () => resolve(barcodeImg1);
  //           barcodeImg1.onerror = reject;
  //           barcodeImg1.src = barcodeImagecon1;
  //         });

  //         const barcodeImage2 = await new Promise((resolve, reject) => {
  //           const barcodeImg2 = new Image();
  //           barcodeImg2.onload = () => resolve(barcodeImg2);
  //           barcodeImg2.onerror = reject;
  //           barcodeImg2.src = barcodeImagecon2;
  //         });

  //         // Append barcode images to their respective containers
  //         const barcodeContainer1 = printdata.querySelector(
  //           "#get_imagecontent_1"
  //         );
  //         barcodeContainer1.innerHTML = ""; // Clear previous content
  //         barcodeContainer1.appendChild(barcodeImage1);

  //         const barcodeContainer2 = printdata.querySelector(
  //           "#get_imagecontent_2"
  //         );
  //         barcodeContainer2.innerHTML = ""; // Clear previous content
  //         barcodeContainer2.appendChild(barcodeImage2);

  //         pdf.html(printdata, {
  //           x: 5, // Set x-coordinate for content
  //           y: 5, // Set y-coordinate for content
  //           callback: () => {
  //             const generatedPdfBlob = pdf.output("blob");
  //             setPdfBlob(generatedPdfBlob);
  //             // pdf.save(`${capturedatas.Patient_Name}_labReport.pdf`);
  //             setshow(false);

  //             const formdata = new FormData();
  //             const postdata = {
  //               patientname: capturedatas.Patient_Name,
  //               patient_Id: capturedatas.Patient_Id,
  //               Visit_Id: capturedatas.Visit_Id,
  //               refering_doc: capturedatas.Refering_Doctor,
  //               invoice: capturedatas.Billing_Invoice,
  //               patinetbillingbarcode: patinetbillingbarcode,
  //               samplecode: capturedatas.Barcode,
  //               location: userRecord?.location,
  //               createdby: userRecord?.username,
  //             };

  //             // Append each field in postdata to the formdata
  //             for (const key in postdata) {
  //               if (postdata.hasOwnProperty(key)) {
  //                 formdata.append(key, postdata[key]);
  //               }
  //             }

  //             // Append the PDF blob to the formdata
  //             formdata.append("report", generatedPdfBlob);

  //             axios
  //               .post(`${urllink}Phelobotomist/insert_reort_table`, formdata, {
  //                 headers: {
  //                   "Content-Type": "multipart/form-data",
  //                 },
  //               })
  //               .then((response) => {
  //                 console.log(response);
  //                 settrues(!trues);
  //               })
  //               .catch((error) => {
  //                 console.log(error);
  //               });
  //             if (capturedatas.Refering_Hospital === "RMC") {
  //               axios
  //                 .post(
  //                   `${urllink}Phelobotomist/update_report_for_external_request`,
  //                   formdata,
  //                   {
  //                     headers: {
  //                       "Content-Type": "multipart/form-data",
  //                     },
  //                   }
  //                 )
  //                 .then((response) => {
  //                   console.log(response);
  //                   settrues(!trues);
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });
  //             }
  //             pdf.save(`${capturedatas.Patient_Name}_labReport.pdf`);
  //           },
  //         });

  //       } else {
  //         throw new Error("Unable to get the target element");
  //       }
  //     } catch (error) {}
  //   },
  // });




  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      setshow(true);
    },
    onAfterPrint: async () => {
      setshow(false);
    },
  });
  


  const handleSave = async () => {
    const printdata = componentRef.current;
  
    try {
      if (printdata) {
        // Create a clone of the original content to manipulate
        const clonedContent = printdata.cloneNode(true);
        
        // Add aggressive styling to reduce content size and adjust positioning
        const scalingStyle = document.createElement('style');
        scalingStyle.textContent = `
          .pdf-scaled-content {
            transform: scale(0.65);
            transform-origin: top left;
            width: 60% !important; 
            margin: -1% 0 0 -65% !important; /* Further move content to the left */
            position:relative !important;
            left:-70px;
            padding: 0 !important;
            font-size: 8px !important;
          }
          .pdf-scaled-content * {
            font-size: 8px !important;
            line-height: 1 !important;
            margin: 2px 0 !important;
            padding: 0 !important;
          }
          .pdf-scaled-content table {
            width: 88% !important;
             position:relative !important;
           
            border-collapse: collapse !important;
          }
          .pdf-scaled-content th, 
          .pdf-scaled-content td {
            padding: 2px !important;
            font-size: 7px !important;
          }

        `;
        
        // Apply scaling classes and styles
        clonedContent.appendChild(scalingStyle);
        clonedContent.classList.add('pdf-scaled-content');
  
        // Create PDF with adjusted settings
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: [600, 1120], // Adjusted page size
        });
  
        await new Promise((resolve) => {
          pdf.html(clonedContent, {
            x: 0, // Minimal left padding
            y: 0, // Kept vertical padding same
            width: 590, // Slightly increased width
            html2canvas: {
              scale: 1.2, // Adjusted scaling
              width: 590,
              useCORS: true,
              logging: false, // Disable logging
            },
            callback: function() {
              resolve();
            }
          });
        });
  
        // Generate and save PDF
        const generatedPdfBlob = pdf.output("blob");
        setPdfBlob(generatedPdfBlob);
        // const pdfUrl = URL.createObjectURL(generatedPdfBlob);
        // setPdfUrl(pdfUrl);
  
        // Existing backend saving logic remains the same
        const formdata = new FormData();
        const postdata = {
          patientname: capturedatas.Patient_Name,
          patient_Id: capturedatas.Patient_Id,
          Visit_Id: capturedatas.Visit_Id,
          refering_doc: capturedatas.Refering_Doctor,
          invoice: capturedatas.Billing_Invoice,
          patinetbillingbarcode: patinetbillingbarcode,
          samplecode: capturedatas.Barcode,
          location: userRecord?.location,
          createdby: userRecord?.username,
        };
  
        for (const key in postdata) {
          if (postdata.hasOwnProperty(key)) {
            formdata.append(key, postdata[key]);
          }
        }
  
        formdata.append("report", generatedPdfBlob);
  
        try {
          const response = await axios.post(
            `${urllink}Phelobotomist/insert_reort_table`,
            formdata,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
          console.log(response);
          settrues(!trues);
        } catch (error) {
          console.error("Error saving the report:", error);
        }
  
        if (capturedatas.Refering_Hospital === "RMC") {
          try {
            const response = await axios.post(
              `${urllink}Phelobotomist/update_report_for_external_request`,
              formdata,
              { headers: { "Content-Type": "multipart/form-data" } }
            );
            console.log(response);
            settrues(!trues);
          } catch (error) {
            console.error("Error updating external request:", error);
          }
        }
  
        // pdf.save(`${capturedatas.Patient_Name}_labReport.pdf`);
  
      } else {
        throw new Error("Unable to get the target element");
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };
  
  
  

  const [loader, setloader] = useState(false);

  const handleDownloadPDF = async () => {
    setemailsent(true);

    const printData = componentRef.current;

    if (!printData) {
      console.error("Unable to get the target element");
      return;
    }

    setshow(true);

    try {
      const contentWidth = printData.offsetWidth;
      const contentHeight = printData.offsetHeight;
      // const pdfWidth = contentWidth + 2 * 5;
      // const pdfHeight = contentHeight + 2 * 5;

      const a4WidthPx = 794; // A4 width in pixels
      const a4HeightPx = 1123; // A4 height in pixels

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: [a4WidthPx, a4HeightPx],
      });

      const marginX = (a4WidthPx - contentWidth) / 2;
      const marginY = (a4HeightPx - contentHeight) / 2;

      pdf.html(printData, {
        x: marginX > 0 ? marginX : 5,
        y: marginY > 0 ? marginY : 5,
        callback: async () => {
          const generatedPdfBlob = pdf.output("blob");
          setPdfBlob(generatedPdfBlob);

          const formData = new FormData();
          const postData = {
            patientname: capturedatas.Patient_Name,
            Patient_Id: capturedatas.Patient_Id,
          };

          for (const key in postData) {
            if (postData.hasOwnProperty(key)) {
              formData.append(key, postData[key]);
            }
          }

          formData.append("report", generatedPdfBlob);
          setloader(true);
          try {
            const response = await axios.post(
              `${urllink}Phelobotomist/sendEmail`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            setemailsent(false);
            setPdfBlob(null);
            setloader(false);
            console.log(response);
          } catch (error) {
            console.error(error);
          } finally {
            setshow(false);
          }
        },
      });
    } catch (error) {
      console.error("Error generating PDF:", error);
      setshow(false);
    }
  };

  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            const reader = new FileReader();

            reader.onloadend = () => {
              // Convert image file to base64
              const base64String = reader.result.split(",")[1];
              resolve({ default: `data:${file.type};base64,${base64String}` });
            };

            reader.onerror = (error) => {
              reject(error);
            };

            reader.readAsDataURL(file);
          });
        });
      },
    };
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const formatName = (name) => {
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // const loadImage = (src) => {
  //   return new Promise((resolve, reject) => {
  //     const img = new Image();
  //     img.onload = () => resolve(img);
  //     img.onerror = reject;
  //     img.src = src;
  //   });
  // };

  // const appendImageToContainer = (container, image) => {
  //   container.innerHTML = ""; // Clear previous content
  //   container.appendChild(image);
  // };

  // const PdfViewer = ({ pdfBlob }) => {
  //   // Check if pdfBlob is a Blob object
  //   if (pdfBlob instanceof Blob) {
  //     // Convert Blob object to data URL
  //     const url = URL.createObjectURL(pdfBlob);

  //     // Render the PDF using iframe
  //     return (
  //       <iframe
  //         title="PDF Viewer"
  //         src={url}
  //         style={{
  //           width: "100%",
  //           height: "435px",
  //           border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
  //         }}
  //       />
  //     );
  //   }

  //   // If pdfBlob is not a Blob object, assume it's already a valid data URL and render it directly
  //   return (
  //     <img
  //       src={pdfBlob}
  //       alt="Concern Form"
  //       style={{
  //         width: "100%",
  //         height: "100%",
  //         marginTop: "20px",
  //       }}
  //     />
  //   );
  // };

  // function getInnerHeightOfPDF() {
  //   const pdfget = new jsPDF({
  //     orientation: "portrait", // or 'landscape'
  //     unit: "px", // pixels
  //     format: [595, 842], // A4 size in pixels (width, height)
  //   });
  //   const innerHeightInPoints = pdfget.internal.pageSize.getHeight();
  //   pdfget.deletePage();
  //   const innerHeightInPixels = innerHeightInPoints * (1 / 72) * 96;
  //   return innerHeightInPixels - 10;
  // }

  const handleContentEditableInputChange = (e, name) => {
    // Capture the current selection
    const selection = window.getSelection();
    const selectedText = selection?.toString().trim();

    // Update the currently selected field and text
    setSelectedField(name);
    setSelectedText(selectedText || ""); // Handle cases where no text is selected
  };

  const makeTextBold = () => {
    if (!selectedText || !selectedField) return; // Exit if no text is selected or field is undefined

    const updatedData = [...formatData];
    const itemIndex = updatedData.findIndex((p) => p.name === selectedField);

    if (itemIndex !== -1) {
      const value = updatedData[itemIndex].value;

      // Replace selected text with bolded text
      const boldedValue = value.replace(
        new RegExp(`(${selectedText})`, "g"),
        `<b>$1</b>`
      );

      updatedData[itemIndex] = {
        ...updatedData[itemIndex],
        value: boldedValue,
      };
      setFormatData(updatedData);
    }
  };

  const makeTextNormal = () => {
    if (!selectedText || !selectedField) return; // Exit if no text is selected or field is undefined

    const updatedData = [...formatData];
    const itemIndex = updatedData.findIndex((p) => p.name === selectedField);

    if (itemIndex !== -1) {
      let value = updatedData[itemIndex].value;

      // Create a regular expression to find the selected text wrapped in <b> tags
      const regex = new RegExp(`<b>${selectedText}</b>`, "g");

      // Replace only the selected text that is wrapped in <b> tags
      const updatedValue = value.replace(regex, selectedText);

      // Update the value for the selected field
      updatedData[itemIndex] = {
        ...updatedData[itemIndex],
        value: updatedValue,
      };

      setFormatData(updatedData);
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0]; // Get the selected file
    if (file) {
      const previewUrl = URL.createObjectURL(file); // Create a preview URL

      
      setImagePreview(previewUrl); // Set the preview URL for immediate display

      // Update the `formatData` array
      setFormatData((prev) =>
        prev?.map((field) =>
          field.name === "imageData"
            ? { ...field, value: file } // Store the actual file in `value`
            : field // Keep other fields unchanged
        )
      );
    }
  };

  return (
    <>
      {loader ? (
        <>
          <div class="loader"></div>
        </>
      ) : (
        <div>
          <TableComponent
            ref={componentRef}
            patinetbillingbarcode={patinetbillingbarcode}
            patinetbillingQr={patinetbillingQr}
            capturedatas={capturedatas}
            report={report}
            summa={summa}
            userRecord={userRecord}
            navigate={navigate}
            ClinicDetials={ClinicDetials}
            urllink={urllink}
            uniqueOrganisms={uniqueOrganisms}
            getSensitivityColor={getSensitivityColor}
            alphabetHeaders={alphabetHeaders}
            // index = {index}
            summa1={summa1}
            formatData={formatData}
            emailsent={emailsent}
            SignPerson={SignPerson}
            printpapaer={printpapaer}
            isNoRendered={isNoRendered}
            formatHeading={formatHeading}
            microandhistonumber={microandhistonumber}
            editMode={editMode}
            setEditMode={setEditMode}
            autoResizeTextarea={autoResizeTextarea}
            handleeditchange={handleeditchange}
            openmodels={openmodels}
            editHistoMode={editHistoMode} // Histopathology edit mode
            setFormatData={setFormatData}
            formatMethodName={formatMethodName}
            // otreporttype = {otreporttype}
            setopenmodels={setopenmodels}
            contentRef={contentRef}
            uploadPlugin={uploadPlugin}
            isSidebarOpen={isSidebarOpen}
            content={content}
            setContent={setContent}
            editmicrodata={editmicrodata}
            formatName={formatName}
            touppercasefun={touppercasefun}
            setSelectedText={setSelectedText}
            setSelectedField={setSelectedField}
            handleContentEditableInputChange={handleContentEditableInputChange}
            showmodel = {showmodel}
            setshowmodel = {setshowmodel}
            culturevalue ={culturevalue}
            page = {page}
            pageSize = {pageSize}
            dynamicColumns = {dynamicColumns}
            setPage = {setPage}
            showdown = {showdown}
            totalPages ={totalPages}
            handleupdatesencitivity = {handleupdatesencitivity}
            handleSensitivityChange = {handleSensitivityChange}
            imagePreview={imagePreview}
            handleImageChange={handleImageChange}
          />


          <br />

          <div className="sdcyysc_uxcds67">
            <label>
              <h3>Do you want Header and Footer?</h3>
            </label>
            <div className="dhchche_oici8">
              <h3>
                {" "}
                <label>
                  <input
                    type="checkbox"
                    name="headerFooterOption"
                    value="yes"
                    checked={emailsent === true}
                    onChange={handleCheckboxChange}
                  />
                  Yes
                </label>
              </h3>
              <h3>
                <label>
                  <input
                    type="checkbox"
                    name="headerFooterOption"
                    value="no"
                    checked={emailsent === false}
                    onChange={handleCheckboxChange}
                  />
                  No
                </label>
              </h3>
            </div>
          </div>
          <br />

          {summa?.length > 0 && (
            <ReactToPrint
              trigger={() => (
                <div className="Register_btn_con">
                  {summa?.some(
                    (p) =>
                      p.Department === "MICROBIOLOGY" &&
                      p.tests?.some((test) => test.Report_Type !== "Ot")
                  ) && (
                    <button
                      className="RegisterForm_1_btns Rhugt_report"
                      onClick={editMode ? handleupdatechanges : handleeditsumma}
                    >
                      {editMode ? "Update" : "Edit"}
                    </button>
                  )}
                </div>
              )}
              // content={() => componentRef.current}
            />
          )}
          <div className="Register_btn_con">
            {summa?.some(
              (department) =>
                department.Department === "MICROBIOLOGY" &&
                department.tests.some((test) => test.Report_Type === "Ot")
            ) && (
              <button
                className="RegisterForm_1_btns Rhugt_report"
                onClick={
                  editmicromode ? handleupdatechanges : handleeditsummamicro
                }
              >
                {editmicromode ? "Update" : "Edit"}
              </button>
            )}
          </div>

          <div className="Register_btn_con">
            {summa.some(
              (p) =>
                p.Department === "HISTOPATHOLOGY" ||
                (p.Department === "HEMATOLOGY" &&
                  p.tests.some((test) => test.Test_Code === "RD0098"))
            ) && (
              <div className="Register_btn_con">
                <button
                  className="RegisterForm_1_btns Rhugt_report"
                  onClick={editHistoMode ? handleUpdateHisto : handleEditHisto}
                >
                  {editHistoMode ? "Update" : "Edit"}
                </button>
              </div>
            )}
            {editHistoMode && (
              
                <label className="RegisterForm_1_btns" style={{width:"80px",height:"30px"}}>
                  <input type="file" className="hiden-nochse-file" onChange={handleImageChange} />
                  Choose File
                </label>
              
            )}

            {editHistoMode && (
              <div className="Register_btn_con">
                <button
                  className="RegisterForm_1_btns Rhugt_report"
                  onClick={makeTextBold}
                >
                  Bold
                </button>
              </div>
            )}
            {editHistoMode && (
              <div className="Register_btn_con">
                <button
                  className="RegisterForm_1_btns Rhugt_report"
                  onClick={makeTextNormal}
                >
                  Normal
                </button>
              </div>
            )}

            <button
              onClick={handleSave} // Save button for saving the PDF
              className="RegisterForm_1_btns Rhugt_report"
            >
              Save
            </button>

            

            <button

              onClick={
                Dispatch === "Dispatched" ? handlebeforereprint : handlePrint
              }
              className="RegisterForm_1_btns  Rhugt_report"
            >
              {Dispatch === "Dispatched" ? "Reprint" : "Print"}
            </button>

            {summa?.length > 0 && (
              <div className="Register_btn_con">
                <button
                  onClick={handleDownloadPDF}
                  className="RegisterForm_1_btns  Rhugt_report"
                >
                  Send Email
                </button>
              </div>
            )}
          </div>

          {/* )}
              content={() => componentRef.current}
            /> */}

          <CancelAppointmentDialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            setCancelReason={setCancelReason}
            cancelReason={cancelReason}
          />
        </div>
      )}
    </>
  );
}
export default Reports;
